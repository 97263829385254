import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_STREET,
  ADD_STREET_DATA,
  UPDATE_STREET_DATA
} from "./actionTypes"


import {
  addStreetDataSuccess,
  addStreetDataFail,
  getStreetDataFail,
  getStreetDataSuccess,
} from "./actions"

import { toast } from "react-toastify"

// Include Both Helper File with needed methods
import {
  getStreetList,
  addStreetList,
  updateStreetList
} from "helpers/backend_helper"

// group
function* fetchStreet({ payload: data }) {
  try {
    const response = yield call(getStreetList, data)
    yield put(getStreetDataSuccess(response))
  } catch (error) {
    yield put(getStreetDataFail(error))
  }
}

// add street 
function* onAddStreetData({ payload: data }) {
  try {
    const response = yield call(addStreetList, data)
    yield put(addStreetDataSuccess(response))
    yield put({type:"GET_STREET"})
    toast.success("Street Added Successfully", { autoClose: 5000 });
  } catch (error) {
    const message = error.response.data.name ? error.response.data.name[0] : (error.detail ? error.detail : "System error"); 
    yield put(addStreetDataFail(message))
    toast.error(message, { autoClose: 5000 });
  }
}

// update street 
function* onUpdateStreetData({ payload: data }) {
  try {
    const response = yield call(updateStreetList, data)
    yield put({type:"GET_STREET",payload: data})
    toast.success("Street updated Successfully", { autoClose: 5000 });
  } catch (error) {
    const message = error.response.data.name ? error.response.data.name[0] : (error.detail ? error.detail : "System error"); 
    yield put(addStreetDataFail(message))
    toast.error(message, { autoClose: 5000 });
  }
}

function* StreetSaga() {
  yield takeEvery(GET_STREET, fetchStreet)
  yield takeEvery(ADD_STREET_DATA, onAddStreetData)
  yield takeEvery(UPDATE_STREET_DATA, onUpdateStreetData)
}

export default StreetSaga
