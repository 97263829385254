import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_PAYMENT_OPTION,
  ADD_PAYMENT_OPTION_DATA,
  UPDATE_PAYMENT_OPTION_DATA,
  ACTIVATE_PAYMENT_OPTION
} from "./actionTypes"


import {
  addPayment_OptionDataSuccess,
  addPayment_OptionDataFail,
  getPayment_OptionDataFail,
  getPayment_OptionDataSuccess
} from "./actions"

import { toast } from "react-toastify"

// Include Both Helper File with needed methods
import {
  getPayment_OptionList,
  addPayment_OptionList,
  updatePayment_OptionList,
  activatePayment_Option
} from "helpers/backend_helper"

// banner
function* fetchPayment_Option() {
  try {
    const response = yield call(getPayment_OptionList)
    yield put(getPayment_OptionDataSuccess(response))
  } catch (error) {
    yield put(getPayment_OptionDataFail(error))
  }
}

// add data 
function* onAddPayment_OptionData({ payload: data }) {
  try {
    const formData = new FormData()
    formData.append("name",data.name)
    formData.append("image", data.image[0])
    const response = yield call(addPayment_OptionList, formData)
    yield put(addPayment_OptionDataSuccess(response))
    yield put({type:"GET_PAYMENT_OPTION"})
    toast.success("Payment Option Added Successfully", { autoClose: 5000 });
  } catch (error) {
    const message = error.response.data.name ? error.response.data.name[0] : (error.detail ? error.detail : "System error"); 
    yield put(addPayment_OptionDataFail(message))
    toast.error(message, { autoClose: 5000 });
  }
}

// UPDATE PAYMENT_OPTION 
function* onUpdatePayment_OptionData({ payload: data }) {
  try {
    const formData = new FormData()
    formData.append("id", data.id)
    formData.append("name", data.title)
    formData.append("image", data.fileImage[0])
    const response = yield call(updatePayment_OptionList, formData)
    yield put({type:"GET_PAYMENT_OPTION"})
    toast.success("Payment Option Updated Successfully", { autoClose: 5000 });
  } catch (error) {
    const message = error.response.data.name ? error.response.data.name[0] : (error.detail ? error.detail : "System error"); 
    yield put(addPayment_OptionDataFail(message))
    toast.error(message, { autoClose: 5000 });
  }
}


// activate Payment_Option api
function* onActivatePayment_Option({ payload: data }) {
  try {
    const response = yield call(activatePayment_Option, data)
    yield put({type:"GET_PAYMENT_OPTION"})
  } catch (error) {
    const message = error.response.data.name ? error.response.data.name[0] : (error.detail ? error.detail : "System error"); 
    yield put(addPayment_OptionDataFail(message))
    toast.error(message, { autoClose: 5000 });
  }
}


function* Payment_OptionSaga() {
  yield takeEvery(GET_PAYMENT_OPTION, fetchPayment_Option)
  yield takeEvery(ACTIVATE_PAYMENT_OPTION, onActivatePayment_Option)
  yield takeEvery(ADD_PAYMENT_OPTION_DATA, onAddPayment_OptionData)
  yield takeEvery(UPDATE_PAYMENT_OPTION_DATA, onUpdatePayment_OptionData)
}

export default Payment_OptionSaga
