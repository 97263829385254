import {
  GET_PERMISSION,
  ADD_PERMISSION_DATA,
  ADD_PERMISSION_DATA_SUCCESS,
  ADD_PERMISSION_DATA_FAIL,
  GET_PERMISSION_SUCCESS,
  GET_PERMISSION_FAIL,
  UPDATE_PERMISSION_DATA
} from "./actionTypes"

// PERMISSION
export const getPermission = () => ({
  type: GET_PERMISSION,
})

export const addPermissionData = data => ({
  type: ADD_PERMISSION_DATA,
  payload: data,
})

export const updatePermissionData = data => ({
  type: UPDATE_PERMISSION_DATA,
  payload: data,
})

export const getPermissionDataSuccess = data => ({
  type: GET_PERMISSION_SUCCESS,
  payload: data,
})


export const addPermissionDataSuccess = data => ({
  type: ADD_PERMISSION_DATA_SUCCESS,
  payload: data,
})

export const addPermissionDataFail = error => ({
  type: ADD_PERMISSION_DATA_FAIL,
  payload: error,
})

export const getPermissionDataFail = error => ({
  type: GET_PERMISSION_FAIL,
  payload: error,
})
// PERMISSION