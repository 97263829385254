import React, { useState,useMemo,useEffect,useRef } from "react";
import { createSelector } from "reselect"
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  Label,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Container,
  FormFeedback,
  UncontrolledTooltip,
  Form,
} from "reactstrap";
import { ToastContainer } from "react-toastify";
import Spinners from "components/Common/Spinner";
import TableContainer from '../../components/Common/TableContainer';

import { Link } from "react-router-dom";

import {
  addStaffData as onAddStaffData,
  updateStaffData as onUpdateStaffData,
  getStaff as onGetStaff
} from "store/user/Staff/actions"

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
// redux
import { useSelector, useDispatch } from "react-redux"

import {  CreateadAt,CreatedBy } from "./categorytCol";
// Form Mask
import InputMask from "react-input-mask"

// import DeleteModal from "../DeleteModal";

const Staff = () => {
//meta title
document.title="WekaDau | Staff";

//  dispatch
const dispatch = useDispatch({});

const [staff, setStaff] = useState();
const [isEdit, setIsEdit] = useState(false);

// delete modal
const [deleteModal, setDeleteModal] = useState(false);
const [staffs, setStaffs] = useState([])

  //  modal
const [modal, setModal] = useState(false)

useEffect(() => {
  dispatch(onGetStaff())
}, [dispatch])


const onClickDelete = category => {
   setStaff(category);
   setDeleteModal(true);
};


  const selectStaffState = state => state.Staff
  
  const StaffProperties = createSelector(selectStaffState, Staff => ({
    StaffData:Staff.data,
    loading: Staff.loading
  }))
  const { StaffData, loading } = useSelector(StaffProperties ? StaffProperties :[])
  const [isLoading, setLoading] = useState(loading)

 
useEffect(() => {
  setStaffs(StaffData ? StaffData:[])
}, [StaffData])

const handleStaffClick = arg => {
  const category = arg;
  setStaff(category);
  setIsEdit(true);
  toggle();
};
  
// handle delete category
const handleDeleteStaff = () => {
  if (category && category.id) {
    console.log(category.id)
    // dispatch(onDeleteUser(category.id));
  }
  onPaginationPageChange(1);
  setDeleteModal(false);
};

  const toggle = () => {
    if (modal) {
      setStaff()
      setIsEdit(false);
      setModal(false)
    } else {
      setModal(true)
    }
  }

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };


  // Form validation 
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      first_name: (staff && staff.user.first_name) ||'',
      middle_name: (staff && staff.user.middle_name) ||'',
      last_name: (staff && staff.user.last_name) ||'',
      email: (staff && staff.email) ||'',
      phone_number:(staff && staff.user.phone_number) ||''
    },
      validationSchema: Yup.object({
      last_name: Yup.string().required("Please Enter Last Name"),
      first_name: Yup.string().required("Please Enter First Name"),
      middle_name: Yup.string().required("Please Enter Middle Name"),
      phone_number: Yup.number().required( "Please Enter Phone Number"),
      email: Yup.string().email("Must be a valid Email").max(255).required("Email is required"),
    }),
    
    onSubmit: (values) => {
      if(isEdit){
      dispatch(onUpdateStaffData(values));
      }else{
       dispatch(onAddStaffData(values));
      }
      validation.resetForm()
      toggle();
    }
  });
 
  const columns = useMemo(
    () => [
         {
          Header: 'Created By',
          accessor: 'user',
          filterable: true,
          Cell: cellProps => {
          return (
            <>
              {" "}
              <CreatedBy {...cellProps} />{" "}
            </>
          );
        },
       },
        {
            Header: 'Staff Email',
            accessor: 'email',
        },
        {
            Header: 'created At',
            accessor: 'user.created_at',
            filterable: true,
        Cell: cellProps => {
          return (
            <>
              {" "}
              <CreateadAt {...cellProps} />{" "}
            </>
          );
        },
      },
        {
            Header: 'Phone Number',
            accessor: 'user.phone_number',
            filterable: true,
        },
        {
          Header: "Action",
          Cell: cellProps => {
            return (
              <div className="d-flex gap-3">
                <Link
                  to="#"
                  className="text-info"
                  onClick={() => {
                    const categoryData = cellProps.row.original;
                    handleStaffClick(categoryData);
                  }}
                >
                  <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    Edit
                  </UncontrolledTooltip>
                </Link>
                {/* <Link
                  to={"/sub-category/" + cellProps.row.original.id}
                  className="text-success"
                >
                  <i className="mdi mdi-eye font-size-18" id="viewtooltip" />
                  <UncontrolledTooltip placement="top" target="viewtooltip">
                    View
                  </UncontrolledTooltip>
                </Link> */}
                <Link
                  to="#"
                  className="text-danger"
                  onClick={() => {
                    const userData = cellProps.row.original;
                    onClickDelete(userData);
                  }}
                >
                  <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </Link>
              </div>
            );
          },
        },
    ],
    []
);

const column_data = staffs ? staffs : staffs.data ? staffs.data :[];

  const regExp = /\b\d{5}\b/;
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Settings" breadcrumbItem="Staff List" />
          {
          isLoading ? <Spinners setLoading={setLoading} />
            :
          <Row>
            <Col xl="4" sm="4" md="4">
              <Card>
                <CardBody>
                  <div className="text-right d-grid">
                                  <Link
                                    to="#"
                                    className="btn btn-primary waves-effect waves-light addtask-btn"
                                    data-bs-toggle="modal"
                                    data-bs-target=".bs-example-modal-lg"
                                    data-id="#upcoming-task"
                                    onClick={() => toggle(true)}
                                  >
                                    <i className="mdi mdi-plus me-1"></i> Add Staff
                                  </Link>
                                </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl="12">
            <Card>
                <CardBody>
                <div className="container-fluid">
                <TableContainer
                    columns={columns}
                    data={column_data}
                    isGlobalFilter={true}
                    isAddOptions={false}
                    customPageSize={10}
                    isPagination={true}
                    tableClass="align-middle table-nowrap table-check table"
                    theadClass="table-light"
                    paginationDiv="col-12"
                    pagination="justify-content-center pagination pagination-rounded"
                />
            </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          }
        </Container>
      </div>

    {/* adding staff modal */}
      <Modal id="modalForm" isOpen={modal} toggle={toggle} centered={true} size="lg">
        <ModalHeader toggle={toggle}>
          {!!isEdit ? "Edit Staff" : "Add Staff"}
        </ModalHeader>
        <ModalBody>
        <Form className="needs-validation"
                    onSubmit={(e) => {
                      e.preventDefault();
                        validation.handleSubmit();
                      return false;
                    }}
                  >
                    <Row>
                      <Col md="6" sm="6" lg="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom01">First name</Label>
                          <Input
                            name="first_name"
                            placeholder="First name"
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.first_name  || ' '}
                            invalid={
                              validation.touched.first_name && validation.errors.first_name ? true : false
                            }
                          />
                          {validation.touched.first_name && validation.errors.first_name ? (
                            <FormFeedback type="invalid">{validation.errors.first_name}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>

                      <Col md="6" sm="6" lg="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom02">Middle name</Label>
                          <Input
                            name="middle_name"
                            placeholder="Middle name"
                            type="text"
                            className="form-control"
                            id="validationCustom02"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.middle_name || ''}
                            invalid={
                              validation.touched.middle_name && validation.errors.middle_name ? true : false
                            }
                          />
                          {validation.touched.middle_name && validation.errors.middle_name ? (
                            <FormFeedback type="invalid">{validation.errors.middle_name}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>

                      <Col md="6" sm="6" lg="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom03">Last name</Label>
                          <Input
                            name="last_name"
                            placeholder="Last name"
                            type="text"
                            className="form-control"
                            id="validationCustom03"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.last_name || ''}
                            invalid={
                              validation.touched.last_name && validation.errors.last_name ? true : false
                            }
                          />
                          {validation.touched.last_name && validation.errors.last_name ? (
                            <FormFeedback type="invalid">{validation.errors.last_name}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      {isEdit}
                     {!!isEdit ? " ": <Col md="6" sm="6" lg="6">
                      <FormGroup className="mb-3">
                      <Label className="form-label">E-Mail</Label>
                      <Input
                        name="email"
                        placeholder="Enter Valid Email"
                        type="email"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.email || ''}
                        invalid={
                          validation.touched.email && validation.errors.email ? true : false
                        }
                      />
                      {validation.touched.email && validation.errors.email ? (
                        <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                      ) : null}
                    </FormGroup>
                    </Col>
                  }
                    <Col md="6" sm="6" lg="6">
                      <FormGroup className="mb-3">
                      <div className="form-group mb-4">
                      <Label className="form-label">Phone number</Label>
                      <Input
                        name="phone_number"
                        placeholder="Enter Phone number"
                        type="number"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.phone_number || ''}
                        invalid={
                          validation.touched.phone_number && validation.errors.phone_number ? true : false
                        }
                      />
                      {validation.touched.phone_number && validation.errors.phone_number ? (
                        <FormFeedback type="invalid">{validation.errors.phone_number}</FormFeedback>
                      ) : null}
                            <span className="text-muted">e.g "255-XXX-XXX-XXX"</span>
                          </div>
                      </FormGroup>
                          </Col>
                    </Row>
                    <div className="text-center d-grid">
                    <Button color="primary" type="submit">
                    {!!isEdit ? "Submit Edit" : "Submit"}
                    </Button>
                    </div>
                    
        </Form>
        </ModalBody>
      </Modal>
      {/* adding staff modal */}

      <ToastContainer />
    </React.Fragment>
  );
};
export default Staff;
