import cx from "classnames";
import React from "react";

const PriceForm = ({ errors, values, handleChange }) => {
const condition_data = [
  { name: "Tzs", value: "TZS" }
];
  return (
  <>
     <div className="columns" >
      <div className="column is-6">
        <div className="field">
          <label className="label">Starting Price</label>
          <div className="control">
          <input
              className={cx("input", !!errors.starting_price && "is-danger")}
              type="number"
              name="starting_price"
              value={values.starting_price}
              id="starting_price"
              onChange={handleChange}
            />
          </div>
          {errors.starting_price && (
            <p className="help is-danger">{errors.starting_price}</p>
          )}
        </div>
      </div>
      <div className="column is-6">
      <div className="field">
          <label className="label">Ending Price</label>
          <div className="control">
          <input
              className={cx("input", !!errors.ending_price && "is-danger")}
              type="number"
              name="ending_price"
              value={values.ending_price}
              id="example-datetime-local-input"
              onChange={handleChange}
            />
          </div>
          {errors.ending_price && (
            <p className="help is-danger">{errors.ending_price}</p>
          )}
        </div>
      </div>
    </div>
    <div className="columns">
      <div className="column is-6">
        <div className="field">
          <label className="label">Currency</label>
          <div className="control">
              <select                                  
                name="currency"
                value={values.currency}
                onChange={handleChange}
                className={cx("input", !!errors.currency && "is-danger")}
                >
                   <option value="">Select Currency</option>
                    {condition_data.map(conditions =>(
                    <option  key={conditions.name} value={conditions.value}>
                     {conditions.name}
                    </option>
                    ))}          
                </select>
          </div>
          {errors.currency && (
            <p className="help is-danger">{errors.currency}</p>
          )}
        </div>
      </div>
    </div>
  </>
)};

export default PriceForm;
