import {
  GET_CUSTOMER_AUCTION,
  GET_CUSTOMER_AUCTION_DETAILS,
  GET_CUSTOMER_AUCTION_SUCCESS,
  GET_CUSTOMER_AUCTION_DETAILS_SUCCESS,
  GET_CUSTOMER_AUCTION_FAIL,
  GET_CUSTOMER_AUCTION_DETAILS_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  data: [],
  error: {},
  loading : true
}

const customerAuction = (state = INIT_STATE, action) => {
  switch (action.type) {
    
    case GET_CUSTOMER_AUCTION:
      return {
        ...state,
        data: action.payload,
        loading : true
      }
      case GET_CUSTOMER_AUCTION_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading : true
      }

    case GET_CUSTOMER_AUCTION_FAIL:
      return {
        ...state,
        error: action.payload,
      }
      
      case GET_CUSTOMER_AUCTION_DETAILS:
      return {
        ...state,
        data: action.payload,
        loading : true
      }

       case GET_CUSTOMER_AUCTION_DETAILS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading : true
      }

    case GET_CUSTOMER_AUCTION_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}
export default customerAuction
