/* AUCTION */
export const GET_CUSTOMER_AUCTION = "GET_CUSTOMER_AUCTION"
export const GET_CUSTOMER_AUCTION_SUCCESS = "GET_CUSTOMER_AUCTION_SUCCESS"
export const GET_CUSTOMER_AUCTION_FAIL = "GET_CUSTOMER_AUCTION_FAIL"

/*AUCTION DETAILS*/ 
export const GET_CUSTOMER_AUCTION_DETAILS = "GET_CUSTOMER_AUCTION_DETAILS"
export const GET_CUSTOMER_AUCTION_DETAILS_SUCCESS = "GET_CUSTOMER_AUCTION_DETAILS_SUCCESS"
export const GET_CUSTOMER_AUCTION_DETAILS_FAIL = "GET_CUSTOMER_AUCTION_DETAILS_FAIL"


