import React, { useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const AdminSidebarComponent = props => {
  return (
    <React.Fragment>
     {/* admin  routes */}
     <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">

            <li className="menu-title">Menu </li>
            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-home-circle"></i>
                <span>Dashboards</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/dashboard">Dashboard</Link>
                </li>
                {/* <li>
                  <Link to="/dashboard-saas">Saas</Link>
                </li>
                <li>
                  <Link to="/dashboard-crypto">Crypto</Link>
                </li>
                <li>
                  <Link to="/blog">Blog</Link>
                </li>
                <li>
                  <Link to="/dashboard-job">                    
                    Job
                  </Link>
                </li> */}
              </ul>
            </li>
           
            <li className="menu-title">Product Management</li>
            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-store-alt"></i>
                <span>Products</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/product/list">
                    <i className="bx bx-store"></i>
                    <span>Product List</span>
                  </Link>
                </li>
                {/* <li>
                  <Link to="/ecommerce-product-detail/1">
                  <i className="bx bx-map-pin"></i>
                   <span>Address</span> 
                  </Link>
                </li> */}
              </ul>
            </li>

            <li className="menu-title">Bidding Management</li>
            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-box"></i>
                <span>Bidding List</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/bid/list">
                    <i className="bx bx-list-ol"></i>
                    <span>Bidding List</span>
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bxs-cart-alt"></i>
                <span>Auction Order</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/auction/list">
                    <i className="bx bx-cart"></i>
                    <span>Bidding Order</span>
                  </Link>
                </li>
              </ul>
            </li>

            <li className="menu-title">User Management</li>
            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-user-plus"></i>
                <span>Users</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/staff/list">
                    <i className="bx bx-user-pin"></i>
                    <span>Staff</span>
                  </Link>
                </li>
                <li>
                  <Link to="/customer/list">
                    <i className="bx bx-user"></i>
                    <span>Customer</span>
                  </Link>
                </li>
              </ul>
            </li>

            <li className="menu-title">Notification Management</li>
            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-news"></i>
                <span>News</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/news">
                    <i className="bx bxs-news"></i>
                    <span>News</span>
                  </Link>
                </li>
              </ul>
            </li>

            <li className="menu-title">Setting Management</li>
            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-cog"></i>
                <span>Settings</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/category">
                    <i className="bx bx-category-alt"></i>
                    <span>Category</span>
                  </Link>
                </li>
                <li>
                  <Link to="/region/list">
                    <i className="bx bx-map-pin"></i>
                    <span>Address</span>
                  </Link>
                </li>
                <li>
                  <Link to="/payment/list">
                    <i className="bx bx-map-pin"></i>
                    <span>Payment Option</span>
                  </Link>
                </li>
                <li>
                  <Link to="/group">
                    <i className="bx bx-group"></i>
                    <span>group</span>
                  </Link>
                </li>
                <li>
                  <Link to="/banner/list">
                    <i className="bx bxs-image"></i>
                    <span>Banner Setup</span>
                  </Link>
                </li>
                <li>
                  <Link to="/permission">
                    <i className="bx bx-lock-alt"></i>
                    <span>Permission</span>
                  </Link>
                </li>

                {/* <li>
                  <Link to="/ecommerce-orders">Orders</Link>
                </li>
                <li>
                  <Link to="/ecommerce-customers">Customers</Link>
                </li>
                <li>
                  <Link to="/ecommerce-cart">Cart</Link>
                </li>
                <li>
                  <Link to="/ecommerce-checkout">Checkout</Link>
                </li>
                <li>
                  <Link to="/ecommerce-shops">Shops</Link>
                </li>
                <li>
                  <Link to="/ecommerce-add-product">
                    Add Product
                  </Link>
                </li> */}
              </ul>
            </li>

            {/* <li>
              <Link to="/category" >
                <i className="bx bx-category-alt"></i>
                <span>Category</span>
              </Link>
            </li>

            <li>
              <Link to="/calendar" >
                <i className="bx bx-location-plus"></i>
                <span>Address</span>
              </Link>
            </li>  */}

            {/* <li className="menu-title">Apps</li>

            <li>
              <Link to="/calendar" >
                <i className="bx bx-calendar"></i>
                <span>Calendar</span>
              </Link>
            </li>

            <li>
              <Link to="/chat" >
                <i className="bx bx-chat"></i>
                <span>Chat</span>
              </Link>
            </li> */}

            {/* <li>
              <Link to="/apps-filemanager" >
                <i className="bx bx-file"></i>
                <span>File Manager</span>
              </Link>
            </li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-store"></i>
                <span>Ecommerce</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/ecommerce-products">Products</Link>
                </li>
                <li>
                  <Link to="/ecommerce-product-detail/1">
                    Product Detail
                  </Link>
                </li>
                <li>
                  <Link to="/ecommerce-orders">Orders</Link>
                </li>
                <li>
                  <Link to="/ecommerce-customers">Customers</Link>
                </li>
                <li>
                  <Link to="/ecommerce-cart">Cart</Link>
                </li>
                <li>
                  <Link to="/ecommerce-checkout">Checkout</Link>
                </li>
                <li>
                  <Link to="/ecommerce-shops">Shops</Link>
                </li>
                <li>
                  <Link to="/ecommerce-add-product">
                    Add Product
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow ">
                <i className="bx bx-bitcoin"></i>
                <span>Crypto</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/crypto-wallet">Wallet</Link>
                </li>
                <li>
                  <Link to="/crypto-buy-sell">Buy/Sell</Link>
                </li>
                <li>
                  <Link to="/crypto-exchange">Exchange</Link>
                </li>
                <li>
                  <Link to="/crypto-lending">Lending</Link>
                </li>
                <li>
                  <Link to="/crypto-orders">Orders</Link>
                </li>
                <li>
                  <Link to="/crypto-kyc-application">
                    KYC Application
                  </Link>
                </li>
                <li>
                  <Link to="/crypto-ico-landing">ICO Landing</Link>
                </li>
              </ul>
            </li> */}

            {/* <li>
              <Link to="/#" className="has-arrow ">
                <i className="bx bx-envelope"></i>
                <span>Email</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/email-inbox">Inbox</Link>
                </li>
                <li>
                  <Link to="/email-read">Read Email </Link>
                </li>
                <li>
                  <Link to="/#" className="has-arrow ">
                    <span key="t-email-templates">Templates</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/email-template-basic">
                        Basic Action
                      </Link>
                    </li>
                    <li>
                      <Link to="/email-template-alert">
                        Alert Email{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="/email-template-billing">
                        Billing Email{" "}
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </li> */}

            {/* <li>
              <Link to="/#" className="has-arrow ">
                <i className="bx bx-receipt"></i>
                <span>Invoices</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/invoices-list">Invoice List</Link>
                </li>
                <li>
                  <Link to="/invoices-detail">Invoice Detail</Link>
                </li>
              </ul>
            </li> */}

            {/* <li>
              <Link to="/#" className="has-arrow ">
                <i className="bx bx-briefcase-alt-2"></i>
                <span>Projects</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/projects-grid">Projects Grid</Link>
                </li>
                <li>
                  <Link to="/projects-list">Projects List</Link>
                </li>
                <li>
                  <Link to="/projects-overview">
                    Project Overview
                  </Link>
                </li>
                <li>
                  <Link to="/projects-create">Create New</Link>
                </li>
              </ul>
            </li> */}

            {/* <li>
              <Link to="/#" className="has-arrow ">
                <i className="bx bx-task"></i>
                <span>Tasks</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/tasks-list">Task List</Link>
                </li>
                <li>
                  <Link to="/tasks-kanban">Kanban Board</Link>
                </li>
                <li>
                  <Link to="/tasks-create">Create Task</Link>
                </li>
              </ul>
            </li> */}

            {/* <li>
              <Link to="/#" className="has-arrow ">
                <i className="bx bxs-user-detail"></i>
                <span>Contacts</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/contacts-grid">User Grid</Link>
                </li>
                <li>
                  <Link to="/contacts-list">User List</Link>
                </li>
                <li>
                  <Link to="/contacts-profile">Profile</Link>
                </li>
              </ul>
            </li> */}

            {/* <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bxs-detail" />

                <span>Blog</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/blog-list">Blog List</Link>
                </li>
                <li>
                  <Link to="/blog-grid">Blog Grid</Link>
                </li>
                <li>
                  <Link to="/blog-details">Blog Details</Link>
                </li>
              </ul>
            </li> */}

            {/* <li>
              <Link to="/#" className="has-arrow">                
                <i className="bx bx-briefcase-alt"></i>
                <span key="t-jobs">Jobs</span>
              </Link>
              <ul className="sub-menu">
                <li><Link to="/job-list">Job List</Link></li>
                <li><Link to="/job-grid">Job Grid</Link></li>
                <li><Link to="/job-apply">Apply Job</Link></li>
                <li><Link to="/job-details">Job Details</Link></li>
                <li><Link to="/job-categories">Jobs Categories</Link></li>
                <li>
                  <Link to="/#" className="has-arrow">Candidate</Link>
                  <ul className="sub-menu" aria-expanded="true">
                    <li><Link to="/candidate-list">List</Link></li>
                    <li><Link to="/candidate-overview">Overview</Link></li>
                  </ul>
                </li>
              </ul>
            </li> */}

            {/* <li className="menu-title">Pages</li>
            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-user-circle"></i>
                <span>Authentication</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/pages-login">Login</Link>
                </li>
                <li>
                  <Link to="/pages-login-2">Login 2</Link>
                </li>
                <li>
                  <Link to="/pages-register">Register</Link>
                </li>
                <li>
                  <Link to="/pages-register-2">Register 2</Link>
                </li>
                <li>
                  <Link to="/page-recoverpw">
                    Recover Password
                  </Link>
                </li>
                <li>
                  <Link to="/page-recoverpw-2">
                    Recover Password 2
                  </Link>
                </li>
                <li>
                  <Link to="/auth-lock-screen">Lock Screen</Link>
                </li>
                <li>
                  <Link to="/auth-lock-screen-2">
                    Lock Screen 2
                  </Link>
                </li>
                <li>
                  <Link to="/page-confirm-mail">Confirm Mail</Link>
                </li>
                <li>
                  <Link to="/page-confirm-mail-2">
                    Confirm Mail 2
                  </Link>
                </li>
                <li>
                  <Link to="/auth-email-verification">
                    Email Verification
                  </Link>
                </li>
                <li>
                  <Link to="/auth-email-verification-2">
                    Email Verification 2
                  </Link>
                </li>
                <li>
                  <Link to="/auth-two-step-verification">
                    Two Step Verification
                  </Link>
                </li>
                <li>
                  <Link to="/auth-two-step-verification-2">
                    Two Step Verification 2
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/#" className="has-arrow ">
                <i className="bx bx-file"></i>
                <span>Utility</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/pages-starter">Starter Page</Link>
                </li>
                <li>
                  <Link to="/pages-maintenance">Maintenance</Link>
                </li>
                <li>
                  <Link to="/pages-comingsoon">Coming Soon</Link>
                </li>
                <li>
                  <Link to="/pages-timeline">Timeline</Link>
                </li>
                <li>
                  <Link to="/pages-faqs">FAQs</Link>
                </li>
                <li>
                  <Link to="/pages-pricing">Pricing</Link>
                </li>
                <li>
                  <Link to="/pages-404">Error 404</Link>
                </li>
                <li>
                  <Link to="/pages-500">Error 500</Link>
                </li>
              </ul>
            </li>

            <li className="menu-title">Components</li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-tone" />
                <span>UI Elements</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/ui-alerts">Alerts</Link>
                </li>
                <li>
                  <Link to="/ui-buttons">Buttons</Link>
                </li>
                <li>
                  <Link to="/ui-cards">Cards</Link>
                </li>
                <li>
                  <Link to="/ui-carousel">Carousel</Link>
                </li>
                <li>
                  <Link to="/ui-dropdowns">Dropdowns</Link>
                </li>
                <li>
                  <Link to="/ui-grid">Grid</Link>
                </li>
                <li>
                  <Link to="/ui-images">Images</Link>
                </li>
                <li>
                  <Link to="/ui-lightbox">Lightbox</Link>
                </li>
                <li>
                  <Link to="/ui-modals">Modals</Link>
                </li>
                <li>
                  <Link to="/ui-offcanvas">OffCanvas</Link>
                </li>
                <li>
                  <Link to="/ui-rangeslider">
                    Range Slider
                  </Link>
                </li>
                <li>
                  <Link to="/ui-session-timeout">
                    Session Timeout
                  </Link>
                </li>
                <li>
                  <Link to="/ui-progressbars">
                    Progress Bars
                  </Link>
                </li>
                <li>
                  <Link to="/ui-placeholders">Placeholders</Link>
                </li>
                <li>
                  <Link to="/ui-tabs-accordions">
                    Tabs & Accordions
                  </Link>
                </li>
                <li>
                  <Link to="/ui-typography">
                    Typography
                  </Link>
                </li>
                <li>
                  <Link to="/ui-toasts">Toasts</Link>
                </li>
                <li>
                  <Link to="/ui-video">Video</Link>
                </li>
                <li>
                  <Link to="/ui-general">General</Link>
                </li>
                <li>
                  <Link to="/ui-colors">Colors</Link>
                </li>
                <li>
                  <Link to="/ui-rating">Rating</Link>
                </li>
                <li>
                  <Link to="/ui-notifications">
                    Notifications
                  </Link>
                </li>
                <li>
                  <Link to="/ui-breadcrumb">
                    Breadcrumb
                  </Link>
                </li> 
                <li>
                  <Link to="/ui-utilities">
                    Utilities
                    
                  </Link>
                </li>
              </ul> 
            </li> */}

            {/* <li>
              <Link to="/#" >
                <i className="bx bxs-eraser"></i>
                <span className="badge rounded-pill bg-danger float-end">
                  10
                </span>
                <span>Forms</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/form-elements">Form Elements</Link>
                </li>
                <li>
                  <Link to="/form-layouts">Form Layouts</Link>
                </li>
                <li>
                  <Link to="/form-validation">
                    Form Validation
                  </Link>
                </li>
                <li>
                  <Link to="/form-advanced">Form Advanced</Link>
                </li>
                <li>
                  <Link to="/form-editors">Form Editors</Link>
                </li>
                <li>
                  <Link to="/form-uploads">Form File Upload </Link>
                </li>
                <li>
                  <Link to="/form-repeater">Form Repeater</Link>
                </li>
                <li>
                  <Link to="/form-wizard">Form Wizard</Link>
                </li>
                <li>
                  <Link to="/form-mask">Form Mask</Link>
                </li>
                 <li>
                  <Link to="/dual-listbox">Transfer List</Link>
                </li>
               </ul>
            </li> */}

            {/* <li>
              <Link to="/#" className="has-arrow ">
                <i className="bx bx-list-ul"></i>
                <span>Tables</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/tables-basic">Basic Tables</Link>
                </li>
                <li>
                  <Link to="/tables-datatable">Data Tables</Link>
                </li> 
                <li>
                  <Link to="/tables-responsive">
                    Responsive Table
                  </Link>
                </li>
                <li>
                  <Link to="/tables-dragndrop">Drag & Drop Table</Link>
                </li>
               </ul>
            </li>  */}

            {/* <li>
              <Link to="/#" className="has-arrow ">
                <i className="bx bxs-bar-chart-alt-2"></i>
                <span>Charts</span>
              </Link>

              <ul className="sub-menu">
                <li>
                  <Link to="/apex-charts">Apex charts</Link>
                </li>
                <li>
                  <Link to="/chartjs-charts">Chartjs Chart</Link>
                </li>
                <li>
                  <Link to="/e-charts">E Chart</Link>
                </li>
                <li>
                  <Link to="/sparkline-charts">
                    Sparkline Chart
                  </Link>
                </li>
                <li>
                  <Link to="/charts-knob">Knob Chart</Link>
                </li>
                <li>
                  <Link to="/re-charts">Re Chart</Link>
                </li>
              </ul>
            </li>  */}

            {/* <li>
              <Link to="/#" className="has-arrow ">
                <i className="bx bx-aperture"></i>
                <span>Icons</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/icons-boxicons">Boxicons</Link>
                </li>
                <li>
                  <Link to="/icons-materialdesign">
                    Material Design
                  </Link>
                </li>
                <li>
                  <Link to="/icons-dripicons">Dripicons</Link>
                </li>
                <li>
                  <Link to="/icons-fontawesome">Font awesome</Link>
                </li>
              </ul>
            </li> */}

            {/* <li>
              <Link to="/#" className="has-arrow ">
                <i className="bx bx-map"></i>
                <span>Maps</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/maps-google">Google Maps</Link>
                </li>
                
              </ul>
            </li>  */}

            {/* <li>
              <Link to="/#" className="has-arrow ">
                <i className="bx bx-share-alt"></i>
                <span>Multi Level</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/#">Level 1.1</Link>
                </li>
                <li>
                  <Link to="/#" className="has-arrow">
                    Level 1.2
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/#">Level 2.1</Link>
                    </li>
                    <li>
                      <Link to="/#">Level 2.2</Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </li> */}
          </ul>
        </div>
     {/* admin  routes */}
    </React.Fragment>
  );
};
AdminSidebarComponent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default AdminSidebarComponent;
