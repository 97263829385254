import {
  GET_PAYMENT_OPTION,
  ADD_PAYMENT_OPTION_DATA,
  ADD_PAYMENT_OPTION_DATA_SUCCESS,
  ADD_PAYMENT_OPTION_DATA_FAIL,
  GET_PAYMENT_OPTION_SUCCESS,
  GET_PAYMENT_OPTION_FAIL,
  UPDATE_PAYMENT_OPTION_DATA,
  ACTIVATE_PAYMENT_OPTION,
} from "./actionTypes"

// PAYMENT_OPTION
export const getPayment_Option = () => ({
  type: GET_PAYMENT_OPTION,
})

export const addPayment_OptionData = data => ({
  type: ADD_PAYMENT_OPTION_DATA,
  payload: data,
})


export const updatePayment_OptionData = data => ({
  type: UPDATE_PAYMENT_OPTION_DATA,
  payload: data,
})

export const addPermissionPayment_OptionData = data => ({
  type: ADD_PAYMENT_OPTION_PERMISSION_DATA,
  payload: data,
})


export const getPayment_OptionDataSuccess = data => ({
  type: GET_PAYMENT_OPTION_SUCCESS,
  payload: data,
})


export const addPayment_OptionDataSuccess = data => ({
  type: ADD_PAYMENT_OPTION_DATA_SUCCESS,
  payload: data,
})

export const addPayment_OptionDataFail = error => ({
  type: ADD_PAYMENT_OPTION_DATA_FAIL,
  payload: error,
})

export const getPayment_OptionDataFail = error => ({
  type: GET_PAYMENT_OPTION_FAIL,
  payload: error,
})

/*approve Payment_Option*/ 
export const activatePayment_Option = data => ({
  type: ACTIVATE_PAYMENT_OPTION,
  payload: data,
})
/*approve Payment_Option*/ 
// PAYMENT_OPTION