import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_CUSTOMER
} from "./actionTypes"

import {
  getCustomerDataFail,
  getCustomerDataSuccess
} from "./actions"

import { toast } from "react-toastify"

// Include Both Helper File with needed methods
import {
  getCustomerList
} from "helpers/backend_helper"

// group
function* fetchCustomer() {
  try {
    const response = yield call(getCustomerList)
    yield put(getCustomerDataSuccess(response))
  } catch (error) {
    yield put(getCustomerDataFail(error))
  }
}

function* CustomerSaga() {
  yield takeEvery(GET_CUSTOMER, fetchCustomer)
}

export default CustomerSaga
