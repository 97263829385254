import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import * as moment from "moment";
import {
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent
} from "reactstrap";
import classnames from "classnames";
import { isEmpty } from "lodash";


//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import {
  getBidDetails as onGetBidDetails
} from "store/Bidding/BiddingList/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { useParams } from 'react-router-dom';

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

const BidDetails = (args) => {

  //meta title
  document.title = "Wekadau | Bidding";

  let { bid_id } = useParams();
  //  dispatch
  const dispatch = useDispatch();

  const formateDate = (date, format) => {
    const dateFormat = format ? format : "DD MMM Y";
    const date1 = moment(new Date(date)).format(dateFormat);
    return date1;
};

const priceSplitter = (number) =>
  number && number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  useEffect(() => {
    if (bid_id) {
      let data = {
        "bid_id":bid_id
      };
      dispatch(onGetBidDetails(data))
    } 
  }, [dispatch]);


  const [activeTab, setActiveTab] = useState(1); 

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const selectProductState = (state) => state.Bidding;

  const BiddingProperties = createSelector(
  selectProductState,
    (Bid) => ({
      Bidding: Bid ? Bid.data :{},
    })
  );

  const {
    Bidding,
  } = useSelector(BiddingProperties);

  const [biddings, setBiddings] = useState({})

  useEffect(() => {
  setBiddings(Bidding ? Bidding.data:{});
}, [Bidding])



  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Bidding Details" breadcrumbItem="Bidding Details" />
          {!isEmpty(biddings) && (
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <Row>
                      <Col xl={6}>
                        <div className="product-detai-imgs">
                          <Row>
                            <Col md={2} sm={3} className="col-4">
                              <Nav className="flex-column" pills>
                                <NavItem>
                                  {biddings.product.product_image.map(
                                    (product,index)=>(
                                  <NavLink key={product.id}
                                    className={classnames({
                                      active: activeTab === index +1,
                                    })}
                                    onClick={() => {
                                      toggleTab(index +1 );
                                    }}
                                  >
                                    <img
                                      src={product.Product_image}
                                      alt=""
                                      onClick={() => {
                                        imageShow(
                                          products.product_image[index],
                                          index
                                        );
                                      }}
                                      className="img-fluid mx-auto d-block rounded"
                                    />
                                  </NavLink>
                                   )
                                  )}
                                </NavItem>
                              </Nav>
                            </Col>
                            <Col md={7} sm={9} className="offset-md-1 col-8">
                              <TabContent activeTab={activeTab}>
                                {biddings.product.product_image.map(
                                    (product,index)=>(
                                  <div key={product.id} >
                                  {index + 1 === activeTab ? (<div>
                                     <img
                                      src={product.Product_image}
                                      alt={product.description}
                                      id={`expandedImg${index + 1}`}
                                      className="img-fluid mx-auto d-block"
                                    />
                                  </div>):""}         
                                  </div>
                                 )
                                  )}
                              </TabContent>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                      <Col xl="6">
                        <Row>
                          <Col xl="6" sm="12" md="6">
                          <div className="mt-4 mt-xl-3"> 
                          <p className="text-muted mb-4">
                            Product Name :<h5 className="mt-1 mb-3">{biddings.product.name}</h5> 
                          </p>
                          <p className="text-muted mb-4">
                            Descriptions :<h5 className="mt-1 mb-3">{biddings.product.description}</h5> 
                          </p>
                          <p>
                            Product Number :
                          <h5 className="mt-1 mb-3">{biddings.product.product_number}</h5>
                          </p>

                          <p>
                            Brand :
                          <h5 className="mt-1 mb-3">{biddings.product.brand}</h5>
                          </p>

                          <p>
                            Starting Price :
                          <h5 className="mt-1 mb-3">{priceSplitter(biddings.bidding_price.starting_price)} {biddings.bidding_price.currency}</h5>
                          </p>
  <p>
                            Total Product :
                          <h5 className="mt-1 mb-3">{biddings.product.quantity}</h5>
                          </p>
                          </div>
                          </Col>
                          <Col xl="6" sm="12" md="6">
 <p>
                            Ending Price :
                          <h5 className="mt-1 mb-3">{priceSplitter(biddings.bidding_price.ending_price)}  {biddings.bidding_price.currency}</h5>  
                          </p>

                          <p>
                            Starting  date:
                          <h5 className="mt-1 mb-3">{formateDate(biddings.starting_date)}</h5>
                          </p>

                          <p>
                            Ending  date:
                          <h5 className="mt-1 mb-3">{formateDate(biddings.ending_date)}</h5>
                          </p>

                          <p>
                            Condition :
                          <h5 className="mt-1 mb-3">{biddings.product.condition}</h5>
                          </p>
                           <p>
                            Sub category :
                          <h5 className="mt-1 mb-3">{biddings.product.subcategory ? biddings.product.subcategory.name :''}</h5>
                          </p>

                          <p>
                            Uploaded at :
                          <h5 className="mt-1 mb-3">{formateDate(biddings.product.created_at)}</h5>
                          </p>

                                                    <p className=" mb-4">
                           Uploaded By : <h4>
                            {biddings.product.customer ? biddings.product.customer.details.first_name + " " + biddings.product.customer.details.middle_name  + " " + biddings.product.customer.details.last_name:""}
                            </h4> 
                          </p>

                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <div className="mt-5">
                      <h5 className="mb-3">Specifications :</h5>
                      <div className="table-responsive">
                        {biddings.product.specifications}
                      </div>
                    </div>
                    {biddings.product.rejection_reason.length >= 1 ? (
                      <div className="mt-5">
                      <h5 className="mb-3 text-danger">Rejected Reason:</h5>
                      <div className="table-responsive text-danger">
                        {biddings.product.rejection_reason.length >= 1 ? 
                        biddings.product.rejection_reason.map((product,index)=>
                          (
                            <div key={product.id}>
                              {index+1} :  { product.reason} <br/>
                            </div>
                          )
                          ):""
                      }
                      </div>
                    </div>
                    ) :""}
                    
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};
export default BidDetails;
