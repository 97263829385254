import {
  GET_STAFF,
  ADD_STAFF_DATA_SUCCESS,
  ADD_STAFF_DATA_FAIL,
  GET_STAFF_SUCCESS,
  GET_STAFF_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  data: [],
  error: {},
  loading : true
}

const staff = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_STAFF:
      return {
        ...state,
        data: action.payload,
        loading : true
      }

      case GET_STAFF_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading : true
      }

    case GET_STAFF_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_STAFF_DATA_SUCCESS:
      return {
        ...state,
        data: state
      }

    case ADD_STAFF_DATA_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default staff
