import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_DISTRICT,
  ADD_DISTRICT_DATA,
  UPDATE_DISTRICT_DATA
} from "./actionTypes"


import {
  addDistrictDataSuccess,
  addDistrictDataFail,
  getDistrictDataFail,
  getDistrictDataSuccess,
} from "./actions"

import { toast } from "react-toastify"

// Include Both Helper File with needed methods
import {
  getDistrictList,
  addDistrictList,
  updateDistrictList
} from "helpers/backend_helper"

// group
function* fetchDistrict({ payload: data }) {
  try {
    const response = yield call(getDistrictList, data)
    yield put(getDistrictDataSuccess(response))
  } catch (error) {
    yield put(getDistrictDataFail(error))
  }
}

// add data 
function* onAddDistrictData({ payload: data }) {
  try {
    const response = yield call(addDistrictList, data)
    yield put(addDistrictDataSuccess(response))
    yield put({type:"GET_DISTRICT",payload:data})
    toast.success("District Added Successfully", { autoClose: 5000 });
  } catch (error) {
    const message = error.response.data.name ? error.response.data.name[0] : (error.detail ? error.detail : "System error"); 
    yield put(addDistrictDataFail(message))
    toast.error(message, { autoClose: 5000 });
  }
}

// Update district 
function* onUpdateDistrictData({ payload: data }) {
  try {
    const response = yield call(updateDistrictList, data)
    yield put({type:"GET_DISTRICT",payload:data})
    toast.success("District Update Successfully", { autoClose: 5000 });
  } catch (error) {
    const message = error.response.data.name ? error.response.data.name[0] : (error.detail ? error.detail : "System error"); 
    yield put(addDistrictDataFail(message))
    toast.error(message, { autoClose: 5000 });
  }
}

function* DistrictSaga() {
  yield takeEvery(GET_DISTRICT, fetchDistrict)
  yield takeEvery(ADD_DISTRICT_DATA, onAddDistrictData)
  yield takeEvery(UPDATE_DISTRICT_DATA, onUpdateDistrictData)
}

export default DistrictSaga
