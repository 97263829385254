import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { FORGET_PASSWORD,FORGET_PASSWORD_TOKEN } from "./actionTypes"
import { userForgetPasswordSuccess, userForgetPasswordError } from "./actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import {
  addForgetPwd,
  addForgetPwdToken,
  postJwtForgetPwd,
} from "../../../helpers/backend_helper"

const fireBaseBackend = getFirebaseBackend()

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* forgetUser({ payload: { user, history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.forgetPassword, user.email)
      if (response) {
        yield put(
          userForgetPasswordSuccess(
            "Reset link are sended to your mailbox, check there first"
          )
        )
      }
    } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const response = yield call(postJwtForgetPwd, "/jwt-forget-pwd", {
        email: user.email,
      })
      if (response) {
        yield put(
          userForgetPasswordSuccess(
            "Reset link are sended to your mailbox, check there first"
          )
        )
      }
    } else {
      const response = yield call(addForgetPwd, {
        email: user.email,
      })
      if (response) {
        yield put(
          userForgetPasswordSuccess(response.detail)
        );
        history('/forgot-password/token');
      }
    }   
  } catch (error) {
    const message= error.response.data.detail ? error.response.data.detail:error.response.data[0];
    yield put(userForgetPasswordError(message))
  }
}


//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* forgetPasswordUser({ payload: { data, history } }) {
  try {
      const response = yield call(addForgetPwdToken, data)
      if (response) {
        yield put( 
          userForgetPasswordSuccess(response.detail ? response.detail : "Successfully reset password")
        )
        history('/login');
      }
  } catch (error) {
    yield put(userForgetPasswordError(error.response.data?error.response.data.detail: error.response.data[0]))
  }
}

export function* watchUserPasswordForget() {
  yield takeEvery(FORGET_PASSWORD, forgetUser),
  yield takeEvery(FORGET_PASSWORD_TOKEN, forgetPasswordUser)
}

function* forgetPasswordSaga() {
  yield all([fork(watchUserPasswordForget)])
}

export default forgetPasswordSaga
