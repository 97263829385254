import {
  GET_CUSTOMER_PRODUCT,
  GET_CUSTOMER_PRODUCT_DETAILS,
  GET_CUSTOMER_PRODUCT_SUCCESS,
  GET_CUSTOMER_PRODUCT_DETAILS_SUCCESS,
  GET_CUSTOMER_PRODUCT_FAIL,
  GET_CUSTOMER_PRODUCT_DETAILS_FAIL,
  ADD_PRODUCT_DATA_SUCCESS,
  ADD_PRODUCT_DATA_FAIL,
  GET_CUSTOMER_APPROVED_PRODUCT
} from "./actionTypes"

const INIT_STATE = {
  data: [],
  error: {},
  loading : true
}

const product = (state = INIT_STATE, action) => {
  switch (action.type) {
    
    case GET_CUSTOMER_PRODUCT:
      return {
        ...state,
        data: action.payload,
        loading : true
      }
      
    case GET_CUSTOMER_APPROVED_PRODUCT:
      return {
        ...state,
        data: action.payload,
        loading : true
      }
      
      case GET_CUSTOMER_PRODUCT_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading : true
      }

    case GET_CUSTOMER_PRODUCT_FAIL:
      return {
        ...state,
        error: action.payload,
      }
      
      case GET_CUSTOMER_PRODUCT_DETAILS:
      return {
        ...state,
        data: action.payload,
        loading : true
      }

       case GET_CUSTOMER_PRODUCT_DETAILS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading : true
      }

    case GET_CUSTOMER_PRODUCT_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

      case ADD_PRODUCT_DATA_SUCCESS:
      return {
        ...state,
        data: state,
      }

    case ADD_PRODUCT_DATA_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}
export default product
