import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_PERMISSION,
  ADD_PERMISSION_DATA,
  UPDATE_PERMISSION_DATA
} from "./actionTypes"


import {
  addPermissionDataSuccess,
  addPermissionDataFail,
  getPermissionDataFail,
  getPermissionDataSuccess,
} from "./actions"

import { toast } from "react-toastify"

// Include Both Helper File with needed methods
import {
  getPermissionList,
  addPermissionList
} from "helpers/backend_helper"

// group
function* fetchPermission() {
  try {
    const response = yield call(getPermissionList)
    yield put(getPermissionDataSuccess(response))
  } catch (error) {
    yield put(getPermissionDataFail(error))
  }
}

// add Permission 
function* onAddPermissionData({ payload: data }) {
  try {
    console.log("data")
    console.log(data)
    console.log("data")
    // const response = yield call(addPermissionList, data)
    // yield put(addPermissionDataSuccess(response))
    yield put({type:"GET_PERMISSION"})
    toast.success("Permission Added Successfully", { autoClose: 5000 });
  } catch (error) {
    console.log(error.response)
    const message = error.response.data.name ? error.response.data.name[0] : (error.detail ? error.detail : "System error"); 
    yield put(addPermissionDataFail("message"))
    toast.error("message", { autoClose: 5000 });
  }
}

// update  permissions
function* onUpdatePermissionData({ payload: data }) {
  try {
    const response = yield call(addPermissionList, data)
    // yield put(addPermissionDataSuccess(response))
    yield put({type:"GET_PERMISSION"})
    toast.success("Permission update Successfully", { autoClose: 5000 });
  } catch (error) {
    const message = error.response.data.name ? error.response.data.name[0] : (error.detail ? error.detail : "System error"); 
    yield put(addPermissionDataFail(message))
    toast.error(message, { autoClose: 5000 });
  }
}

function* PermissionSaga() {
  yield takeEvery(GET_PERMISSION, fetchPermission)
  yield takeEvery(ADD_PERMISSION_DATA, onAddPermissionData)
  yield takeEvery(UPDATE_PERMISSION_DATA, onUpdatePermissionData)
}

export default PermissionSaga
