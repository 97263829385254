import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';

// redux
import { useSelector, useDispatch } from "react-redux"
import {refreshPage as onRefreshPage} from "store/auth/login/actions"

const Authmiddleware = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
      dispatch(onRefreshPage(navigate))
  }, [dispatch]);
// refresh token every time user wants to change a page 

  // if (!localStorage.getItem("authUser")) {
  //   return (
  //     <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
  //   );
  // }

  return (<React.Fragment>
    {props.children}
  </React.Fragment>);
};

export default Authmiddleware;
