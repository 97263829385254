import { all, fork } from "redux-saga/effects";

//public
import AccountSaga from "./auth/register/saga";
import AuthSaga from "./auth/login/saga";
import ForgetSaga from "./auth/forgetpwd/saga";
import ProfileSaga from "./auth/profile/saga";
import LayoutSaga from "./layout/saga";
import ecommerceSaga from "./e-commerce/saga";
import calendarSaga from "./calendar/saga";
import chatSaga from "./chat/saga";
import cryptoSaga from "./crypto/saga";
import invoiceSaga from "./invoices/saga";
import jobsSaga from "./jobs/saga";
import projectsSaga from "./projects/saga";
import tasksSaga from "./tasks/saga";
import mailsSaga from "./mails/saga";
import contactsSaga from "./contacts/saga";
import dashboardSaga from "./dashboard/saga";
import dashboardSaasSaga from "./dashboard-saas/saga";
import dashboardCryptoSaga from "./dashboard-crypto/saga";
import dashboardBlogSaga from "./dashboard-blog/saga";
import dashboardJobSaga from "./dashboard-jobs/saga";

// settings..
import settings  from "./setting/saga";
import news  from "./setting/News/saga";
import banner  from "./setting/banner/saga";
import paymentOption  from "./setting/paymentOption/saga";
import Group  from "./authetications/group/saga";
import Region  from "./address/region/saga";
import District  from "./address/district/saga";
import Ward  from "./address/ward/saga";
import Street  from "./address/street/saga";

// stafff and products saga
import Staff  from "./user/Staff/saga";
import Customer  from "./user/customer/saga";
import Permission from "./authetications/permission/saga";
import Product from './product/ProductList/saga';
import ApprovedProduct from './product/ApprovedProduct/saga';
import RejectedProduct from './product/RejectedProduct/saga';
import RequestProduct from './product/RequestProduct//saga';

// bidding management
import bidding from './Bidding/BiddingList/saga';

// Auction bidding management
import auction from './Bidding/AuctionBiddingList/saga';

/***CUSTOMER PRODUCT MANAGEMENTS***/ 
import CustomerProduct from './product/CustomerProduct/saga';

/***CUSTOMER BIDDING MANAGEMENTS***/ 
import CustomerBidding from './Bidding/CustomerBid/saga';

/***CUSTOMER AUCTION MANAGEMENTS***/ 
import CustomerAuction from './Bidding/CustomerAuctionBiddingList/saga';


export default function* rootSaga() {
  yield all([
    //public
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(LayoutSaga),
    fork(ecommerceSaga),
    fork(calendarSaga),
    fork(chatSaga),
    fork(mailsSaga),
    fork(cryptoSaga),
    fork(invoiceSaga),
    fork(jobsSaga),
    fork(projectsSaga),
    fork(tasksSaga),
    fork(contactsSaga),
    fork(dashboardSaga),
    fork(dashboardSaasSaga),
    fork(dashboardCryptoSaga),
    fork(dashboardBlogSaga),
    fork(dashboardJobSaga),
    // weka dau
    fork(settings),
    fork(news),
    fork(banner),
    fork(paymentOption),
    fork(Group),
    fork(Region),
    fork(District),
    fork(Ward),
    fork(Street),
    fork(Staff),
    fork(Customer),
    fork(Permission),
    fork(Product),
    fork(ApprovedProduct),
    fork(RequestProduct),
    fork(RejectedProduct),
    // bidding management
    fork(bidding),
    // auction management
    fork(auction),
    // product management
    fork(CustomerProduct),
    /**customer bidding**/ 
    fork(CustomerBidding),
    /**customer bidding**/ 
    fork(CustomerAuction),
  ]);
}
