import {
  GET_DISTRICT,
  ADD_DISTRICT_DATA_SUCCESS,
  ADD_DISTRICT_DATA_FAIL,
  ADD_DISTRICT_PERMISSION_DATA_SUCCESS,
  ADD_DISTRICT_PERMISSION_DATA_FAIL,
  GET_DISTRICT_SUCCESS,
  GET_DISTRICT_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  data: [],
  error: {},
  loading : true
}

const districts = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DISTRICT:
      return {
        ...state,
        data: action.payload,
        loading : true
      }

      case GET_DISTRICT_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading : true
      }

    case GET_DISTRICT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_DISTRICT_DATA_SUCCESS:
      return {
        ...state,
        data: state
      }

    case ADD_DISTRICT_DATA_FAIL:
      return {
        ...state,
        error: action.payload,
      }
   
    default:
      return state
  }
}

export default districts
