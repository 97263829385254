import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_GROUP,
  ADD_GROUP_DATA,
  ADD_GROUP_PERMISSION_DATA,
  UPDATE_GROUP_DATA
} from "./actionTypes"


import {
  addGroupDataSuccess,
  addGroupDataFail,
  getGroupDataFail,
  getGroupDataSuccess,
  addGroupPermissionDataFail,
  addGroupPermissionDataSuccess,
  updateGroupData
} from "./actions"

import { toast } from "react-toastify"

// Include Both Helper File with needed methods
import {
  getGroupList,
  addGroupList,
  addPermissionGroupList,
  updateGroupList
} from "helpers/backend_helper"

// group
function* fetchGroup() {
  try {
    const response = yield call(getGroupList)
    yield put(getGroupDataSuccess(response))
  } catch (error) {
    yield put(getGroupDataFail(error))
  }
}

// add data 
function* onAddGroupData({ payload: data }) {
  try {
    const response = yield call(addGroupList, data)
    yield put(addGroupDataSuccess(response))
    yield put({type:"GET_GROUP"})
    toast.success("Group Added Successfully", { autoClose: 5000 });
  } catch (error) {
    const message = error.response.data.name ? error.response.data.name[0] : (error.detail ? error.detail : "System error"); 
    yield put(addGroupDataFail(message))
    toast.error(message, { autoClose: 5000 });
  }
}

// UPDATE GROUP 
function* onUpdateGroupData({ payload: data }) {
  try {
    const response = yield call(updateGroupList, data)
    yield put({type:"GET_GROUP"})
    toast.success("Group Update Successfully", { autoClose: 5000 });
  } catch (error) {
    const message = error.response.data.name ? error.response.data.name[0] : (error.detail ? error.detail : "System error"); 
    yield put(addGroupDataFail(message))
    toast.error(message, { autoClose: 5000 });
  }
}

// assign permission to the group
function* onAddPermissionGroupData({ payload: data }) {
  try {
    const response = yield call(addPermissionGroupList, data)
    yield put({type:"GET_GROUP"})
    toast.success("Permission Assigned to Group Successfully", { autoClose: 5000 });
  } catch (error) {
    const message = error.response.data.name ? error.response.data.name[0] : (error.detail ? error.detail : "System error"); 
    yield put(addGroupPermissionDataFail(message))
    toast.error(message, { autoClose: 5000 });
  }
}

function* GroupSaga() {
  yield takeEvery(GET_GROUP, fetchGroup)
  yield takeEvery(ADD_GROUP_DATA, onAddGroupData)
  yield takeEvery(UPDATE_GROUP_DATA, onUpdateGroupData)
  yield takeEvery(ADD_GROUP_PERMISSION_DATA, onAddPermissionGroupData)
}

export default GroupSaga
