import {
  GET_AUCTION,
  GET_AUCTION_SUCCESS,
  GET_AUCTION_DETAILS_SUCCESS,
  GET_AUCTION_DETAILS_FAIL,
  GET_AUCTION_FAIL,
  GET_AUCTION_DETAILS
} from "./actionTypes"

// AUCTION
export const getAuctionOrder = () => ({
  type: GET_AUCTION,
})

// 
export const getAuctionOrderDataSuccess = data => ({
  type: GET_AUCTION_SUCCESS,
  payload: data,
})

export const getAuctionOrderDataFail = error => ({
  type: GET_AUCTION_FAIL,
  payload: error,
})
// AUCTION

/*AUCTION DETAILS*/ 
export const getAuctionOrderDetails= data => ({
  type: GET_AUCTION_DETAILS,
  payload: data,
})

export const getAuctionOrderDetailsDataSuccess = data => ({
  type: GET_AUCTION_DETAILS_SUCCESS,
  payload: data,
})

export const getAuctionOrderDetailsDataFail = error => ({
  type: GET_AUCTION_DETAILS_FAIL,
  payload: error,
})

/*AUCTION DETAILS*/ 