import {
  GET_PAYMENT_OPTION,
  ADD_PAYMENT_OPTION_DATA_SUCCESS,
  ADD_PAYMENT_OPTION_DATA_FAIL,
  GET_PAYMENT_OPTION_SUCCESS,
  GET_PAYMENT_OPTION_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  data: [],
  error: {},
  loading : true
}

const payment_option = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PAYMENT_OPTION:
      return {
        ...state,
        data: action.payload,
        loading: true,
      }

    case GET_PAYMENT_OPTION_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: true,
      }

    case GET_PAYMENT_OPTION_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_PAYMENT_OPTION_DATA_SUCCESS:
      return {
        ...state,
        data: state,
      }

    case ADD_PAYMENT_OPTION_DATA_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default payment_option
