import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_NEWS,
  ADD_NEWS_DATA,
  UPDATE_NEWS_DATA,
  ACTIVATE_NEWS
} from "./actionTypes"

import {
  addNewsDataFail,
  getNewsDataFail,
  getNewsDataSuccess,
} from "./actions"

import { toast } from "react-toastify"

// Include Both Helper File with needed methods
import {
  getNewsList,
  addNewsList,
  updateNews,
  activateNews
} from "helpers/backend_helper"

// group
function* fetchNews() {
  try {
    const response = yield call(getNewsList)
    yield put(getNewsDataSuccess(response))
  } catch (error) {
    yield put(getNewsDataFail(error))
  }
}

// add data 
function* onAddNewsData({ payload: data }) {
  try {
     const formData = new FormData()
     formData.append("id", data.id)
     formData.append("title", data.title)
     formData.append("body", data.body)
     formData.append("news_image", data.fileImage[0])
    const response = yield call(addNewsList, formData)
    yield put({type:"GET_NEWS"})
    toast.success("News Added Successfully", { autoClose: 5000 });
  } catch (error) {
    console.log(error)
    const message = error.response.data.name
      ? error.response.data.name[0]
      : error.response.data.detail 
    yield put(addNewsDataFail(message))
    toast.error(message, { autoClose: 5000 });
  }
}

// update data 
function* onUpdateNewsData({ payload: data }) {
  try {
    const response = yield call(updateNews, data)
    yield put({type:"GET_NEWS"})
    toast.success("News Edited Successfully", { autoClose: 5000 });
  } catch (error) {
    const message = error.response.data.name ? error.response.data.name[0] : (error.detail ? error.detail : error.response.data.email ? error.response.data.email[0] : "System error"); 
    yield put(addNewsDataFail(message))
    toast.error(message, { autoClose: 5000 });
  }
}

// activate News api
function* onActivateNews({ payload: data }) {
  try {
    const response = yield call(activateNews, data)
     yield put({type:"GET_NEWS"})
  } catch (error) {
    const message = error.response.data.name ? error.response.data.name[0] : (error.detail ? error.detail : "System error"); 
    yield put(addNewsDataFail(message))
    toast.error(message, { autoClose: 5000 });
  }
}

function* NewsSaga() {
  yield takeEvery(GET_NEWS, fetchNews)
  yield takeEvery(ACTIVATE_NEWS, onActivateNews)
  yield takeEvery(ADD_NEWS_DATA, onAddNewsData)
  yield takeEvery(UPDATE_NEWS_DATA, onUpdateNewsData)
}

export default NewsSaga
