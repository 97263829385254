import {
  GET_APPROVED_PRODUCT,
  GET_APPROVED_PRODUCT_DETAILS,
  GET_APPROVED_PRODUCT_SUCCESS,
  GET_APPROVED_PRODUCT_DETAILS_SUCCESS,
  GET_APPROVED_PRODUCT_FAIL,
  GET_APPROVED_PRODUCT_DETAILS_FAIL,
  APPROVE_PRODUCT,
  REJECT_PRODUCT,
  FEATURED_PRODUCT,
  UNFEATURED_PRODUCT
} from "./actionTypes"

const INIT_STATE = {
  data: [],
  error: {},
  loading : true
}

const product = (state = INIT_STATE, action) => {
  switch (action.type) {
    
    case GET_APPROVED_PRODUCT:
      return {
        ...state,
        data: action.payload,
        loading : true
      }

    case FEATURED_PRODUCT:
      return {
        ...state,
        data: action.payload,
        loading : true
      }
    case UNFEATURED_PRODUCT:
      return {
        ...state,
        data: action.payload,
        loading : true
      }
    case APPROVE_PRODUCT:
      return {
        ...state,
        data: action.payload,
        loading : true
      }
    case REJECT_PRODUCT:
      return {
        ...state,
        data: action.payload,
        loading : true
      }

      case GET_APPROVED_PRODUCT_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading : true
      }

    case GET_APPROVED_PRODUCT_FAIL:
      return {
        ...state,
        error: action.payload,
      }
      
      case GET_APPROVED_PRODUCT_DETAILS:
      return {
        ...state,
        data: action.payload,
        loading : true
      }

       case GET_APPROVED_PRODUCT_DETAILS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading : true
      }

    case GET_APPROVED_PRODUCT_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}
export default product
