import React, { useState,useMemo,useEffect,useRef } from "react";
import { createSelector } from "reselect"
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  Label,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Container,
  FormFeedback,
  UncontrolledTooltip,
  Form,
} from "reactstrap";
import { ToastContainer } from "react-toastify";

import TableContainer from '../../../components/Common/TableContainer';

import { Link } from "react-router-dom";
import Dropzone from "react-dropzone"

import {
  addCategoryData as onAddCategoryData,
  getCategory as onGetCategory,
  updateCategoryData as onUpdateCategoryData 
} from "store/setting/actions"
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
// redux
import { useSelector, useDispatch } from "react-redux"

import {  CreateadAt,CreatedBy,Image } from "../categorytCol";

import DeleteModal from "../DeleteModal";

const Category = () => {
//meta title
document.title="WekaDau | Category";

//  dispatch
const dispatch = useDispatch();

const [category, setCategory] = useState();
const [isEdit, setIsEdit] = useState(false);

// delete modal
const [deleteModal, setDeleteModal] = useState(false);
const [categories, setCategories] = useState()
const [selectedFiles, setselectedFiles] = useState([])
const [errorFiles, seterrorFiles] = useState("")


  //  modal
const [modal, setModal] = useState(false)

  const columns = useMemo(
    () => [
        {
            Header: 'Category',
            accessor: 'name',
        },
        {
          Header: 'Category Image',
          accessor: 'category_image',
          filterable: true,
          Cell: cellProps => {
          return (
            <>
              {" "}
              <Image {...cellProps} />{" "}
            </>
          )
        },
       },
        {
          Header: 'Created By',
          accessor: 'users.details',
          filterable: true,
          Cell: cellProps => {
          return (
            <>
              {" "}
              <CreatedBy {...cellProps} />{" "}
            </>
          );
        },
       },
        {
            Header: 'created At',
            accessor: 'created_at',
            filterable: true,
        Cell: cellProps => {
          return (
            <>
              {" "}
              <CreateadAt {...cellProps} />{" "}
            </>
          );
        },
        },
        {
          Header: "Action",
          Cell: cellProps => {
            return (
              <div className="d-flex gap-3">
                <Link
                  to="#"
                  className="text-info"
                  onClick={() => {
                    const categoryData = cellProps.row.original;
                    handleCategoryClick(categoryData);
                  }}
                >
                  <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    Edit
                  </UncontrolledTooltip>
                </Link>
                <Link
                  to={"/sub-category/" + cellProps.row.original.id}
                  className="text-success"
                >
                  <i className="mdi mdi-eye font-size-18" id="viewtooltip" />
                  <UncontrolledTooltip placement="top" target="viewtooltip">
                    View
                  </UncontrolledTooltip>
                </Link>
                {/* <Link
                  to="#"
                  className="text-danger"
                  onClick={() => {
                    const userData = cellProps.row.original;
                    onClickDelete(userData);
                  }}
                >
                  <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </Link> */}
              </div>
            );
          },
        },
    ],
    []
);

const onClickDelete = category => {
   setCategory(category);
   setDeleteModal(true);
};

function handleAcceptedFiles(files) {
  files.map(file => {
    if (file.type.startsWith("image/")) {
      setselectedFiles(files)
      return Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    } else {
      seterrorFiles("Please image select files")
      // handle non-image files here or simply ignore them
      return null // returning null will filter out non-image files from the resulting array
    }
  })
}

function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes"
  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
}

  const selectCategoryState = state => state.Settings
  
  const CategoryProperties = createSelector(selectCategoryState, Category => ({
    CategoryData:Category.data,
    loading: Category.loading
  }))

  const { CategoryData, loading } = useSelector(CategoryProperties)

useEffect(() => {
  dispatch(onGetCategory())
}, [dispatch])

useEffect(() => {
  setCategories(CategoryData)
}, [CategoryData])

const data = categories ? categories.data :[];

const handleCategoryClick = arg => {
  const category = arg;
  setCategory({
    id: category.id,
    name: category.name,
    image: category.category_image
  });
  setIsEdit(true);
  toggle();
};
  
// handle delete category
const handleDeleteCategory = () => {
  if (category && category.id) {
    console.log(category.id)
    // dispatch(onDeleteUser(category.id));
  }
  onPaginationPageChange(1);
  setDeleteModal(false);
};

  const toggle = () => {
    if (modal) {
      setCategory();
      setIsEdit(false);
      setModal(false)
    } else {
      setModal(true)
    }
  }

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };


  // Form validation 
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (category && category.name) ||'',
    },
    
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Category Name"),
    }),
    onSubmit: (values) => {
      if(isEdit){
       const payload = {
         name: values.name,
         id: category.id,
         fileImage: selectedFiles,
       }
       dispatch(onUpdateCategoryData(payload))
      }else{
          const payload = {
            name: values.name,
            fileImage: selectedFiles,
          }
        dispatch(onAddCategoryData(payload))
      }
      validation.resetForm()
      toggle();
    }
  });

  const regExp = /\b\d{5}\b/;
  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteCategory}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Settings" breadcrumbItem="Category List" />
          <Row>
            <Col xl="3">
              <Card>
                <CardBody>
                  <div className="text-right d-grid">
                    <Link
                      to="#"
                      className="btn btn-primary waves-effect waves-light addtask-btn"
                      data-bs-toggle="modal"
                      data-bs-target=".bs-example-modal-lg"
                      data-id="#upcoming-task"
                      onClick={() => toggle(true)}
                    >
                      <i className="mdi mdi-plus me-1"></i> Add Category
                    </Link>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl="9">
              <Card>
                <CardBody>
                  <div className="container-fluid">
                    {/* <Table columns={columns} data={data} /> */}
                    <TableContainer
                      columns={columns}
                      data={data}
                      isGlobalFilter={true}
                      isAddOptions={false}
                      customPageSize={10}
                      isPagination={true}
                      tableClass="align-middle table-nowrap table-check table"
                      theadClass="table-light"
                      paginationDiv="col-12"
                      pagination="justify-content-center pagination pagination-rounded"
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <Modal
        id="modalForm"
        isOpen={modal}
        toggle={toggle}
        centered={true}
        size="md"
      >
        <ModalHeader toggle={toggle}>
          {!!isEdit ? "Edit Category" : "Add Category"}
        </ModalHeader>
        <ModalBody>
          <Form
            className="needs-validation"
            onSubmit={e => {
              e.preventDefault()
              validation.handleSubmit()
              return false
            }}
          >
            <Row>
              <Col md="12" sm="12" lg="12">
                <FormGroup className="mb-3">
                  <Label htmlFor="validationCustom01">Category name</Label>
                  <Input
                    name="name"
                    placeholder="Category name"
                    type="text"
                    className="form-control"
                    id="validationCustom01"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.name || ""}
                    invalid={
                      validation.touched.name && validation.errors.name
                        ? true
                        : false
                    }
                  />
                  {validation.touched.name && validation.errors.name ? (
                    <FormFeedback type="invalid">
                      {validation.errors.name}
                    </FormFeedback>
                  ) : null}
                </FormGroup>
                {!!isEdit ? (
                  <div>
                    <img height="70" src={category.image} alt="image" />
                  </div>
                ) : (
                  ""
                )}
                <FormGroup className="mb-3">
                  <Dropzone
                    onDrop={acceptedFiles => {
                      handleAcceptedFiles(acceptedFiles)
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div className="dropzone">
                        <div
                          className="dz-message needsclick"
                          {...getRootProps()}
                        >
                          <input {...getInputProps()} />
                          <div className="dz-message needsclick">
                            <div className="mb-3">
                              <i className="display-4 text-muted bx bxs-cloud-upload" />
                            </div>
                            <h4>Drop banner image here or click to upload.</h4>
                          </div>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                  <div className="dropzone-previews mt-3" id="file-previews">
                    {selectedFiles.map((f, i) => {
                      return (
                        <Card
                          className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                          key={i + "-file"}
                        >
                          <div className="p-2">
                            <Row className="align-items-center">
                              <Col className="col-auto">
                                <img
                                  data-dz-thumbnail=""
                                  height="80"
                                  className="avatar-sm rounded bg-light"
                                  alt={f.name}
                                  src={f.preview}
                                />
                              </Col>
                              <Col>
                                <Link
                                  to="#"
                                  className="text-muted font-weight-bold"
                                >
                                  {f.name}
                                </Link>
                                <p className="mb-0">
                                  <strong>{f.formattedSize}</strong>
                                </p>
                              </Col>
                            </Row>
                          </div>
                        </Card>
                      )
                    })}
                    <span className="text-danger">
                      {errorFiles ? errorFiles : ""}*
                    </span>
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Button color="primary" type="submit">
              Submit
            </Button>
          </Form>
        </ModalBody>
      </Modal>
      <ToastContainer />
    </React.Fragment>
  )
};

export default Category;
