import React from 'react';
import * as moment from "moment";


const formateDate = (date, format) => {
    const dateFormat = format ? format : "DD MMM Y";
    const date1 = moment(new Date(date)).format(dateFormat);
    return date1;
};

const CreateadAt = (cell) => {
    return cell.value ? formateDate(cell.value) : '';
};

const CreatedBy = (cell) => {
    return cell.value ? cell.value.first_name.toUpperCase() + " " + cell.value.middle_name.toUpperCase() + " " + cell.value.last_name.toUpperCase() : '';
};

export {
    CreatedBy,
    CreateadAt
};

