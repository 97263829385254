/* BID */
export const GET_BID = "GET_BID"
export const GET_BID_SUCCESS = "GET_BID_SUCCESS"
export const GET_BID_FAIL = "GET_BID_FAIL"

/*BID DETAILS*/ 
export const GET_BID_DETAILS = "GET_BID_DETAILS"
export const GET_BID_DETAILS_SUCCESS = "GET_BID_DETAILS_SUCCESS"
export const GET_BID_DETAILS_FAIL = "GET_BID_DETAILS_FAIL"


