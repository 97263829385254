import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_CUSTOMER_PRODUCT,
  GET_CUSTOMER_PRODUCT_DETAILS,
  ADD_PRODUCT_DATA,
  GET_CUSTOMER_APPROVED_PRODUCT
} from "./actionTypes"

import {
  getCustomerProductDataFail,
  getCustomerProductDataSuccess,
  getCustomerProductDetailsDataSuccess,
  getCustomerProductDetailsDataFail,
  addProductDataSuccess,
  addProductDataFail
} from "./actions"

import { toast } from "react-toastify"

// Include Both Helper File with needed methods
import {
  getCustomerProductListApi,
  getCustomerProductDetailsApi,
  addProductList,
  getCustomerApprovedProductListApi
} from "helpers/backend_helper"

// fetch all customer products.
function* fetchCustomerProduct() {
  try {
    const response = yield call(getCustomerProductListApi)
    yield put(getCustomerProductDataSuccess(response))
  } catch (error) {
    yield put(getCustomerProductDataFail(error))
  }
}

// fetch customer product .
function* fetchProductDetails({ payload: data }) {
  try {
    const response = yield call(getCustomerProductDetailsApi, data)
    yield put(getCustomerProductDetailsDataSuccess(response))
  } catch (error) {
    yield put(getCustomerProductDetailsDataFail(error))
  }
}

/**ADD PRODUCT**/ 
function* onAddProductData({ payload: data }) {
  try {
    const formData = new FormData()
    formData.append("name",data.name)
    formData.append("product_number", data.productModel)
    formData.append("condition", data.condition)
    formData.append("brand", data.brand)
    formData.append("quantity", data.quantity)
    formData.append("specifications", data.specifications)
    formData.append("description", data.description)
    formData.append("manufacture_year", data.manufactureYear)
    formData.append("subcategory", data.subcategory)
    console.log("data")
    console.log(data.ProductImages)
    console.log("data")
    data.ProductImages.forEach(image => {
      formData.append('images', image);
    });
    formData.append("youtube_link", data.youtube_link)
    const response = yield call(addProductList, formData)
    // yield put(addProductDataSuccess(response))
    yield put({type:"GET_CUSTOMER_PRODUCT"})
    toast.success("Product Added Successfully", { autoClose: 5000 });
  } catch (error) {
    const message = error.response.data.name ? error.response.data.name[0] : (error.detail ? error.detail : "System error"); 
    yield put(addProductDataFail(message))
    toast.error(message, { autoClose: 5000 });
  }
} 

// fetch all customer approved products.
function* fetchCustomerApprovedProduct() {
  try {
    const response = yield call(getCustomerApprovedProductListApi)
    yield put(getCustomerProductDataSuccess(response))
  } catch (error) {
    yield put(getCustomerProductDataFail(error))
  }
}

function* ProductSaga() {
  yield takeEvery(GET_CUSTOMER_PRODUCT, fetchCustomerProduct)
  yield takeEvery(GET_CUSTOMER_APPROVED_PRODUCT, fetchCustomerApprovedProduct)
  yield takeEvery(ADD_PRODUCT_DATA, onAddProductData)
  yield takeEvery(GET_CUSTOMER_PRODUCT_DETAILS, fetchProductDetails)
}

export default ProductSaga
