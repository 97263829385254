import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_REGION,
  ADD_REGION_DATA,
  ADD_REGION_PERMISSION_DATA,
  UPDATE_REGION_DATA
} from "./actionTypes"


import {
  addRegionDataSuccess,
  addRegionDataFail,
  getRegionDataFail,
  getRegionDataSuccess,
  updateRegionData
} from "./actions"

import { toast } from "react-toastify"

// Include Both Helper File with needed methods
import {
  getRegionList,
  addRegionList,
  updateRegionList
} from "helpers/backend_helper"

// group
function* fetchRegion() {
  try {
    const response = yield call(getRegionList)
    yield put(getRegionDataSuccess(response))
  } catch (error) {
    yield put(getRegionDataFail(error))
  }
}

// add data 
function* onAddRegionData({ payload: data }) {
  try {
    const response = yield call(addRegionList, data)
    yield put(addRegionDataSuccess(response))
    yield put({type:"GET_REGION"})
    toast.success("Region Added Successfully", { autoClose: 5000 });
  } catch (error) {
    const message = error.response.data.name ? error.response.data.name[0] : (error.detail ? error.detail : "System error"); 
    yield put(addRegionDataFail(message))
    toast.error(message, { autoClose: 5000 });
  }
}

// updates data 
function* onUpdateRegionData({ payload: data }) {
  try {
    const response = yield call(updateRegionList, data)
    yield put({type:"GET_REGION"})
    toast.success("Region Updated Successfully", { autoClose: 5000 });
  } catch (error) {
    const message = error.response.data.name ? error.response.data.name[0] : (error.detail ? error.detail : "System error"); 
    yield put(addRegionDataFail(message))
    toast.error(message, { autoClose: 5000 });
  }
}

function* RegionSaga() {
  yield takeEvery(GET_REGION, fetchRegion)
  yield takeEvery(ADD_REGION_DATA, onAddRegionData)
  yield takeEvery(UPDATE_REGION_DATA, onUpdateRegionData)
}

export default RegionSaga
