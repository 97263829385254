import {
  GET_CUSTOMER,
  GET_CUSTOMER_SUCCESS,
  GET_CUSTOMER_FAIL,
} from "./actionTypes"

// CUSTOMER
export const getCustomer = () => ({
  type: GET_CUSTOMER,
})

export const getCustomerDataSuccess = data => ({
  type: GET_CUSTOMER_SUCCESS,
  payload: data,
})

export const getCustomerDataFail = error => ({
  type: GET_CUSTOMER_FAIL,
  payload: error,
})
// CUSTOMER