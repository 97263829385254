import React, { useState,useMemo,useEffect,useRef } from "react";
import { createSelector } from "reselect"
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  UncontrolledTooltip,
  Button
} from "reactstrap";

import TableContainer from '../../components/Common/TableContainer';
import { ToastContainer } from "react-toastify";
import Spinners from "components/Common/Spinner";

import { Link } from "react-router-dom";


import {
  getRejectedProduct as onGetProduct
} from "store/product/RejectedProduct/actions"

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
// redux
import { useSelector, useDispatch } from "react-redux"

import {  CreateadAt,Customer,Price } from "./Col";

const Product = () => {
//meta title
document.title="WekaDau | Products";
//  dispatch
const dispatch = useDispatch({});
const [customer, setProduct] = useState();
const [isEdit, setIsEdit] = useState(false);

// products 
const [products, setProducts] = useState([])

useEffect(() => {
  dispatch(onGetProduct())
}, [dispatch])


const onClickDelete = category => {
   setProduct(category);
   setDeleteModal(true);
};

  const selectProductState = state => state.RejectedProduct

  
  const ProductProperties = createSelector(selectProductState, Product => ({
    ProductData:Product,
    loading: Product
  }))

  const { ProductData, loading } = useSelector(ProductProperties ? ProductProperties :[])
  const [isLoading, setLoading] = useState(loading)

useEffect(() => {
  setProducts(ProductData ? ProductData.data:[])
}, [ProductData])

const handleProductClick = arg => {
  const category = arg;
  setProduct(category);
  setIsEdit(true);
  toggle();
};
  
// handle delete category
const handleDeleteProduct = () => {
  if (category && category.id) {
    // dispatch(onDeleteUser(category.id));
  }
  onPaginationPageChange(1);
  setDeleteModal(false);
};

  const toggle = () => {
    if (modal) {
      setProduct()
      setIsEdit(false);
      setModal(false)
    } else {
      setModal(true)
    }
  }

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const columns = useMemo(
    () => [
       {
            Header: 'Customer Name',
            accessor: 'customer',
            filterable: true,
            Cell: cellProps => {
              return (
                <>
                  {" "}
                  <Customer {...cellProps} />{" "}
                </>
              );
            },
      },
        {
            Header: 'Product Name',
            accessor: 'name',
        },
        {
            Header: 'brand',
            accessor: 'brand',
        },
        {
            Header: 'Quantity',
            accessor: 'quantity',
        },
        {
            Header: 'created At',
            accessor: 'created_at',
            filterable: true,
            Cell: cellProps => {
              return (
                <>
                  {" "}
                  <CreateadAt {...cellProps} />{" "}
                </>
              );
            },
      },
        {
          Header: "Action",
          Cell: cellProps => {
            return (
              <div className="d-flex gap-3">
                
              <Link
                  to={"/product-details/list/" + cellProps.row.original.id}
                  className="text-success "
                >
                  <Button color="success" outline>
                    View
                  <i className="mdi mdi-eye font-size-18 m-1" id="viewtooltip" />
                  <UncontrolledTooltip placement="top" target="viewtooltip">
                    Preview Product
                  </UncontrolledTooltip>
                   </Button>
                </Link>
              </div>
            );
          },
        },
    ],
    []
);

  return (
    <div>
          {
          isLoading ? <Spinners setLoading={setLoading} />
            :
          <Row>
            <Col xl="12">
            <Card>
                <CardBody>                  
                <div className="container-fluid">
                <TableContainer
                    columns={columns}
                    data={products ? products.data :[]}
                    isGlobalFilter={true}
                    isAddOptions={false}
                    customPageSize={10}
                    isPagination={true}
                    tableClass="align-middle table-nowrap table-check table"
                    theadClass="table-light"
                    paginationDiv="col-12"
                    pagination="justify-content-center pagination pagination-rounded"
                />
            </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          }
      <ToastContainer />
    </div>   
  );
};
export default Product;
