import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_CUSTOMER_BID,
  GET_CUSTOMER_BID_DETAILS,
  ADD_BID_DATA
} from "./actionTypes"

import {
  getCustomerBidDataFail,
  getCustomerBidDataSuccess,
  getCustomerBidDetailsDataSuccess,
  getCustomerBidDetailsDataFail,
  addBidDataSuccess,
  addBidDataFail
} from "./actions"

import { toast } from "react-toastify"

// Include Both Helper File with needed methods
import {
  getCustomerBidListApi,
  getCustomerBidDetailsApi,
  addCustomerBidApi
} from "helpers/backend_helper"

// fetch all customer bids.
function* fetchCustomerBid() {
  try {
    const response = yield call(getCustomerBidListApi)
    yield put(getCustomerBidDataSuccess(response))
  } catch (error) {
    yield put(getCustomerBidDataFail(error))
  }
}

// fetch customer bid .
function* fetchBidDetails({ payload: data }) {
  try {
    const response = yield call(getCustomerBidDetailsApi, data)
    yield put(getCustomerBidDetailsDataSuccess(response))
  } catch (error) {
    yield put(getCustomerBidDetailsDataFail(error))
  }
}

/**ADD BID**/ 
function* onAddBidData({ payload: data }) {
  try {
    const response = yield call(addCustomerBidApi, data)
    yield put({type:"GET_CUSTOMER_BID"})
    toast.success("Bid Added Successfully", { autoClose: 5000 });
  } catch (error) {
    const message = error.response.data.name ? error.response.data.name[0] : (error.detail ? error.detail : "System error"); 
    yield put(addBidDataFail(message))
    toast.error(message, { autoClose: 5000 });
  }
} 


function* BidSaga() {
  yield takeEvery(GET_CUSTOMER_BID, fetchCustomerBid)
  yield takeEvery(ADD_BID_DATA, onAddBidData)
  yield takeEvery(GET_CUSTOMER_BID_DETAILS, fetchBidDetails)
}

export default BidSaga
