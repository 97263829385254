import {
  GET_WARD,
  ADD_WARD_DATA,
  ADD_WARD_DATA_SUCCESS,
  ADD_WARD_DATA_FAIL,
  GET_WARD_SUCCESS,
  GET_WARD_FAIL,
  UPDATE_WARD_DATA
} from "./actionTypes"

// WARD
export const getWard = data => ({
  type: GET_WARD,
  payload: data,
})

export const addWardData = data => ({
  type: ADD_WARD_DATA,
  payload: data,
})

export const updateWardData = data => ({
  type: UPDATE_WARD_DATA,
  payload: data,
})

export const getWardDataSuccess = data => ({
  type: GET_WARD_SUCCESS,
  payload: data,
})


export const addWardDataSuccess = data => ({
  type: ADD_WARD_DATA_SUCCESS,
  payload: data,
})

export const addWardDataFail = error => ({
  type: ADD_WARD_DATA_FAIL,
  payload: error,
})

export const getWardDataFail = error => ({
  type: GET_WARD_FAIL,
  payload: error,
})
// WARD