import {
  GET_CUSTOMER,
  GET_CUSTOMER_SUCCESS,
  GET_CUSTOMER_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  data: [],
  error: {},
  loading : true
}

const customer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CUSTOMER:
      return {
        ...state,
        data: action.payload,
        loading : true
      }

      case GET_CUSTOMER_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading : true
      }

    case GET_CUSTOMER_FAIL:
      return {
        ...state,
        error: action.payload,
      }
      
    default:
      return state
  }
}

export default customer
