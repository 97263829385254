import React, { useState,useMemo,useEffect,useRef } from "react";
import { createSelector } from "reselect"
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  UncontrolledTooltip,
  Button
} from "reactstrap";
import TableContainer from '../../components/Common/TableContainer';
import { ToastContainer } from "react-toastify";
import Spinners from "components/Common/Spinner";
import { Link } from "react-router-dom";
import {
  getBid as onGetBid
} from "store/Bidding/BiddingList/actions"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
// redux
import { useSelector, useDispatch } from "react-redux"
import {  CreateadAt,Customer,Price,PriceFormat } from "./Col";

// bidding contents
const Bidding = () => {
//meta title
document.title="WekaDau | Bidding";
//  dispatch
const dispatch = useDispatch({});
const [customer, setBid] = useState();
const [isEdit, setIsEdit] = useState(false);

// Bids 
const [Bids, setBids] = useState([])

useEffect(() => {
  dispatch(onGetBid())
}, [dispatch])


const onClickDelete = category => {
   setBid(category);
   setDeleteModal(true);
};

  const selectBidState = state => state.Bidding

  const BidProperties = createSelector(selectBidState, Bid => ({
    BidData:Bid,
    loading: Bid
  }))

  const { BidData, loading } = useSelector(BidProperties ? BidProperties :[])
  const [isLoading, setLoading] = useState(loading)

useEffect(() => {
  setBids(BidData ? BidData.data:[])
}, [BidData])

  
// handle delete category
const handleDeleteBid = () => {
  if (category && category.id) {
    // dispatch(onDeleteUser(category.id));
  }
  onPaginationPageChange(1);
  setDeleteModal(false);
};

  const toggle = () => {
    if (modal) {
      setBid()
      setIsEdit(false);
      setModal(false)
    } else {
      setModal(true)
    }
  }

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const columns = useMemo(
    () => [
       {
            Header: 'Customer Name',
            accessor: 'customer',
            filterable: true,
            Cell: cellProps => {
              return (
                <>
                  {" "}
                  <Customer {...cellProps} />{" "}
                </>
              );
            },
      },
        {
            Header: 'Product Name',
            accessor: 'product.name',
        },
        {
            Header: 'brand',
            accessor: 'product.brand',
        },
        {
            Header: 'Quantity',
            accessor: 'product.quantity',
        },
        {
            Header: 'Starting Date',
            accessor: 'starting_date',
            filterable: true,
            Cell: cellProps => {
              return (
                <>
                  {" "}
                  <CreateadAt {...cellProps} />{" "}
                </>
              );
            },
        },
        {
            Header: 'Ending Date',
            accessor: 'ending_date',
             filterable: true,
            Cell: cellProps => {
              return (
                <>
                  {" "}
                  <CreateadAt {...cellProps} />{" "}
                </>
              );
            },
        },
        
        {
            Header: 'Starting Price',
            accessor: 'bidding_price.starting_price',
            filterable: true,
            Cell: cellProps => {
              return (
                <>
                  {" "}
                  <PriceFormat {...cellProps} />{" "}
                </>
              );
            },
        },
        {
            Header: 'Ending Price',
            accessor: 'bidding_price.ending_price',
            filterable: true,
            Cell: cellProps => {
              return (
                <>
                  {" "}
                  <PriceFormat {...cellProps} />{" "}
                </>
              );
            },
        },
        {
            Header: 'created At',
            accessor: 'created_at',
            filterable: true,
            Cell: cellProps => {
              return (
                <>
                  {" "}
                  <CreateadAt {...cellProps} />{" "}
                </>
              );
            },
      },
        {
          Header: "Action",
          Cell: cellProps => {
            return (
              <div className="d-flex gap-3">
                
              <Link
                  to={"/bid/details/" + cellProps.row.original.id}
                  className="text-success "
                >
                  <Button color="success" outline>
                    View
                  <i className="mdi mdi-eye font-size-18 m-1" id="viewtooltip" />
                  <UncontrolledTooltip placement="top" target="viewtooltip">
                    Preview Bid
                  </UncontrolledTooltip>
                   </Button>
                </Link>
              </div>
            );
          },
        },
    ],
    []
);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Bidding" breadcrumbItem="Bidding " />
        <div>
              {
              isLoading ? <Spinners setLoading={setLoading} />
                :
              <Row>
                <Col xl="12">
                <Card>
                    <CardBody>                  
                    <div className="container-fluid">
                    <TableContainer
                        columns={columns}
                        data={Bids ? Bids.data :[]}
                        isGlobalFilter={true}
                        isAddOptions={false}
                        customPageSize={10}
                        isPagination={true}
                        tableClass="align-middle table-nowrap table-check table"
                        theadClass="table-light"
                        paginationDiv="col-12"
                        pagination="justify-content-center pagination pagination-rounded"
                    />
                </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              }
          <ToastContainer />
        </div>  
    </Container>
    </div>
    </React.Fragment>
  );
};
export default Bidding;
