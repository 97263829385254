import {
  GET_REGION,
  ADD_REGION_DATA_SUCCESS,
  ADD_REGION_DATA_FAIL,
  ADD_REGION_PERMISSION_DATA_SUCCESS,
  ADD_REGION_PERMISSION_DATA_FAIL,
  GET_REGION_SUCCESS,
  GET_REGION_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  data: [],
  error: {},
  loading : true
}

const regions = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_REGION:
      return {
        ...state,
        data: action.payload,
        loading : true
      }

      case GET_REGION_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading : true
      }

    case GET_REGION_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_REGION_DATA_SUCCESS:
      return {
        ...state,
        data: state
      }

    case ADD_REGION_DATA_FAIL:
      return {
        ...state,
        error: action.payload,
      }
   
    default:
      return state
  }
}

export default regions
