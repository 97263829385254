import {
  GET_REGION,
  ADD_REGION_DATA,
  ADD_REGION_DATA_SUCCESS,
  ADD_REGION_DATA_FAIL,
  GET_REGION_SUCCESS,
  GET_REGION_FAIL,
  UPDATE_REGION_DATA
} from "./actionTypes"

// REGION
export const getRegion = () => ({
  type: GET_REGION,
})

export const addRegionData = data => ({
  type: ADD_REGION_DATA,
  payload: data,
})

export const updateRegionData = data => ({
  type: UPDATE_REGION_DATA,
  payload: data,
})

export const getRegionDataSuccess = data => ({
  type: GET_REGION_SUCCESS,
  payload: data,
})


export const addRegionDataSuccess = data => ({
  type: ADD_REGION_DATA_SUCCESS,
  payload: data,
})

export const addRegionDataFail = error => ({
  type: ADD_REGION_DATA_FAIL,
  payload: error,
})

export const getRegionDataFail = error => ({
  type: GET_REGION_FAIL,
  payload: error,
})
// REGION