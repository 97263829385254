/* WARD */
export const GET_WARD = "GET_WARD"
export const GET_WARD_SUCCESS = "GET_WARD_SUCCESS"
export const GET_WARD_FAIL = "GET_WARD_FAIL"
/*ADD WARD*/
export const ADD_WARD_DATA = "ADD_WARD_DATA"
export const UPDATE_WARD_DATA = "UPDATE_WARD_DATA"
export const ADD_WARD_DATA_SUCCESS = "ADD_WARD_DATA_SUCCESS"
export const ADD_WARD_DATA_FAIL = "ADD_WARD_DATA_FAIL"

