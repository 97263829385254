import {
  GET_BID,
  GET_BID_SUCCESS,
  GET_BID_DETAILS_SUCCESS,
  GET_BID_DETAILS_FAIL,
  GET_BID_FAIL,
  GET_BID_DETAILS
} from "./actionTypes"

// BID
export const getBid = () => ({
  type: GET_BID,
})

// 
export const getBidDataSuccess = data => ({
  type: GET_BID_SUCCESS,
  payload: data,
})

export const getBidDataFail = error => ({
  type: GET_BID_FAIL,
  payload: error,
})
// BID

/*BID DETAILS*/ 
export const getBidDetails= data => ({
  type: GET_BID_DETAILS,
  payload: data,
})

export const getBidDetailsDataSuccess = data => ({
  type: GET_BID_DETAILS_SUCCESS,
  payload: data,
})

export const getBidDetailsDataFail = error => ({
  type: GET_BID_DETAILS_FAIL,
  payload: error,
})

/*BID DETAILS*/ 