/**BID LIST**/
export const ADD_BID_DATA = "ADD_BID_DATA"
export const ADD_BID_DATA_FAIL = "ADD_BID_DATA_FAIL"
export const ADD_BID_DATA_SUCCESS = "ADD_BID_DATA_SUCCESS"

/**GET BID**/ 
export const GET_CUSTOMER_BID = "GET_CUSTOMER_BID"
export const GET_CUSTOMER_BID_SUCCESS = "GET_CUSTOMER_BID_SUCCESS"
export const GET_CUSTOMER_BID_FAIL = "GET_CUSTOMER_BID_FAIL"

/*BID DETAILS*/ 
export const GET_CUSTOMER_BID_DETAILS = "GET_CUSTOMER_BID_DETAILS"
export const GET_CUSTOMER_BID_DETAILS_SUCCESS = "GET_CUSTOMER_BID_DETAILS_SUCCESS"
export const GET_CUSTOMER_BID_DETAILS_FAIL = "GET_CUSTOMER_BID_DETAILS_FAIL"

/* BID APPROVAL*/ 
export const CUSTOMER_BID = "CUSTOMER_BID"

