import React from 'react';
import * as moment from "moment";

const TruncatedText = ({ text, maxLength }) => {
  if (text.length <= maxLength) {
    return <span>{text}</span>
  }

  const truncatedText = text.substring(0, maxLength) + "..."

  return <span title={text}>{truncatedText}</span>
}

const formateDate = (date, format) => {
    const dateFormat = format ? format : "DD MMM Y";
    const date1 = moment(new Date(date)).format(dateFormat);
    return date1;
};

const CreateadAt = (cell) => {
    return cell.value ? formateDate(cell.value) : '';
};

const LongText = cell => {
  return cell.value ? (
    <>
      <TruncatedText text={cell.value} maxLength={20} />
    </>
  ) : (
    ""
  )
}


const Image = cell => {
  return cell.value ? (
    <div>
      <img height="50" src={cell.value} alt="image" />
    </div>
  ) : (
    <>
      <span className="badge rounded-pill bg-info">N/a</span>
    </>
  )
}


  const convertToSentenceCase = (text) => {
    return text.charAt(0).toUpperCase() + text.slice(1);
  };

const CreatedBy = (cell) => {
   return cell.value
     ? convertToSentenceCase(cell.value.first_name) +
         " " +
         convertToSentenceCase(cell.value.middle_name) +
         " " +
         convertToSentenceCase(cell.value.last_name)
     : ""
};

export { CreatedBy, CreateadAt, Image, LongText }

