import {
  GET_DISTRICT,
  ADD_DISTRICT_DATA,
  ADD_DISTRICT_DATA_SUCCESS,
  ADD_DISTRICT_DATA_FAIL,
  GET_DISTRICT_SUCCESS,
  GET_DISTRICT_FAIL,
  UPDATE_DISTRICT_DATA
} from "./actionTypes"

// DISTRICT
export const getDistrict = data => ({
  type: GET_DISTRICT,
  payload: data,
})

export const addDistrictData = data => ({
  type: ADD_DISTRICT_DATA,
  payload: data,
})

// update district
export const updateDistrictData = data => ({
  type: UPDATE_DISTRICT_DATA,
  payload: data,
})

export const getDistrictDataSuccess = data => ({
  type: GET_DISTRICT_SUCCESS,
  payload: data,
})


export const addDistrictDataSuccess = data => ({
  type: ADD_DISTRICT_DATA_SUCCESS,
  payload: data,
})

export const addDistrictDataFail = error => ({
  type: ADD_DISTRICT_DATA_FAIL,
  payload: error,
})

export const getDistrictDataFail = error => ({
  type: GET_DISTRICT_FAIL,
  payload: error,
})
// DISTRICT