/* AUCTION */
export const GET_AUCTION = "GET_AUCTION"
export const GET_AUCTION_SUCCESS = "GET_AUCTION_SUCCESS"
export const GET_AUCTION_FAIL = "GET_AUCTION_FAIL"

/*AUCTION DETAILS*/ 
export const GET_AUCTION_DETAILS = "GET_AUCTION_DETAILS"
export const GET_AUCTION_DETAILS_SUCCESS = "GET_AUCTION_DETAILS_SUCCESS"
export const GET_AUCTION_DETAILS_FAIL = "GET_AUCTION_DETAILS_FAIL"


