import React, { useState,useMemo,useEffect,useRef } from "react";
import { createSelector } from "reselect"
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  UncontrolledTooltip
} from "reactstrap";

import TableContainer from '../../components/Common/TableContainer';
import { ToastContainer } from "react-toastify";
import Spinners from "components/Common/Spinner";

import { Link } from "react-router-dom";

import {
  getCustomer as onGetCustomer
} from "store/user/customer/actions"

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
// redux
import { useSelector, useDispatch } from "react-redux"

import {  CreateadAt,CreatedBy } from "./categorytCol";

const Customer = () => {
//meta title
document.title="WekaDau | Customer";

//  dispatch
const dispatch = useDispatch({});

const [customer, setCustomer] = useState();
const [isEdit, setIsEdit] = useState(false);

// customer 
const [customers, setCustomers] = useState([])
console.log(customers)
useEffect(() => {
  dispatch(onGetCustomer())
}, [dispatch])


const onClickDelete = category => {
   setCustomer(category);
   setDeleteModal(true);
};

  const selectCustomerState = state => state.customer


  // console.log(selectCustomerState)
  
  const CustomerProperties = createSelector(selectCustomerState, Customer => ({
    CustomerData:Customer,
    loading: Customer
  }))

  const { CustomerData, loading } = useSelector(CustomerProperties ? CustomerProperties :[])
  const [isLoading, setLoading] = useState(loading)

 
useEffect(() => {
  setCustomers(CustomerData ? CustomerData.data:[])
}, [CustomerData])

const handleCustomerClick = arg => {
  const category = arg;
  setCustomer(category);
  setIsEdit(true);
  toggle();
};
  
// handle delete category
const handleDeleteCustomer = () => {
  if (category && category.id) {
    // dispatch(onDeleteUser(category.id));
  }
  onPaginationPageChange(1);
  setDeleteModal(false);
};

  const toggle = () => {
    if (modal) {
      setCustomer()
      setIsEdit(false);
      setModal(false)
    } else {
      setModal(true)
    }
  }

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };


  // Form validation 
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      first_name: (customer && customer.user.first_name) ||'',
      middle_name: (customer && customer.user.middle_name) ||'',
      last_name: (customer && customer.user.last_name) ||'',
      email: (customer && customer.email) ||'',
      phone_number:(customer && customer.user.phone_number) ||''
    },
      validationSchema: Yup.object({
      last_name: Yup.string().required("Please Enter Last Name"),
      first_name: Yup.string().required("Please Enter First Name"),
      middle_name: Yup.string().required("Please Enter Middle Name"),
      phone_number: Yup.number().required( "Please Enter Phone Number"),
      email: Yup.string().email("Must be a valid Email").max(255).required("Email is required"),
    }),
    
    onSubmit: (values) => {
      if(isEdit){
      dispatch(onUpdateCustomerData(values));
      }else{
       dispatch(onAddCustomerData(values));
      }
      validation.resetForm()
      toggle();
    }
  });
 
  const columns = useMemo(
    () => [
         {
          Header: 'Customer Name',
          accessor: 'details',
          filterable: true,
          Cell: cellProps => {
          return (
            <>
              {" "}
              <CreatedBy {...cellProps} />{" "}
            </>
          );
        },
       },
        {
            Header: 'Customer Email',
            accessor: 'email',
        },
        {
            Header: 'created At',
            accessor: 'details.created_at',
            filterable: true,
        Cell: cellProps => {
          return (
            <>
              {" "}
              <CreateadAt {...cellProps} />{" "}
            </>
          );
        },
      },
        {
            Header: 'Phone Number',
            accessor: 'details.phone_number',
            filterable: true,
        },
        {
          Header: "Action",
          Cell: cellProps => {
            return (
              <div className="d-flex gap-3">
                <Link
                  to="#"
                  className="text-danger"
                  onClick={() => {
                    const userData = cellProps.row.original;
                    // onClickDelete(userData);
                  }}
                >
                  <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Deactivate
                  </UncontrolledTooltip>
                </Link>
              </div>
            );
          },
        },
    ],
    []
);

// const column_data = customers ? customers.data :[];

  const regExp = /\b\d{5}\b/;
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Settings" breadcrumbItem="Customer List" />
          {
          isLoading ? <Spinners setLoading={setLoading} />
            :
          <Row>
            <Col xl="12">
            <Card>
                <CardBody>
                <div className="container-fluid">
                 <TableContainer
                    columns={columns}
                    data={customers}
                    isGlobalFilter={true}
                    isAddOptions={false}
                    customPageSize={10}
                    isPagination={true}
                    tableClass="align-middle table-nowrap table-check table"
                    theadClass="table-light"
                    paginationDiv="col-12"
                    pagination="justify-content-center pagination pagination-rounded"
                /> 
            </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          }
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};
export default Customer;
