import cx from "classnames";
import * as React from "react";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {  Label } from "reactstrap";
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const condition_data = [
  { name: "New", value: "New" },
  { name: "Old", value: "Old" },
  { name: "Refurbished", value: "Refurbished" }
];

const handleChangeData=()=>{
  console.log("data");
}

const ProductDetailsForm = ({ errors, values, handleChange }) => (
  <>
    <div className="columns" >
      <div className="column is-6">
        <div className="field">
          <label className="label">Product Name</label>
          <div className="control">
            <input
              name="name"
              className={cx("input", !!errors.name && "is-danger")}
              type="text"
              placeholder="Product Name"
              value={values.name}
              onChange={handleChange}
            />
          </div>
          {errors.name && (
            <p className="help is-danger">{errors.name}</p>
          )}
        </div>
      </div>
      <div className="column is-6">
        <div className="field">
          <label className="label">Product Model</label>
          <div className="control">
            <input
              name="productModel"
              className="input"
              type="text"
              placeholder="Product Model"
              value={values.productModel}
              onChange={handleChange}
            />
          </div>
          {errors.productModel && (
            <p className="help is-danger">{errors.productModel}</p>
          )}
        </div>
      </div>
    </div>
   
    <div className="columns">
      <div className="column is-6">
        <div className="field">
          <label className="label">Product Brand</label>
          <div className="control">
            <input
              name="brand"
              className={cx("input", !!errors.brand && "is-danger")}
              type="text"
              placeholder="Brand"
              value={values.brand}
              onChange={handleChange}
            />
          </div>
          {errors.brand && (
            <p className="help is-danger">{errors.brand}</p>
          )}
        </div>
      </div>
      <div className="column is-6">
        <div className="field">
          <label className="label">Product Condition</label>
          <div className="control">
              <select                                  
                name="condition"
                value={values.condition}
                onChange={handleChange}
                className={cx("input", !!errors.condition && "is-danger")}
                >
                   <option value="">Select Conditions</option>
                    {condition_data.map(conditions =>(
                    <option  key={conditions.name} value={conditions.value}>
                     {conditions.name}
                    </option>
                    ))}          
                </select>
          </div>
          {errors.condition && (
            <p className="help is-danger">{errors.condition}</p>
          )}
        </div>
      </div>
    </div>
   
    <div className="columns">
      <div className="column is-6">
        <div className="field">
          <label className="label">Manufacture Year</label>
          <div className="control">
            <input
              name="manufactureYear"
              className="input"
              type="number"
              placeholder="Manufacture Year"
              value={values.manufactureYear}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
      <div className="column is-6">
        <div className="field">
          <label className="label">Product Quantity</label>
          <div className="control">
            <input
              name="quantity"
              className={cx("input", !!errors.quantity && "is-danger")}
              type="number"
              placeholder="Product Quantity"
              value={values.quantity}
              onChange={handleChange}
            />
          </div>
          {errors.quantity && (
            <p className="help is-danger">{errors.quantity}</p>
          )}
        </div>
      </div>
    </div>
    <div className="columns">
      <div className="column is-6">
        <div className="field">
          <div className="control">
             <Label for="basicpill-address-input1">
             Description of the Products
             </Label>
            <CKEditor
              editor={ClassicEditor}
              data={values.description}
              onReady={editor => {
                console.log('Editor is ready to use!', editor);
              }}
              onChange={(event, editor) => { 
                  const data = editor.getData();
                  values.description = data;
              }}
            />
          </div>
          {/* {errors.description && (
            <p className="help is-danger">{errors.description}</p>
          )} */}
        </div>
      </div>
      <div className="column is-6">
        <div className="field">
          <div className="control">
             <Label for="basicpill-address-input1">
             Specifications of the Products
             </Label>
                <CKEditor
                  editor={ClassicEditor}
                  data={values.specifications}
                  onReady={editor => {
                    console.log('Editor is ready to use!', editor);
                  }}
                  onChange={(event, editor) => {      
                    values.specifications =  editor.getData();
                  }}
                />
          </div>
          {/* {errors.specifications && (
            <p className="help is-danger">{errors.specifications}</p>
          )} */}
        </div>
      </div>
    </div>
   
  </>
);

export default ProductDetailsForm;
