import React, { useEffect } from "react"
import { Spinner } from "reactstrap";

const Spinners = ({ setLoading }) => {

    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 1000)
    }, [setLoading]);
    return (
        <React.Fragment>
            <Spinner 
            color="primary"
            className='position-absolute top-50 start-50'
            style={{
                height: '3rem',
                width: '3rem'
              }}
              type="grow"
            />
        </React.Fragment>
    )
}

export default Spinners;