import React from 'react';
import * as moment from "moment";
import { Badge } from 'reactstrap';


const formateDate = (date, format) => {
    const dateFormat = format ? format : "DD MMM Y";
    const date1 = moment(new Date(date)).format(dateFormat);
    return date1;
};

const CreateadAt = (cell) => {
    return cell.value ? formateDate(cell.value) : '';
};

const CreatedBy = (cell) => {
    return cell.value ? cell.value.first_name + " " + cell.value.middle_name + " " + cell.value.last_name : '';
};


const Status = (cell) => {
    return cell.value ? (<div><span className="badge rounded-pill bg-success float-center">Active</span></div>):(<div>
        <span className="badge rounded-pill bg-danger float-center">Inactive</span>
    </div>);
};

const Image = (cell) => {
    return cell.value ? (
      <div>
        <img height="50" src={cell.value} alt="image" />
      </div>
    ) : (
      <>
        <span className="badge rounded-pill bg-info">Not Uploaded</span>
      </>
    )
};


const TruncatedText = ({ text, maxLength }) => {
  if (text.length <= maxLength) {
    return <span>{text}</span>
  }

  const truncatedText = text.substring(0, maxLength) + "..."

  return <span title={text}>{truncatedText}</span>
}


const LongText = cell => {
  return cell.value ? (
    <>
      <TruncatedText text={cell.value } maxLength={20} />
    </>
  ) : (
    ""
  )
}

export {
    CreatedBy,
    CreateadAt,
    Image,
    Status,
    LongText
};