import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_BID,
  GET_BID_DETAILS,
} from "./actionTypes"

import {
  getBidDataFail,
  getBidDataSuccess,
  getBidDetailsDataSuccess,
  getBidDetailsDataFail,
} from "./actions"

import { toast } from "react-toastify"

// Include Both Helper File with needed methods
import {
  getBidListApi,
  getBidDetailsApi,
} from "helpers/backend_helper"

// all bids 
function* fetchBid() {
  try {
    const response = yield call(getBidListApi)
    yield put(getBidDataSuccess(response))
  } catch (error) {
    yield put(getBidDataFail(error))
  }
}

// fetch bids Details
function* fetchBidDetails({ payload: data }) {
  try {
    const response = yield call(getBidDetailsApi, data)
    yield put(getBidDetailsDataSuccess(response))
  } catch (error) {
    yield put(getBidDetailsDataFail(error))
  }
}

function* BidSaga() {
  yield takeEvery(GET_BID, fetchBid)
  yield takeEvery(GET_BID_DETAILS, fetchBidDetails)
}

export default BidSaga
