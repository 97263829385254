//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register";

//LOGIN
export const POST_LOGIN = "/auth/login";
export const REFRESH_PAGE = "/auth/refresh";
export const POST_PASSWORD_FORGET = "/auth/forgot/password/email";
export const POST_PASSWORD_FORGET_TOKEN = "/auth/forgot/password/token";
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login";
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd";
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd";
export const SOCIAL_LOGIN = "/social-login";

// WEKA DAU
// WEKA DAU
// WEKA DAU

            // SETTINGS MANAGEMENT
 // CATEGORY
export const GET_CATEGORY = "/setting/view/category";
export const ADD_CATEGORY = "/setting/category/create";
export const EDIT_CATEGORY = "/setting/category/edit"

// SUBCATEGORY
export const ADD_SUBCATEGORY = "/setting/create/subcategory";
export const GET_SUBCATEGORY = "/setting/view/subcategory";


// GROUP
export const ADD_GROUP = "/auth/create-group";
export const GET_GROUP = "/auth/all/group";
export const ASSIGN_PERMISSION_GROUP = "/auth/assign-permission/group";

// BANNER
export const ADD_BANNER = "/setting/admin/banner";
export const GET_BANNER = "/setting/admin/banner";
export const UPDATE_BANNER = "/setting/admin/edit/banner"
export const ACTIVATE_BANNER = "/setting/activate/banner";

// PAYMENT OPTION
export const ADD_PAYMENT_OPTION = "/setting/payment-method/create";
export const GET_PAYMENT_OPTION = "/setting/payment-method/all";
export const UPDATE_PAYMENT_OPTION = "/setting/payment-method/edit"
export const ACTIVATE_PAYMENT_OPTION = "/setting/payment-method/activate";

// SETTINGS MANAGEMENT

           // STAFF MANAGEMENT
// STAFF
export const ADD_STAFF = "/auth/register";
export const GET_STAFF = "/auth/all/user";

// NEWS
export const ADD_NEWS = "/setting/create/news"
export const GET_NEWS = "/setting/list/news"
export const ACTIVATE_NEWS = "/setting/activate/news"

// BIDDING MANAGEMENT
export const GET_BIDS = "/bid/admin/list";
export const GET_BIDS_DETAILS = "/bid/details";

// BIDDING AUCTION MANAGEMENT
export const GET_AUCTION = "/bid/list/auction-admin"
export const GET_AUCTION_DETAILS = "/bid/details/auction-admin"


// CUSTOMER BIDDING AUCTION MANAGEMENT
export const GET_CUSTOMER_AUCTION = "/bid/list/auction-customer"
export const GET_CUSTOMER_AUCTION_DETAILS = "/bid/details/auction-admin"

// PRODUCT DETAILS
export const ADD_PRODUCT = "/product/create";
export const GET_PRODUCT = "/product/all";
export const GET_APPROVED_PRODUCT = "/product/approved";
export const GET_REJECTED_PRODUCT = "/product/rejected";
export const GET_REQUEST_PRODUCT = "/product/request";
export const APPROVE_PRODUCT = "/product/activate";
export const FEATURED_PRODUCT = "/product/featured";
export const REJECTED_PRODUCT = "/product/reject";
export const GET_PRODUCT_DETAILS = "/product/details";

/**CUSTOMER PRODUCT LIST**/ 
export const CUSTOMER_PRODUCT_DETAILS = "/auth/customer/all-customer";
export const CUSTOMER_PRODUCT_API = "/product/customer";
export const CUSTOMER_APPROVED_PRODUCT_API = "/product/customer/list";
export const ADD_BID = "/bid/create";


/**CUSTOMER PRODUCT LIST**/ 
export const CUSTOMER_BID_DETAILS = "/bid/details";
export const CUSTOMER_BID_API = "/bid/customer/list";

/**CUSTOMER LIST**/ 
export const GET_CUSTOMER = "/auth/customer/all-customer";

// REGION
export const ADD_REGION = "/setting/upload/region";
export const GET_REGION = "/setting/list/region";


// DISTRICT
export const ADD_DISTRICT = "/setting/upload/district";
export const GET_DISTRICT = "/setting/list/district";


// WARD
export const ADD_WARD = "/setting/upload/ward";
export const GET_WARD = "/setting/list/ward";

// STREET
export const ADD_STREET = "/setting/upload/street";
export const GET_STREET = "/setting/list/street";

// PERMISSION
export const ADD_PERMISSION = "/auth/create-permission";
export const GET_PERMISSION = "/auth/all/permission";
           // STAFF MANAGEMENT

// WEKA DAU
// WEKA DAU
// WEKA DAU

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile";
export const POST_EDIT_PROFILE = "/post-fake-profile";

//PRODUCTS
export const GET_PRODUCTS = "/products";
export const GET_PRODUCTS_DETAIL = "/product";

//Mails
export const GET_MAILS_LIST = "/mailslists";
export const SELECT_FOLDER = "/folders";
export const GET_SELECTED_MAILS = "/selectedmails";
export const SET_FOLDER_SELECTED_MAILS = "/setfolderonmail";
export const UPDATE_MAIL = "/update/mail";

//CALENDER
export const GET_EVENTS = "/events";
export const ADD_NEW_EVENT = "/add/event";
export const UPDATE_EVENT = "/update/event";
export const DELETE_EVENT = "/delete/event";
export const GET_CATEGORIES = "/categories";

//CHATS
export const GET_CHATS = "/chats";
export const GET_GROUPS = "/groups";
export const GET_CONTACTS = "/contacts";
export const GET_MESSAGES = "/messages";
export const ADD_MESSAGE = "/add/messages";
export const DELETE_MESSAGE = "/delete/message";

//ORDERS
export const GET_ORDERS = "/orders";
export const ADD_NEW_ORDER = "/add/order";
export const UPDATE_ORDER = "/update/order";
export const DELETE_ORDER = "/delete/order";

//CART DATA
export const GET_CART_DATA = "/cart";

//CUSTOMERS
export const GET_CUSTOMERS = "/customers";
export const ADD_NEW_CUSTOMER = "/add/customer";
export const UPDATE_CUSTOMER = "/update/customer";
export const DELETE_CUSTOMER = "/delete/customer";

//SHOPS
export const GET_SHOPS = "/shops";

//CRYPTO
export const GET_WALLET = "/wallet";
export const GET_CRYPTO_ORDERS = "/crypto/orders";
export const GET_CRYPTO_PRODUCTS = "/crypto-products";

//INVOICES
export const GET_INVOICES = "/invoices";
export const GET_INVOICE_DETAIL = "/invoice";

// JOBS
export const GET_JOB_LIST = "/jobs";
export const ADD_NEW_JOB_LIST = "/add/job";
export const UPDATE_JOB_LIST = "/update/job";
export const DELETE_JOB_LIST = "/delete/job";

//Apply Jobs
export const GET_APPLY_JOB = "/jobApply";
export const DELETE_APPLY_JOB = "add/applyjob";

//PROJECTS
export const GET_PROJECTS = "/projects";
export const GET_PROJECT_DETAIL = "/project";
export const ADD_NEW_PROJECT = "/add/project";
export const UPDATE_PROJECT = "/update/project";
export const DELETE_PROJECT = "/delete/project";

//TASKS
export const GET_TASKS = "/tasks";
export const DELETE_KANBAN = "/delete/tasks"
export const ADD_CARD_DATA = "/add/tasks"
export const UPDATE_CARD_DATA = "/update/tasks"

//CONTACTS
export const GET_USERS = "/users";
export const GET_USER_PROFILE = "/user";
export const ADD_NEW_USER = "/add/user";
export const UPDATE_USER = "/update/user";
export const DELETE_USER = "/delete/user";

//Blog
export const GET_VISITOR_DATA = "/visitor-data";

//dashboard charts data
export const GET_WEEKLY_DATA = "/weekly-data";
export const GET_YEARLY_DATA = "/yearly-data";
export const GET_MONTHLY_DATA = "/monthly-data";

export const TOP_SELLING_DATA = "/top-selling-data";

//dashboard crypto
export const GET_WALLET_DATA = "/wallet-balance-data";

//dashboard jobs
export const GET_STATISTICS_DATA = "/Statistics-data";

export const GET_EARNING_DATA = "/earning-charts-data";

export const GET_PRODUCT_COMMENTS = "/comments-product";

export const ON_LIKNE_COMMENT = "/comments-product-action";

export const ON_ADD_REPLY = "/comments-product-add-reply";

export const ON_ADD_COMMENT = "/comments-product-add-comment";