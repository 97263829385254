import {
  GET_CUSTOMER_BID,
  GET_CUSTOMER_BID_DETAILS,
  GET_CUSTOMER_BID_SUCCESS,
  GET_CUSTOMER_BID_DETAILS_SUCCESS,
  GET_CUSTOMER_BID_FAIL,
  GET_CUSTOMER_BID_DETAILS_FAIL,
  ADD_BID_DATA_SUCCESS,
  ADD_BID_DATA_FAIL
} from "./actionTypes"

const INIT_STATE = {
  data: [],
  error: {},
  loading : true
}

const bid = (state = INIT_STATE, action) => {
  switch (action.type) {
    
    case GET_CUSTOMER_BID:
      return {
        ...state,
        data: action.payload,
        loading : true
      }
      
      case GET_CUSTOMER_BID_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading : true
      }

    case GET_CUSTOMER_BID_FAIL:
      return {
        ...state,
        error: action.payload,
      }
      
      case GET_CUSTOMER_BID_DETAILS:
      return {
        ...state,
        data: action.payload,
        loading : true
      }

       case GET_CUSTOMER_BID_DETAILS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading : true
      }

    case GET_CUSTOMER_BID_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

      case ADD_BID_DATA_SUCCESS:
      return {
        ...state,
        data: state,
      }

    case ADD_BID_DATA_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}
export default bid
