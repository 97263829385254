import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN,REFRESH_PAGE } from "./actionTypes";
import { apiError, loginSuccess, logoutUserSuccess } from "./actions";

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";
import {
  postFakeLogin,
  postJwtLogin,
  postSocialLogin,
  getRefreshPage
} from "../../../helpers/backend_helper";
// navigate to login


const fireBaseBackend = getFirebaseBackend();

function* loginUser({ payload: { user, history } }) {
  localStorage.setItem("authUser", JSON.stringify({"email":"info@gmail.com","role":"Customer","uid":'test',"username":"customer","access_token":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NSwiZXhwIjoxNzE5Njc3NDc0LCJpYXQiOjE3MTk2NzM4NzR9.pQits_DtVcaPv9J7ivUrVqvOk4LxNO89vpnnXNOcNNU"}));
  localStorage.setItem("access_token", 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NSwiZXhwIjoxNzE5Njc3NDc0LCJpYXQiOjE3MTk2NzM4NzR9.pQits_DtVcaPv9J7ivUrVqvOk4LxNO89vpnnXNO');
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(
        fireBaseBackend.loginUser,
        user.email,
        user.password
      );
      yield put(loginSuccess(response));
    } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const response = yield call(postJwtLogin, {
        email: user.email,
        password: user.password,
      });
      // localStorage.setItem("authUser", JSON.stringify(response));
      // yield put(loginSuccess(response));
    } else if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
      const response = yield call(postJwtLogin, {
        email: user.email,
        password: user.password,
      });
      localStorage.setItem("authUser", JSON.stringify(response.data));
      localStorage.setItem("access_token", response.data.access_token);
      document.cookie = "name=value";
      yield put(loginSuccess(response.data));
      if(response.data.role === 'Admin' ){
          // Dashboard for Admin
          history('/dashboard');
      }
      if(response.data.role === 'Customer' ){
          // Dashboard for customer
          history('/dashboard-saas');
      }
    }
  } catch (error) {
    yield put(apiError(error.response.data));
  }
}

// fetch refresh page
function* fetchRefreshPage({ payload: {navigate} }) {
  try {
    const response = yield call(getRefreshPage)
    yield put(loginSuccess(response.data))
  } catch (error) {
    // console.log("Failed to redirect to refresh page")
    navigate('/login');
    // localStorage.removeItem("authUser");
    // localStorage.removeItem("access_token");
    yield put(apiError("Session Expired Pleasea login again..."))
    // yield put(apiError(error.response.data))
  }
}

// logout user ....
function* logoutUser({ payload: { history } }) {
  try {
    // localStorage.removeItem("authUser");
    // localStorage.removeItem("access_token");
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.logout);
      yield put(logoutUserSuccess(response));
    }
    history('/login');
  } catch (error) {
    yield put(apiError(error));
  }
}
// third party
function* socialLogin({ payload: { type, history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const fireBaseBackend = getFirebaseBackend();
      const response = yield call(fireBaseBackend.socialLoginUser, type);
      if (response) {
        history("/dashboard");
      } else {
        history("/login");
      }
      localStorage.setItem("authUser", JSON.stringify(response));
      yield put(loginSuccess(response));
    }
    if(response)
    history("/dashboard");
  } catch (error) {
    console.log("error",error)
    yield put(apiError(error));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeLatest(SOCIAL_LOGIN, socialLogin);
  yield takeEvery(LOGOUT_USER, logoutUser);
  yield takeEvery(REFRESH_PAGE, fetchRefreshPage);
}

export default authSaga;
