import cx from "classnames";
import React, { useState,useEffect } from "react";
import { createSelector } from "reselect";
// redux
import { useSelector, useDispatch } from "react-redux";
// category
import { getCategory as onGetCategory,
	getSubCategory as onGetSubCategory,
} from "store/setting/actions";

const CategoryForm = ({ errors, values, handleChange }) => {
  const dispatch = useDispatch({});
	// set state for the categories
	const [categories, setCategories] = useState([])
	const [subcategories, setSubCategories] = useState([]);

// fetch category data
useEffect(() => {
	dispatch(onGetCategory())
  }, [dispatch])
 // fetch data from the category
 const selectCategoryState = state => state.Settings
  
 const CategoryProperties = createSelector(selectCategoryState, Category => ({
   CategoryData:Category.data,
   loading: Category.loading
 }))

 const { CategoryData } = useSelector(CategoryProperties)

 useEffect(() => {
   setCategories(CategoryData ? CategoryData.data :[])
 }, [CategoryData])

//  console.log("categories");
//  console.log(category);
//  console.log("categories");
// sub categories
 useEffect(() => {
	if (values.category) {
	  let data = {
		"category_id":values.category
	  };
	  dispatch(onGetSubCategory(data))
	} 
  }, [values.category]);
 
  const selectSubCategoryState = state => state.Settings.subcategory
  
  const SubCategoryProperties = createSelector(selectSubCategoryState, subcategory => ({
    SubCategoryData:subcategory,
  }))

  const {SubCategoryData,loading } = useSelector(SubCategoryProperties)

useEffect(() => {
  setSubCategories(SubCategoryData ? SubCategoryData.data ? SubCategoryData.data.category:[]:[]);
}, [SubCategoryData])

  return (
  <>
    <div className="columns">
      <div className="column is-6">
      <div className="field">
          <label className="label">Product Category</label>
          <div className="control">
              <select                                  
                name="category"
                value={values.category}
                onChange={handleChange}
                className={cx("input", !!errors.category && "is-danger")}
                >
                   <option value="">Select Category</option>
                    {categories.map(categories =>(
                    <option  key={categories.id} value={categories.id}>
                     {categories.name}
                    </option>
                    ))}          
                </select>
          </div>
          {errors.category && (
            <p className="help is-danger">{errors.category}</p>
          )}
      </div>
      </div>
      <div className="column is-6">
      <div className="field">
          <label className="label">Product Sub Category</label>
          <div className="control">
              <select                                  
                name="subcategory"
                value={values.subcategory}
                onChange={handleChange}
                className={cx("input", !!errors.subcategory && "is-danger")}
                >
                   <option value="">Select SubCategory</option>
                    {subcategories.map(subcategories =>(
                    <option  key={subcategories.id} value={subcategories.id}>
                     {subcategories.name}
                    </option>
                    ))}          
                </select>
          </div>
          {errors.subcategories && (
            <p className="help is-danger">{errors.subcategories}</p>
          )}
        </div>
      </div>
    </div>
  </>
)};

export default CategoryForm;
