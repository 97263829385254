import {
  GET_NEWS,
  ADD_NEWS_DATA_SUCCESS,
  ADD_NEWS_DATA_FAIL,
  GET_NEWS_SUCCESS,
  GET_NEWS_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  data: [],
  error: {},
  loading : true
}

const news = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_NEWS:
      return {
        ...state,
        data: action.payload,
        loading : true
      }

      case GET_NEWS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading : true
      }

    case GET_NEWS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_NEWS_DATA_SUCCESS:
      return {
        ...state,
        data: state
      }

    case ADD_NEWS_DATA_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default news
