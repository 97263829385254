import {
  GET_CUSTOMER_PRODUCT,
  GET_CUSTOMER_PRODUCT_SUCCESS,
  GET_CUSTOMER_PRODUCT_DETAILS_SUCCESS,
  GET_CUSTOMER_PRODUCT_DETAILS_FAIL,
  GET_CUSTOMER_PRODUCT_FAIL,
  GET_CUSTOMER_PRODUCT_DETAILS,
  ADD_PRODUCT_DATA,
  ADD_PRODUCT_DATA_SUCCESS,
  ADD_PRODUCT_DATA_FAIL,
  GET_CUSTOMER_APPROVED_PRODUCT
} from "./actionTypes"

/**ADD PRODUCT**/  
export const addProduct = data => ({
  type: ADD_PRODUCT_DATA,
  payload: data,
})

// CUSTOMER PRODUCT
export const getCustomerProduct = () => ({
  type: GET_CUSTOMER_PRODUCT,
})

// CUSTOMER APPROVED PRODUCT
export const getCustomerApprovedProduct = () => ({
  type: GET_CUSTOMER_APPROVED_PRODUCT,
})

// CUSTOMER PRODUCT SUCCESSFUL
export const getCustomerProductDataSuccess = data => ({
  type: GET_CUSTOMER_PRODUCT_SUCCESS,
  payload: data,
})

export const getCustomerProductDataFail = error => ({
  type: GET_CUSTOMER_PRODUCT_FAIL,
  payload: error,
})
// CUSTOMER  PRODUCT

/*CUSTOMER  PRODUCT DETAILS*/ 
export const getCustomerProductDetails= data => ({
  type: GET_CUSTOMER_PRODUCT_DETAILS,
  payload: data,
})

export const getCustomerProductDetailsDataSuccess = data => ({
  type: GET_CUSTOMER_PRODUCT_DETAILS_SUCCESS,
  payload: data,
})

export const getCustomerProductDetailsDataFail = error => ({
  type: GET_CUSTOMER_PRODUCT_DETAILS_FAIL,
  payload: error,
})

export const addProductDataSuccess = error => ({
  type: ADD_PRODUCT_DATA_SUCCESS,
  payload: error,
})

export const addProductDataFail = error => ({
  type: ADD_PRODUCT_DATA_FAIL,
  payload: error,
})

/*PRODUCT DETAILS*/ 