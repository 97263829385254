import axios from "axios";
import accessToken from "./jwt-token-access/accessToken";

// console.log("token");
// console.log(JSON.parse(localStorage.getItem("authUser")).access_token);
// console.log("token");

//apply base url for axios
const API_URL = process.env.REACT_APP_API_URL;
// window.$host = process.env.REACT_APP_API_URL;

const axiosApi = axios.create({
  baseURL: API_URL,
});

//pass new generated access token here
if (localStorage.getItem("access_token")){
const token = localStorage.getItem("access_token") !== null ? "Bearer " + localStorage.getItem("access_token"):"Bearer " + JSON.parse(localStorage.getItem("authUser")).access_token;
axiosApi.defaults.headers.common["Authorization"] = token;
}else{
  const token = JSON.parse(localStorage.getItem("authUser"))
    ? "Bearer " + JSON.parse(localStorage.getItem("authUser")).access_token
    : ""
  axiosApi.defaults.headers.common["Authorization"] = token
}

// axiosApi.defaults.withCredentials = true;

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

export async function get(url, config = {}) {
  return await axiosApi
    .get(url, { ...config })
    .then((response) => response.data);
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then((response) => response.data);
}

export async function postFormData(
  url,
  formData,
  config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  }
) {
  return axiosApi
    .post(url, formData, { ...config })
    .then(response => response.data)
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then((response) => response.data);
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then((response) => response.data);
}
