import React, { useState,useMemo,useEffect,useRef } from "react";
import { createSelector } from "reselect"
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  UncontrolledTooltip,
  Button,
  Modal,
  ModalBody,
  ModalHeader
} from "reactstrap";

import TableContainer from '../../../components/Common/TableContainer';
import { ToastContainer } from "react-toastify";
import Spinners from "components/Common/Spinner";

import { Link } from "react-router-dom";

import {
  getCustomerBid as onGetCustomerBid
} from "store/Bidding/CustomerBid/actions"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
// redux
import { useSelector, useDispatch } from "react-redux"

import {  CreateadAt,Image,PriceFormat,Status } from "../Col";

// Form Editor
import FormStepper from './FormWizard/Form';


const CustomerBid = () => {
//meta title
document.title="WekaDau | Customer Products Bids";
//  dispatch
const dispatch = useDispatch({});
const [customer, setProduct] = useState();
const [isEdit, setIsEdit] = useState(false);
// /  modal
const [modal, setModal] = useState(false)
// bids 
const [bids, setBids] = useState([])


useEffect(() => {
  dispatch(onGetCustomerBid())
}, [dispatch])


  const selectProductState = state => state.CustomerBid

  
  const BidProperties = createSelector(selectProductState, bid => ({
    bidData:bid,
    loading: bid
  }))

  const { bidData, loading } = useSelector(BidProperties ? BidProperties :[])
  const [isLoading, setLoading] = useState(loading)

useEffect(() => {
  setBids(bidData ? bidData.data:[])
}, [bidData])
  
  const toggle = () => {
    if (modal) {
      setProduct()
      setIsEdit(false);
      setModal(false)
    } else {
      setModal(true)
    }
  }

  const closeModal = () => {
    setModal(false);
    };

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

/**data for the table**/ 
  const columns = useMemo(
    () => [
      {
        Header: 'Bid#',
        accessor: 'bidding_number',
    },
       {
            Header: 'Product Image',
            accessor: 'product.product_image',
            filterable: true,
            Cell: cellProps => {
              return (
                <>
                  {" "}
                  <Image {...cellProps} />{" "}
                </>
              );
            },
      },
        
        {
            Header: 'Product Name',
            accessor: 'product.name',
        },
        {
            Header: 'Starting Price',
            accessor: 'bidding_price.starting_price',
            Cell: cellProps => {
              return (
                <>
                  {" "}
                  <PriceFormat {...cellProps} />{" "}
                </>
              );
            },
        },
        {
            Header: 'Ending Price',
            accessor: 'bidding_price.ending_price',
            Cell: cellProps => {
              return (
                <>
                  {" "}
                  <PriceFormat {...cellProps} />{" "}
                </>
              );
            },
        },
        {
            Header: 'Starting Date',
            accessor: 'starting_date',
            filterable: true,
            Cell: cellProps => {
              return (
                <>
                  {" "}
                  <CreateadAt {...cellProps} />{" "}
                </>
              );
            },
        },
        {
            Header: 'Ending Date',
            accessor: 'ending_date',
            filterable: true,
            Cell: cellProps => {
              return (
                <>
                  {" "}
                  <CreateadAt {...cellProps} />{" "}
                </>
              );
            },
        },
        {
            Header: 'Bid Status',
            accessor: 'is_ative',
            filterable: true,
            Cell: cellProps => {
              return (
                <>
                  {" "}
                  <Status {...cellProps} />{" "}
                </>
              );
            },
      },
      //   {
      //       Header: 'created At',
      //       accessor: 'created_at',
      //       filterable: true,
      //       Cell: cellProps => {
      //         return (
      //           <>
      //             {" "}
      //             <CreateadAt {...cellProps} />{" "}
      //           </>
      //         );
      //       },
      // },
        {
          Header: "Action",
          Cell: cellProps => {
            return (
              <div className="d-flex gap-3">
                
              <Link
                  to={"/bid/details/" + cellProps.row.original.id}
                  className="text-success "
                >
                  <Button color="success" outline>
                    View
                  <i className="mdi mdi-eye font-size-18 m-1" id="viewtooltip" />
                  <UncontrolledTooltip placement="top" target="viewtooltip">
                    Preview Bid
                  </UncontrolledTooltip>
                   </Button>
                </Link>
              </div>
            );
          },
        },
    ],
    []
);

// model controller
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Product Bid" breadcrumbItem="Product Bid List" />
    <div>
          {
          isLoading ? <Spinners setLoading={setLoading} />
            :
          <Row>
            <Col xl="4" sm="4" md="4">
                <Card>
                  <CardBody>
                    <div className="text-right d-grid">
                      <Link
                        to="#"
                        className="btn btn-primary waves-effect waves-light addtask-btn"
                        data-bs-toggle="modal"
                        data-bs-target=".bs-example-modal-lg"
                        data-id="#upcoming-task"
                        onClick={() => toggle(true)}
                      >
                        <i className="mdi mdi-plus me-1"></i> Add Bid Product
                      </Link>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            <Col xl="12">
            <Card>
                <CardBody>                  
                <div className="container-fluid">
                <TableContainer
                    columns={columns}
                    data={bids ? bids.data :[]}
                    isGlobalFilter={true}
                    isAddOptions={false}
                    customPageSize={10}
                    isPagination={true}
                    tableClass="align-middle table-nowrap table-check table"
                    theadClass="table-light"
                    paginationDiv="col-12"
                    pagination="justify-content-center pagination pagination-rounded"
                />
            </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          }
      <ToastContainer />
    </div>   
    </Container>

    {/* modal for creating the bids Bids*/}
     <Modal
        id="modalForm"
        isOpen={modal}
        toggle={toggle}
        centered={true}
        backdrop={true}
        size="xl"
      >
        <ModalHeader toggle={toggle}>
         <div className="d-flex justify-content-center">Add Product to a Bid</div> 
        </ModalHeader>
        <ModalBody>
        <Row>
            <Col lg="12">
              <Card>
                <CardBody>
               <FormStepper closeModal={closeModal}></FormStepper>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    {/* modal for creating the bids bids */}
    </div>
    </React.Fragment>
  );
};
export default CustomerBid;
