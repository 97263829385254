import React, { useState,useEffect } from "react";
import cx from "classnames";

import { createSelector } from "reselect";
// redux
import { useSelector, useDispatch } from "react-redux";

// regions
import { 
	getRegion as onGetRegion
} from "store/address/region/actions";

// district
import { 
	getDistrict as onGetDistrict
} from "store/address/district/actions";

// wards
import { 
	getWard as onGetWard
} from "store/address/ward/actions";

// street
import { 
	getStreet as onGetStreet
} from "store/address/street/actions";


const AddressDetails = ({ errors, values, handleChange }) =>{
  const dispatch = useDispatch({});
  const [regions, setRegion] = useState([])
  const [districts, setDistrict] = useState([])
  const [wards, setWard] = useState([])
  const [streets, setStreet] = useState([])


  /**fetch region data**/  
useEffect(() => {
	dispatch(onGetRegion())
  }, [dispatch])

 /**fetch data from the regions**/ 
 const selectRegionState = state => state.Region
  
 const RegionProperties = createSelector(selectRegionState, Region => ({
   RegionData:Region.data,
   loading: Region.loading
 }))

 const { RegionData } = useSelector(RegionProperties)

 useEffect(() => {
  setRegion(RegionData ? RegionData.data :[])
 }, [RegionData])

/**fetch data from districts**/  
useEffect(() => {
	if (values.region_id) {
	  let data = {
		"region_id":values.region_id
	  };
	  dispatch(onGetDistrict(data))
	} 
  }, [values.region_id]);
 
  const selectDistrictState = state => state.District
  
  const DistrictProperties = createSelector(selectDistrictState, district => ({
    DistrictData:district.data,
  }))

  const {DistrictData,loading } = useSelector(DistrictProperties)

useEffect(() => {
  setDistrict(DistrictData ? DistrictData.data ? DistrictData.data.districts :[]:[]);
}, [DistrictData])

/**fetch data from wards**/  
useEffect(() => {
	if (values.district_id) {
	  let data = {
		"district_id":values.district_id
	  };
	  dispatch(onGetWard(data))
	} 
  }, [values.district_id]);
 
  const selectWardState = state => state.Ward
  
  const WardProperties = createSelector(selectWardState, ward => ({
    WardData:ward.data,
  }))

  const {WardData } = useSelector(WardProperties)

useEffect(() => {
  setWard(WardData ? WardData.data ? WardData.data.wards :[]:[]);
}, [WardData])


/**fetch data from streets**/  
useEffect(() => {
	if (values.ward_id) {
	  let data = {
		"ward_id":values.ward_id
	  };
	  dispatch(onGetStreet(data))
	} 
  }, [values.ward_id]);
 
  const selectStreetState = state => state.Street
  
  const StreetProperties = createSelector(selectStreetState, street => ({
    StreetData:street.data,
  }))

  const {StreetData } = useSelector(StreetProperties)

useEffect(() => {
  setStreet(StreetData ? StreetData.data ? StreetData.data.streets:[] :[]);
}, [StreetData])

  return(
  <>
    <div className="columns">
    <div className="column is-6">
        <div className="field">
          <label className="label">Region</label>
          <div className="control">
              <select                                  
                name="region_id"
                value={values.region_id}
                onChange={handleChange}
                className={cx("input", !!errors.region_id && "is-danger")}
                >
                   <option value="">Select Region</option>
                    {regions.map(region =>(
                    <option  key={region.id} value={region.id}>
                     {region.name}
                    </option>
                    ))}          
                </select>
          </div>
          {errors.region_id && (
            <p className="help is-danger">{errors.region_id}</p>
          )}
        </div>
    </div>
    <div className="column is-6">
        <div className="field">
          <label className="label">District</label>
          <div className="control">
              <select                                  
                name="district_id"
                value={values.district_id}
                onChange={handleChange}
                className={cx("input", !!errors.district_id && "is-danger")}
                >
                   <option value="">Select District</option>
                    {districts.map(district =>(
                    <option  key={district.id} value={district.id}>
                     {district.name}
                    </option>
                    ))}          
                </select>
          </div>
          {errors.district_id && (
            <p className="help is-danger">{errors.district_id}</p>
          )}
        </div>
      </div>
      </div>
      <div className="columns">
    <div className="column is-6">
        <div className="field">
          <label className="label">Ward</label>
          <div className="control">
              <select                                  
                name="ward_id"
                value={values.ward_id}
                onChange={handleChange}
                className={cx("input", !!errors.ward_id && "is-danger")}
                >
                   <option value="">Select Ward</option>
                    {wards.map(ward =>(
                    <option  key={ward.id} value={ward.id}>
                     {ward.name}
                    </option>
                    ))}          
                </select>
          </div>
          {errors.ward_id && (
            <p className="help is-danger">{errors.ward_id}</p>
          )}
        </div>
      </div>
    <div className="column is-6">
        <div className="field">
          <label className="label">Street</label>
          <div className="control">
              <select                                  
                name="street_id"
                value={values.street_id}
                onChange={handleChange}
                className={cx("input", !!errors.street_id && "is-danger")}
                >
                   <option value="">Select Street</option>
                    {streets.map(street =>(
                    <option  key={street.id} value={street.id}>
                     {street.name}
                    </option>
                    ))}          
                </select>
          </div>
          {errors.street_id && (
            <p className="help is-danger">{errors.street_id}</p>
          )}
        </div>
      </div>
    </div>
    <div className="columns">
        <div className="column is-6">
           <div className="field">
          <label className="label">Physical Address</label>
          <div className="control">
          <input
              className={cx("input", !!errors.physical_address && "is-danger")}
              type="text"
              name="physical_address"
              value={values.physical_address}
              id="physical_address"
              onChange={handleChange}
            />
          </div>
          {errors.physical_address && (
            <p className="help is-danger">{errors.physical_address}</p>
          )}
        </div>
      </div>
      </div>
  </>
)};

export default AddressDetails;
