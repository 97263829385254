import {
  GET_STAFF,
  ADD_STAFF_DATA,
  ADD_STAFF_DATA_SUCCESS,
  ADD_STAFF_DATA_FAIL,
  GET_STAFF_SUCCESS,
  GET_STAFF_FAIL,
  UPDATE_STAFF_DATA
} from "./actionTypes"

// STAFF
export const getStaff = () => ({
  type: GET_STAFF,
})

export const addStaffData = data => ({
  type: ADD_STAFF_DATA,
  payload: data,
})
export const updateStaffData = data => ({
  type: UPDATE_STAFF_DATA,
  payload: data,
})


export const getStaffDataSuccess = data => ({
  type: GET_STAFF_SUCCESS,
  payload: data,
})


export const addStaffDataSuccess = payload_data => ({
  type: ADD_STAFF_DATA_SUCCESS,
  payload: payload_data.data,
})

export const addStaffDataFail = error => ({
  type: ADD_STAFF_DATA_FAIL,
  payload: error,
})

export const getStaffDataFail = error => ({
  type: GET_STAFF_FAIL,
  payload: error,
})
// STAFF