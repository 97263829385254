import {
  GET_BANNER,
  ADD_BANNER_DATA,
  ADD_BANNER_DATA_SUCCESS,
  ADD_BANNER_DATA_FAIL,
  GET_BANNER_SUCCESS,
  GET_BANNER_FAIL,
  UPDATE_BANNER_DATA,
  ACTIVATE_BANNER,
} from "./actionTypes"

// BANNER
export const getBanner = () => ({
  type: GET_BANNER,
})

export const addBannerData = data => ({
  type: ADD_BANNER_DATA,
  payload: data,
})


export const updateBannerData = data => ({
  type: UPDATE_BANNER_DATA,
  payload: data,
})

export const addPermissionBannerData = data => ({
  type: ADD_BANNER_PERMISSION_DATA,
  payload: data,
})


export const getBannerDataSuccess = data => ({
  type: GET_BANNER_SUCCESS,
  payload: data,
})


export const addBannerDataSuccess = data => ({
  type: ADD_BANNER_DATA_SUCCESS,
  payload: data,
})

export const addBannerDataFail = error => ({
  type: ADD_BANNER_DATA_FAIL,
  payload: error,
})

export const getBannerDataFail = error => ({
  type: GET_BANNER_FAIL,
  payload: error,
})

/*approve product*/ 
export const activateBanner = data => ({
  type: ACTIVATE_BANNER,
  payload: data,
})
/*approve product*/ 
// BANNER