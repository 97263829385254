import React, { useState,useMemo,useEffect,useRef } from "react";
import { createSelector } from "reselect";
import { useParams } from 'react-router-dom';
import Spinners from "components/Common/Spinner";
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  Label,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Container,
  FormFeedback,
  UncontrolledTooltip,
  Form,
} from "reactstrap";
import { ToastContainer } from "react-toastify";

import TableContainer from '../../../../components/Common/TableContainer';

import { Link } from "react-router-dom";

// saga district
import {
  addDistrictData as onAddDistrictData,
  updateDistrictData as onUpdateDistrictData,
  getDistrict as onGetDistrict
} from "store/address/district/actions"

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb";

// redux
import { useSelector, useDispatch } from "react-redux"

// created 
import {  CreateadAt,CreatedBy } from "./../../categorytCol";

import DeleteModal from "./../../DeleteModal";

const District = () => {
//meta title
document.title="WekaDau | District";
let { region_id } = useParams();
//  dispatch
const dispatch = useDispatch();

useEffect(() => {
  if (region_id) {
    const data = {
      "region_id":region_id
    };
    console.log(data)
    dispatch(onGetDistrict(data))
  } 
}, [dispatch]);

const [district, setDistrict] = useState();
const [isEdit, setIsEdit] = useState(false);
// delete modal
const [deleteModal, setDeleteModal] = useState(false);
const [districts, setDistricts] = useState([]);
const [districtname, setDistrictName] = useState();
  //  modal
const [modal, setModal] = useState(false)

  const columns = useMemo(
    () => [
        {
            Header: 'Region Name',
            accessor: 'name',
        },
        {
            Header: 'created At',
            accessor: 'created_at',
            filterable: true,
        Cell: cellProps => {
          return (
            <>
              {" "}
              <CreateadAt {...cellProps} />{" "}
            </>
          );
        },
        },
        {
          Header: "Action",
          Cell: cellProps => {
            return (
              <div className="d-flex gap-3">
                <Link
                  to="#"
                  className="text-info"
                  onClick={() => {
                    const districtData = cellProps.row.original;
                    handleClick(districtData);
                  }}
                >
                  <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    Edit
                  </UncontrolledTooltip>
                </Link>
                <Link
                 to={"/ward/list/" + cellProps.row.original.id}
                  className="text-success"
                  onClick={() => {
                    const districtData = cellProps.row.original;
                    console.log(districtData.id)
                    // handleClick(districtData);
                  }}
                >
                  <i className="mdi mdi-eye font-size-18" id="viewtooltip" />
                  <UncontrolledTooltip placement="top" target="viewtooltip">
                    View
                  </UncontrolledTooltip>
                </Link>
                {/* <Link
                  to="#"
                  className="text-danger"
                  onClick={() => {
                    const userData = cellProps.row.original;
                    onClickDelete(userData);
                  }}
                >
                  <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </Link> */}
              </div>
            );
          },
        },
    ],
    []
);

const onClickDelete = district => {
   setDistrict(district);
   setDeleteModal(true);
};

  const selectDistrictState = state => state.District
  
  const CategoryProperties = createSelector(selectDistrictState, district => ({
    DistrictData:district.data,
    loading: district.loading
  }))

  const {DistrictData,loading } = useSelector(CategoryProperties)
  const [isLoading, setLoading] = useState(loading)

useEffect(() => {
  setDistricts(DistrictData ? DistrictData.data ? DistrictData.data.districts:[]:[]);
  setDistrictName(DistrictData ? DistrictData.data ? DistrictData.data.name :'' :'')
}, [DistrictData])

const data =districts;

const handleClick = arg => {
  const district = arg;
  setDistrict({
    id: district.id,
    name: district.name
  });
  setIsEdit(true);
  toggle();
};
  
// handle delete district
const handleDeleteCategory = () => {
  if (district && district.id) {
    // dispatch(onDeleteUser(district.id));
  }
  onPaginationPageChange(1);
  setDeleteModal(false);
};

  const toggle = () => {
    if (modal) {
      setDistrict();
      setIsEdit(false);
      setModal(false)
    } else {
      setModal(true)
    }
  }

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };


  // Form validation 
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (district && district.name) || ''
    },

    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter  Name"),
    }),

    onSubmit: (values) => {
      if(isEdit){
        const edited_data={
           'name':values.name,
           'id':district.id,
           "region_id":region_id
         }
        dispatch(onUpdateDistrictData(edited_data));
       }else{
      let data = {
        "name":values.name,
        "region_id":region_id
      };
      dispatch(onAddDistrictData(data));
      }
      validation.resetForm()
      toggle();
    }
  });

  const regExp = /\b\d{5}\b/;
  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteCategory}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Districts" breadcrumbItem="District List" />
          <h4 className="font-size-14 mb-3">Region: { districtname ? districtname:"" } </h4>  <h5>has <b>{data.length}</b> district</h5>
          {
          isLoading ? <Spinners setLoading={setLoading} />
            :
          <Row>
            <Col xl="4">
              <Card>
                <CardBody>
                  <div className="text-right d-grid">
                                  <Link
                                    to="#"
                                    className="btn btn-primary waves-effect waves-light addtask-btn"
                                    data-bs-toggle="modal"
                                    data-bs-target=".bs-example-modal-lg"
                                    data-id="#upcoming-task"
                                    onClick={() => toggle(true)}
                                  >
                                    <i className="mdi mdi-plus me-1"></i> Add District
                                  </Link>
                                </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl="8">
            <Card>
                <CardBody>
                <div className="container-fluid">
                <TableContainer
                    columns={columns}
                    data={data}
                    isGlobalFilter={true}
                    isAddOptions={false}
                    customPageSize={10}
                    isPagination={true}
                    tableClass="align-middle table-nowrap table-check table"
                    theadClass="table-light"
                    paginationDiv="col-12"
                    pagination="justify-content-center pagination pagination-rounded"
                />
            </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
         }
        </Container>
      </div>

      <Modal id="modalForm" isOpen={modal} toggle={toggle} centered={true} size="sm">
        <ModalHeader toggle={toggle}>
          {!!isEdit ? "Edit District" : "Add District"}
        </ModalHeader>
        <ModalBody>
        <Form className="needs-validation"
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <Row>
                      <Col md="12" sm="12" lg="12">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom01">District name</Label>
                          <Input
                            name="name"
                            placeholder="district name"
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.name || ""}
                            invalid={
                              validation.touched.name && validation.errors.name ? true : false
                            }
                          />
                          {validation.touched.name && validation.errors.name ? (
                            <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Button color="primary" type="submit">
                      Submit
                    </Button>
                  </Form>
        </ModalBody>
      </Modal>
      <ToastContainer />
    </React.Fragment>
  );
};
export default District;
