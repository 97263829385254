/* REGION */
export const GET_REGION = "GET_REGION"
export const GET_REGION_SUCCESS = "GET_REGION_SUCCESS"
export const GET_REGION_FAIL = "GET_REGION_FAIL"

// ADD REGION
export const UPDATE_REGION_DATA = "UPDATE_REGION_DATA"
export const ADD_REGION_DATA = "ADD_REGION_DATA"
export const ADD_REGION_DATA_SUCCESS = "ADD_REGION_DATA_SUCCESS"
export const ADD_REGION_DATA_FAIL = "ADD_REGION_DATA_FAIL"

