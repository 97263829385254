import cx from "classnames";
import React, { useState,useEffect } from "react";
import { createSelector } from "reselect";
// redux
import { useSelector, useDispatch } from "react-redux";

// products
import { 
	getCustomerApprovedProduct as onGetCustomerApprovedProduct
} from "store/product/CustomerProduct/actions";

const BidDetailsForm = ({ errors, values, handleChange }) => {
  const dispatch = useDispatch({});
  const [products, setProduct] = useState([])

  //  fetch product data
useEffect(() => {
	dispatch(onGetCustomerApprovedProduct())
  }, [dispatch])

 // fetch data from the products
 const selectProductState = state => state.CustomerProduct
  
 const ProductProperties = createSelector(selectProductState, Product => ({
   ProductData:Product.data,
   loading: Product.loading
 }))

 const { ProductData } = useSelector(ProductProperties)

 useEffect(() => {
  setProduct(ProductData ? ProductData.data :[])
 }, [ProductData])


 const type_of_bid_datas = [
  {
    id: "Fast_lane",
    name: 'Fast Lane'
  },
  {
    id: 'Market',
    name: 'Market'
  },
  {
    id: 'Normal_bid',
    name: 'Normal Bid'
  },
];
 return ( <>
   
    <div className="columns">
      <div className="column is-6">
        <div className="field">
          <label className="label">Product Name</label>
          <div className="control">
              <select                                  
                name="product_id"
                value={values.product_id}
                onChange={handleChange}
                className={cx("input", !!errors.product_id && "is-danger")}
                >
                   <option value="">Select Product</option>
                    {products.map(product =>(
                    <option  key={product.id} value={product.id}>
                     {product.name}
                    </option>
                    ))}          
                </select>
          </div>
          {errors.product_id && (
            <p className="help is-danger">{errors.product_id}</p>
          )}
        </div>
      </div>
      <div className="column is-6">
        <div className="field">
          <label className="label">Type of bid</label>
          <div className="control">
              <select                                  
                name="type_of_bid"
                value={values.type_of_bid}
                onChange={handleChange}
                className={cx("input", !!errors.type_of_bid && "is-danger")}
                >
                   <option value="">Select Type of Bid</option>
                    {type_of_bid_datas.map(type_of_bid_data =>(
                    <option  key={type_of_bid_data.id} value={type_of_bid_data.id}>
                     {type_of_bid_data.name}
                    </option>
                    ))}          
                </select>
          </div>
          {errors.type_of_bid && (
            <p className="help is-danger">{errors.type_of_bid}</p>
          )}
        </div>
      </div>
    </div>
    <div className="columns" >
      <div className="column is-6">
        <div className="field">
          <label className="label">Starting Date</label>
          <div className="control">
          <input
              className={cx("input", !!errors.starting_date && "is-danger")}
              type="datetime-local"
              name="starting_date"
              value={values.starting_date }
              id="example-datetime-local-input"
              onChange={handleChange}
            />
          </div>
          {errors.starting_date && (
            <p className="help is-danger">{errors.starting_date}</p>
          )}
        </div>
      </div>
    
      <div className="column is-6" >
      {values.type_of_bid == 'Fast_lane' ? <></> : <><div className="field">
          <label className="label">Ending Date</label>
          <div className="control">
          <input
              className={cx("input", !!errors.ending_date && "is-danger")}
              type="datetime-local"
              name="ending_date"
              value={values.ending_date}
              id="example-datetime-local-input"
              onChange={handleChange}
            />
          </div>
          {errors.ending_date && (
            <p className="help is-danger">{errors.ending_date}</p>
          )}
        </div></>}
      
      </div>
    </div>
  </>
)};

export default BidDetailsForm;
