/* DISTRICT */
export const GET_DISTRICT = "GET_DISTRICT"
export const GET_DISTRICT_SUCCESS = "GET_DISTRICT_SUCCESS"
export const GET_DISTRICT_FAIL = "GET_DISTRICT_FAIL"
/*ADD DISTRICT*/
export const UPDATE_DISTRICT_DATA = "UPDATE_DISTRICT_DATA"
export const ADD_DISTRICT_DATA = "ADD_DISTRICT_DATA"
export const ADD_DISTRICT_DATA_SUCCESS = "ADD_DISTRICT_DATA_SUCCESS"
export const ADD_DISTRICT_DATA_FAIL = "ADD_DISTRICT_DATA_FAIL"

