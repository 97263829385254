import {
  GET_WARD,
  ADD_WARD_DATA_SUCCESS,
  ADD_WARD_DATA_FAIL,
  ADD_WARD_PERMISSION_DATA_SUCCESS,
  ADD_WARD_PERMISSION_DATA_FAIL,
  GET_WARD_SUCCESS,
  GET_WARD_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  data: [],
  error: {},
  loading : true
}

const wards = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_WARD:
      return {
        ...state,
        data: action.payload,
        loading : true
      }

      case GET_WARD_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading : true
      }

    case GET_WARD_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_WARD_DATA_SUCCESS:
      return {
        ...state,
        data: state
      }

    case ADD_WARD_DATA_FAIL:
      return {
        ...state,
        error: action.payload,
      }
   
    default:
      return state
  }
}

export default wards
