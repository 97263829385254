import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_WARD,
  ADD_WARD_DATA,
  UPDATE_WARD_DATA
} from "./actionTypes"


import {
  addWardDataSuccess,
  addWardDataFail,
  getWardDataFail,
  getWardDataSuccess,
} from "./actions"

import { toast } from "react-toastify"

// Include Both Helper File with needed methods
import {
  getWardList,
  addWardList,
  updateWardList
} from "helpers/backend_helper"

// group
function* fetchWard({ payload: data }) {
  try {
    const response = yield call(getWardList, data)
    yield put(getWardDataSuccess(response))
  } catch (error) {
    yield put(getWardDataFail(error))
  }
}

// add data 
function* onAddWardData({ payload: data }) {
  try {
    const response = yield call(addWardList, data)
    yield put(addWardDataSuccess(response))
    yield put({type:"GET_WARD"})
    toast.success("Ward Added Successfully", { autoClose: 5000 });
  } catch (error) {
    const message = error.response.data.name ? error.response.data.name[0] : (error.detail ? error.detail : "System error"); 
    yield put(addWardDataFail(message))
    toast.error(message, { autoClose: 5000 });
  }
}

// Update ward 
function* onUpdateWardData({ payload: data }) {
  try {
    const response = yield call(updateWardList, data)
    // yield put(addWardDataSuccess(response))
    yield put({type:"GET_WARD",payload:data})
    toast.success("Ward Updated Successfully", { autoClose: 5000 });
  } catch (error) {
    const message = error.response.data.name ? error.response.data.name[0] : (error.detail ? error.detail : "System error"); 
    yield put(addWardDataFail(message))
    toast.error(message, { autoClose: 5000 });
  }
}

function* WardSaga() {
  yield takeEvery(GET_WARD, fetchWard)
  yield takeEvery(ADD_WARD_DATA, onAddWardData)
  yield takeEvery(UPDATE_WARD_DATA, onUpdateWardData)
}

export default WardSaga
