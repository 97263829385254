import React, { useState,useMemo,useEffect,useRef } from "react";
import { createSelector } from "reselect"
import { useParams } from 'react-router-dom';
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Input,
  Container,
  CardHeader,
} from "reactstrap";
import { ToastContainer } from "react-toastify";
import {
  getPermission as onGetPermission,
} from "store/authetications/permission/actions"
import {
  addPermissionGroupData as onAddGroupData,
} from "store/authetications/group/actions"

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
// redux

// redux
import { useSelector, useDispatch } from "react-redux"

const AssignPermissionGroup = () => {
  const { group_id } = useParams();
//meta title
document.title="WekaDau | Assign Permission";

// set variable
const [permissions, setPermissions] = useState([])
const [checkedPermissions, setPermissionItems] = useState([]); // State to track checked items

//  dispatch
const dispatch = useDispatch();
useEffect(() => {
   dispatch(onGetPermission())
}, [dispatch])

// fetch data for the permission
const selectPermissionState = state => state.Permission
  
const PermissionProperties = createSelector(selectPermissionState, Permission => ({
  PermissionData:Permission.data,
  loading: Permission.loading
}))

const { PermissionData, loading } = useSelector(PermissionProperties)

useEffect(() => {
setPermissions(PermissionData ? PermissionData.data:[])
}, [PermissionData])

let PermissionChange = (event) => {
  const { name, checked } = event.target;
  if (checked) {
    // If the checkbox is checked, add its ID to the array
    setPermissionItems([...checkedPermissions, { id: name }]);
  } else {
    // If the checkbox is unchecked, remove its ID from the array
    const filteredItems = checkedPermissions.filter((item) => item.id !== name);
    setPermissionItems(filteredItems);
  }
};

const SubmitPermission = () =>  {
  let payload = {
    "group_id": group_id,
    "permissions":checkedPermissions
  };
  console.log(payload)
   dispatch(onAddGroupData(payload))
}

// console.log(checkedPermissions);

  const regExp = /\b\d{5}\b/;
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Assign Permission Group" breadcrumbItem="Assign Permission to a Group" />
         
          <Card>
            <CardHeader><h2>All Permissions</h2>  <h5>{permissions.length} total</h5><br/>
             <h5> {checkedPermissions.length >= 1 ? "You have selected "+checkedPermissions.length+" Permission":""}</h5>
            </CardHeader>
                <CardBody>
          <Row>
          {
                (permissions || []).map((permission, index) => (
              <Col key={index} xl="3" sm="3" md="3">
              <div className="mb-3">
                        <div className="form-check">
                          <Input
                            type="checkbox"
                            name={permission.id.toString()}
                            // checked={checkedPermissions.some((checkedPermission)=>checkedPermission.id === permission.id.toString())}
                            onClick={PermissionChange}
                            className="form-check-Input"
                            id={permission.id}
                          />
                          <Label
                            className="form-check-Label"
                            htmlFor={permission.id}
                          >
                            {permission.name}
                          </Label>
                        </div>
                      </div>
              </Col>
             ))
            }
            <div>
              {checkedPermissions.length >= 1 ? <Button color="primary" onClick={SubmitPermission} >
                Submit
              </Button> : <div></div>
              }
            
            </div>
          </Row>
          </CardBody>
          </Card>
          </Container>
          </div>
      <ToastContainer />
    </React.Fragment>
  );
};


export default AssignPermissionGroup;
