import axios from "axios";
import { del, get, post, put, postFormData, putFormData } from "./api_helper"
import * as url from "./url_helper";
import { PureComponent } from "react";

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

// Register Method
const postFakeRegister = data => {
  return axios
    .post(url.POST_FAKE_REGISTER, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data;
      throw response.data;
    })
    .catch(err => {
      let message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

// Login Method
const postFakeLogin = data => post(url.POST_LOGIN, data);

// postForgetPwd
const postFakeForgetPwd = data => post(url.POST_FAKE_PASSWORD_FORGET, data);

// Edit profile
const postJwtProfile = data => post(url.POST_EDIT_JWT_PROFILE, data);

const postFakeProfile = data => post(url.POST_EDIT_PROFILE, data);

// Register Method
const postJwtRegister = (url, data) => {
  return axios
    .post(url, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data;
      throw response.data;
    })
    .catch(err => {
      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

//  *******wekadau****
//  *******wekadau****
//  *******wekadau****

// get Categories
export const getCategories = () => get(url.GET_CATEGORIES);

// ***category***
export const getCategoryList = () => get(url.GET_CATEGORY);
export const addCategoryList = data => postFormData(url.ADD_CATEGORY, data)
export const updateCategoryList = data => postFormData(url.EDIT_CATEGORY, data)

// ***sub-categorycategory***
export const addSubCategoryList = data => post(url.ADD_SUBCATEGORY, data);
export const getSubCategoryList = data => post(url.GET_SUBCATEGORY, data);
export const updateSubCategoryList = data => put(url.ADD_SUBCATEGORY, data);


// ***group***
export const getGroupList = () => get(url.GET_GROUP);
export const addGroupList = data => post(url.ADD_GROUP, data);
export const updateGroupList = data => put(url.ADD_GROUP, data);
export const addPermissionGroupList = data => post(url.ASSIGN_PERMISSION_GROUP, data);


// ***banner***
export const getBannerList = () => get(url.GET_BANNER)
export const addBannerList = data => postFormData(url.ADD_BANNER, data)
export const updateBannerList = data => postFormData(url.UPDATE_BANNER, data)
export const activateBanner = data => post(url.ACTIVATE_BANNER, data)

// ***payment Option ***
export const getPayment_OptionList = () => get(url.GET_PAYMENT_OPTION)
export const addPayment_OptionList = data => postFormData(url.ADD_PAYMENT_OPTION, data)
export const updatePayment_OptionList = data => postFormData(url.UPDATE_PAYMENT_OPTION, data)
export const activatePayment_Option = data => post(url.ACTIVATE_PAYMENT_OPTION, data)

// ***bids management***
export const getBidListApi = () => get(url.GET_BIDS);
export const getBidDetailsApi = (data) => post(url.GET_BIDS_DETAILS,data);
// ***bids management***

// ***Auction bids order management***
export const getAuctionListApi = () => get(url.GET_AUCTION)
export const getAuctionDetailsApi = data => post(url.GET_AUCTION_DETAILS, data)
// ***bids management***

// ***customer Auction bids order management***
export const getCustomerAuctionListApi = () => get(url.GET_CUSTOMER_AUCTION)
export const getCustomerAuctionDetailsApi = data => post(url.GET_CUSTOMER_AUCTION_DETAILS, data)
// ***customer bids management***


// ***product***
export const getProductListApi = () => get(url.GET_PRODUCT);
export const getApprovedProductListApi = () => get(url.GET_APPROVED_PRODUCT);
export const getRejectedProductListApi = () => get(url.GET_REJECTED_PRODUCT);
export const getRequestProductListApi = () => get(url.GET_REQUEST_PRODUCT);
export const getProductDetailsApi = (data) => post(url.GET_PRODUCT_DETAILS,data);
export const getRequestProductDetailsApi = (data) => post(url.GET_PRODUCT_DETAILS,data);
export const getRejectedProductDetailsApi = (data) => post(url.GET_PRODUCT_DETAILS,data);
export const getApprovedProductDetailsApi = (data) => post(url.GET_PRODUCT_DETAILS,data);
export const addProductList = data => postFormData(url.ADD_PRODUCT, data);
export const approveProduct = data => post(url.APPROVE_PRODUCT, data);
export const featuredProduct = data => post(url.FEATURED_PRODUCT, data);
export const unFeaturedProduct = data => put(url.FEATURED_PRODUCT, data);
export const rejectedProduct = data => post(url.REJECTED_PRODUCT, data);

// Customer Product managements API
export const getCustomerProductListApi = data => get(url.CUSTOMER_PRODUCT_API, data);
export const getCustomerApprovedProductListApi = () => get(url.CUSTOMER_APPROVED_PRODUCT_API);
export const getCustomerProductDetailsApi = data => get(url.CUSTOMER_PRODUCT_DETAILS, data);

// Customer BIDDING managements API
export const getCustomerBidListApi = () => get(url.CUSTOMER_BID_API);
export const getCustomerBidDetailsApi = data => get(url.CUSTOMER_BID_DETAILS, data);
export const addCustomerBidApi = data => post(url.ADD_BID, data);


// ***staff***
export const getStaffList = () => get(url.GET_STAFF);
export const addStaffList = data => post(url.ADD_STAFF, data);
export const updateStaff = data => put(url.ADD_STAFF, data);

// ***News***
export const getNewsList = () => get(url.GET_NEWS)
export const addNewsList = data => postFormData(url.ADD_NEWS, data)
export const updateNews = data => put(url.ADD_NEWS, data)
export const activateNews = data => post(url.ACTIVATE_NEWS, data)

// ***Customer***
export const getCustomerList = () => get(url.GET_CUSTOMER);
// export const addCustomerList = data => post(url.ADD_CUSTOMER, data);
// export const updateCustomerDetails = data => put(url.ADD_CUSTOMER_DETAIL, data);

// ***REGION***
export const getRegionList = () => get(url.GET_REGION);
export const addRegionList = data => post(url.ADD_REGION, data);
export const updateRegionList = data => put(url.ADD_REGION, data);

// ***DISTRICT***
export const getDistrictList = data => post(url.GET_DISTRICT,data);
export const addDistrictList = data => post(url.ADD_DISTRICT, data);
export const updateDistrictList = data => put(url.ADD_DISTRICT, data);


// ***WARD***
export const getWardList = data => post(url.GET_WARD,data);
export const addWardList = data => post(url.ADD_WARD, data);
export const updateWardList = data => put(url.ADD_WARD, data);

// ***STREET***
export const getStreetList = data => post(url.GET_STREET,data);
export const addStreetList = data => post(url.ADD_STREET, data);
export const updateStreetList = data => put(url.ADD_STREET, data);
  
// ***permissions***
export const getPermissionList = () => get(url.GET_PERMISSION);
export const addPermissionList = data => post(url.ADD_PERMISSION, data);

// Login Method
const postJwtLogin = data => post(url.POST_LOGIN, data);
const getRefreshPage = data => get(url.REFRESH_PAGE, data);
const addForgetPwd = data => post(url.POST_PASSWORD_FORGET, data);
const addForgetPwdToken = data => post(url.POST_PASSWORD_FORGET_TOKEN, data);

//  *******wekadau****
//  *******wekadau****
//  *******wekadau****






// postForgetPwd
const postJwtForgetPwd = data => post(url.POST_FAKE_JWT_PASSWORD_FORGET, data);

// postSocialLogin
export const postSocialLogin = data => post(url.SOCIAL_LOGIN, data);

// get Products
export const getProducts = () => get(url.GET_PRODUCTS);

// get Product detail
export const getProductDetail = id =>
  get(`${url.GET_PRODUCTS_DETAIL}/${id}`, { params: { id } });

// get Events
export const getEvents = () => get(url.GET_EVENTS);

// add Events
export const addNewEvent = event => post(url.ADD_NEW_EVENT, event);

// update Event
export const updateEvent = event => put(url.UPDATE_EVENT, event);

// delete Event
export const deleteEvent = event =>
  del(url.DELETE_EVENT, { headers: { event } });



// get chats
export const getChats = () => get(url.GET_CHATS);

// get groups
export const getGroups = () => get(url.GET_GROUPS);

// get Contacts
export const getContacts = () => get(url.GET_CONTACTS);

// get messages
export const getMessages = (roomId = "") =>
  get(`${url.GET_MESSAGES}/${roomId}`, { params: { roomId } });

// post messages
export const getselectedmails = (selectedmails) => post(url.GET_SELECTED_MAILS, selectedmails);

//post setfolderonmails
export const setfolderonmails = (selectedmails, folderId, activeTab) => post(url.SET_FOLDER_SELECTED_MAILS, { selectedmails, folderId, activeTab });

// get orders
export const getOrders = () => get(url.GET_ORDERS);

// add order
export const addNewOrder = order => post(url.ADD_NEW_ORDER, order);

// update order
export const updateOrder = order => put(url.UPDATE_ORDER, order);

// delete order
export const deleteOrder = order =>
  del(url.DELETE_ORDER, { headers: { order } });

// get cart data
export const getCartData = () => get(url.GET_CART_DATA);

// get customers
export const getCustomers = () => get(url.GET_CUSTOMERS);

// add CUSTOMER
export const addNewCustomer = customer => post(url.ADD_NEW_CUSTOMER, customer);

// update CUSTOMER
export const updateCustomer = customer => put(url.UPDATE_CUSTOMER, customer);

// delete CUSTOMER
export const deleteCustomer = customer =>
  del(url.DELETE_CUSTOMER, { headers: { customer } });

// get shops
export const getShops = () => get(url.GET_SHOPS);

// get wallet
export const getWallet = () => get(url.GET_WALLET);

// get crypto order
export const getCryptoOrder = () => get(url.GET_CRYPTO_ORDERS);

// get crypto product
export const getCryptoProduct = () => get(url.GET_CRYPTO_PRODUCTS);

// get invoices
export const getInvoices = () => get(url.GET_INVOICES);

// get invoice details
export const getInvoiceDetail = id =>
  get(`${url.GET_INVOICE_DETAIL}/${id}`, { params: { id } });

// get jobs
export const getJobList = () => get(url.GET_JOB_LIST);

// get Apply Jobs
export const getApplyJob = () => get(url.GET_APPLY_JOB);

// get project
export const getProjects = () => get(url.GET_PROJECTS);

// get project details
export const getProjectsDetails = id =>
  get(`${url.GET_PROJECT_DETAIL}/${id}`, { params: { id } });

// get tasks
export const getTasks = () => get(url.GET_TASKS);



// add CardData Kanban
export const addCardData = cardData => post(url.ADD_CATEGORY, cardData);

// update jobs
export const updateCardData = card => put(url.UPDATE_CARD_DATA, card);

// delete Kanban
export const deleteKanban = kanban => del(url.DELETE_KANBAN, { headers: { kanban } });

// get contacts
export const getUsers = () => get(url.GET_USERS);

// add user
export const addNewUser = user => post(url.ADD_NEW_USER, user);

// update user
export const updateUser = user => put(url.UPDATE_USER, user);

// delete user
export const deleteUser = user => del(url.DELETE_USER, { headers: { user } });

// add jobs
export const addNewJobList = job => post(url.ADD_NEW_JOB_LIST, job);

// update jobs
export const updateJobList = job => put(url.UPDATE_JOB_LIST, job);

// delete jobs
export const deleteJobList = job => del(url.DELETE_JOB_LIST, { headers: { job } });

// Delete Apply Jobs
export const deleteApplyJob = data => del(url.DELETE_APPLY_JOB, { headers: { data } });

/** PROJECT */
// add user
export const addNewProject = project => post(url.ADD_NEW_PROJECT, project);

// update user
export const updateProject = project => put(url.UPDATE_PROJECT, project);

// delete user
export const deleteProject = project =>
  del(url.DELETE_PROJECT, { headers: { project } });

export const getUserProfile = () => get(url.GET_USER_PROFILE);

// get maillist
export const getMailsLists = filter => post(url.GET_MAILS_LIST, {
  params: filter,
});

//update mail
export const updateMail = mail => put(url.UPDATE_MAIL, mail);

// get folderlist
export const selectFolders = () => get(url.SELECT_FOLDER);

// post messages
export const addMessage = message => post(url.ADD_MESSAGE, message);

// delete message
export const deleteMessage = data => del(url.DELETE_MESSAGE, { headers: { data } });

// get dashboard charts data
export const getWeeklyData = () => get(url.GET_WEEKLY_DATA);
export const getYearlyData = () => get(url.GET_YEARLY_DATA);
export const getMonthlyData = () => get(url.GET_MONTHLY_DATA);

export const walletBalanceData = month =>
  get(`${url.GET_WALLET_DATA}/${month}`, { params: { month } });

export const getStatisticData = duration =>
  get(`${url.GET_STATISTICS_DATA}/${duration}`, { params: { duration } });

export const visitorData = duration =>
  get(`${url.GET_VISITOR_DATA}/${duration}`, { params: { duration } });

export const topSellingData = month =>
  get(`${url.TOP_SELLING_DATA}/${month}`, { params: { month } });

export const getEarningChartsData = month =>
  get(`${url.GET_EARNING_DATA}/${month}`, { params: { month } });

const getProductComents = () => get(url.GET_PRODUCT_COMMENTS);

const onLikeComment = (commentId, productId) => {
  return post(`${url.ON_LIKNE_COMMENT}/${productId}/${commentId}`, {
    params: { commentId, productId },
  });
};
const onLikeReply = (commentId, productId, replyId) => {
  return post(`${url.ON_LIKNE_COMMENT}/${productId}/${commentId}/${replyId}`, {
    params: { commentId, productId, replyId },
  });
};

const onAddReply = (commentId, productId, replyText) => {
  return post(`${url.ON_ADD_REPLY}/${productId}/${commentId}`, {
    params: { commentId, productId, replyText },
  });
};

const onAddComment = (productId, commentText) => {
  return post(`${url.ON_ADD_COMMENT}/${productId}`, {
    params: { productId, commentText },
  });
};

export {
  getLoggedInUser,
  isUserAuthenticated,
  postFakeRegister,
  postFakeLogin,
  postFakeProfile,
  postFakeForgetPwd,
  postJwtRegister,
  postJwtLogin,
  getRefreshPage,
  postJwtForgetPwd,
  postJwtProfile,
  addForgetPwd,
  addForgetPwdToken,
  getProductComents,
  onLikeComment,
  onLikeReply,
  onAddReply,
  onAddComment,
};
