/* NEWS */
export const GET_NEWS = "GET_NEWS"
export const ACTIVATE_NEWS = "ACTIVATE_NEWS"
export const GET_NEWS_SUCCESS = "GET_NEWS_SUCCESS"
export const GET_NEWS_FAIL = "GET_NEWS_FAIL"
export const ADD_NEWS_DATA = "ADD_NEWS_DATA"
export const UPDATE_NEWS_DATA = "UPDATE_NEWS_DATA"
export const ADD_NEWS_DATA_SUCCESS = "ADD_NEWS_DATA_SUCCESS"
export const ADD_NEWS_DATA_FAIL = "ADD_NEWS_DATA_FAIL"

