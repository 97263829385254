import {
  GET_GROUP,
  ADD_GROUP_DATA,
  ADD_GROUP_DATA_SUCCESS,
  ADD_GROUP_DATA_FAIL,
  GET_GROUP_SUCCESS,
  GET_GROUP_FAIL,
  ADD_GROUP_PERMISSION_DATA_SUCCESS,
  ADD_GROUP_PERMISSION_DATA_FAIL,
  ADD_GROUP_PERMISSION_DATA,
  UPDATE_GROUP_DATA
} from "./actionTypes"

// GROUP
export const getGroup = () => ({
  type: GET_GROUP,
})

export const addGroupData = data => ({
  type: ADD_GROUP_DATA,
  payload: data,
})


export const updateGroupData = data => ({
  type: UPDATE_GROUP_DATA,
  payload: data,
})

export const addPermissionGroupData = data => ({
  type: ADD_GROUP_PERMISSION_DATA,
  payload: data,
})


export const getGroupDataSuccess = data => ({
  type: GET_GROUP_SUCCESS,
  payload: data,
})


export const addGroupDataSuccess = data => ({
  type: ADD_GROUP_DATA_SUCCESS,
  payload: data,
})

export const addGroupPermissionDataSuccess = data => ({
  type: ADD_GROUP_PERMISSION_DATA_SUCCESS,
  payload: data,
})

export const addGroupPermissionDataFail = error => ({
  type: ADD_GROUP_PERMISSION_DATA_FAIL,
  payload: error,
})

export const addGroupDataFail = error => ({
  type: ADD_GROUP_DATA_FAIL,
  payload: error,
})

export const getGroupDataFail = error => ({
  type: GET_GROUP_FAIL,
  payload: error,
})
// GROUP