import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_AUCTION,
  GET_AUCTION_DETAILS,
} from "./actionTypes"

import {
  getAuctionOrderDataFail,
  getAuctionOrderDataSuccess,
  getAuctionOrderDetailsDataSuccess,
  getAuctionOrderDetailsDataFail,
} from "./actions"

import { toast } from "react-toastify"

// Include Both Helper File with needed methods
import {
  getAuctionListApi,
  getAuctionDetailsApi,
} from "helpers/backend_helper"

// all bids 
function* fetchAuction() {
  try {
    const response = yield call(getAuctionListApi)
    yield put(getAuctionOrderDataSuccess(response))
  } catch (error) {
    yield put(getAuctionOrderDataFail(error))
  }
}

// fetch bids Details
function* fetchAuctionDetails({ payload: data }) {
  try {
    const response = yield call(getAuctionDetailsApi, data)
    yield put(getAuctionOrderDetailsDataSuccess(response))
  } catch (error) {
    yield put(getAuctionOrderDetailsDataFail(error))
  }
}

function* AuctionSaga() {
  yield takeEvery(GET_AUCTION, fetchAuction)
  yield takeEvery(GET_AUCTION_DETAILS, fetchAuctionDetails)
}

export default AuctionSaga
