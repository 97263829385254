/* CATEGORY */
export const GET_CATEGORY = "GET_CATEGORY"
export const GET_CATEGORY_SUCCESS = "GET_CATEGORY_SUCCESS"
export const GET_CATEGORY_FAIL = "GET_CATEGORY_FAIL"
export const ADD_CATEGORY_DATA = "ADD_CATEGORY_DATA"
export const UPDATE_CATEGORY_DATA = "UPDATE_CATEGORY_DATA"
export const UPDATE_SUBCATEGORY_DATA = "UPDATE_SUBCATEGORY_DATA"
export const ADD_CATEGORY_DATA_SUCCESS = "ADD_CATEGORY_DATA_SUCCESS"
export const ADD_CATEGORY_DATA_FAIL = "ADD_CATEGORY_DATA_FAIL"

/* SUBCATEGORY */
export const GET_SUBCATEGORY = "GET_SUBCATEGORY"
export const GET_SUBCATEGORY_SUCCESS = "GET_SUBCATEGORY_SUCCESS"
export const GET_SUBCATEGORY_FAIL = "GET_SUBCATEGORY_FAIL"
export const ADD_SUBCATEGORY_DATA = "ADD_SUBCATEGORY_DATA"
export const ADD_SUBCATEGORY_DATA_SUCCESS = "ADD_SUBCATEGORY_DATA_SUCCESS"
export const ADD_SUBCATEGORY_DATA_FAIL = "ADD_SUBCATEGORY_DATA_FAIL"