import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_STAFF,
  ADD_STAFF_DATA,
  UPDATE_STAFF_DATA
} from "./actionTypes"


import {
  addStaffDataSuccess,
  addStaffDataFail,
  getStaffDataFail,
  getStaffDataSuccess,
  updateStaffData
} from "./actions"

import { toast } from "react-toastify"

// Include Both Helper File with needed methods
import {
  getStaffList,
  addStaffList,
  updateStaff
} from "helpers/backend_helper"

// group
function* fetchStaff() {
  try {
    const response = yield call(getStaffList)
    yield put(getStaffDataSuccess(response))
  } catch (error) {
    yield put(getStaffDataFail(error))
  }
}

// add data 
function* onAddStaffData({ payload: data }) {
  try {
    const response = yield call(addStaffList, data)
    yield put({type:"GET_STAFF"})
    toast.success("Staff Added Successfully", { autoClose: 5000 });
  } catch (error) {
    const message = error.response.data.name ? error.response.data.name[0] : (error.detail ? error.detail : error.response.data.email ? error.response.data.email[0] : "System error"); 
    yield put(addStaffDataFail(message))
    toast.error(message, { autoClose: 5000 });
  }
}

// update data 
function* onUpdateStaffData({ payload: data }) {
  try {
    const response = yield call(updateStaff, data)
    yield put({type:"GET_STAFF"})
    toast.success("Staff Edited Successfully", { autoClose: 5000 });
  } catch (error) {
    const message = error.response.data.name ? error.response.data.name[0] : (error.detail ? error.detail : error.response.data.email ? error.response.data.email[0] : "System error"); 
    yield put(addStaffDataFail(message))
    toast.error(message, { autoClose: 5000 });
  }
}

function* StaffSaga() {
  yield takeEvery(GET_STAFF, fetchStaff)
  yield takeEvery(ADD_STAFF_DATA, onAddStaffData)
  yield takeEvery(UPDATE_STAFF_DATA, onUpdateStaffData)
}

export default StaffSaga
