/* GROUP */
export const GET_GROUP = "GET_GROUP"
export const GET_GROUP_SUCCESS = "GET_GROUP_SUCCESS"
export const GET_GROUP_FAIL = "GET_GROUP_FAIL"
// ADD GROUP
export const ADD_GROUP_DATA = "ADD_GROUP_DATA"
export const UPDATE_GROUP_DATA = "UPDATE_GROUP_DATA"
export const ADD_GROUP_DATA_SUCCESS = "ADD_GROUP_DATA_SUCCESS"
export const ADD_GROUP_DATA_FAIL = "ADD_GROUP_DATA_FAIL"
// GROUP PERMISSION
export const ADD_GROUP_PERMISSION_DATA = "ADD_GROUP_PERMISSION_DATA"
export const ADD_GROUP_PERMISSION_DATA_SUCCESS = "ADD_GROUP_PERMISSION_DATA_SUCCESS"
export const ADD_GROUP_PERMISSION_DATA_FAIL = "ADD_GROUP_PERMISSION_DATA_FAIL"

