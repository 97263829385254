import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_CATEGORY,
  ADD_CATEGORY_DATA,
  ADD_SUBCATEGORY_DATA,
  GET_SUBCATEGORY,
  UPDATE_CATEGORY_DATA,
  UPDATE_SUBCATEGORY_DATA
} from "./actionTypes"


import {
  addCategoryDataSuccess,
  addCategoryDataFail,
  getCategoryDataFail,
  getCategoryDataSuccess,
  addSubCategoryDataSuccess,
  getSubCategoryDataFail,
  getSubCategoryDataSuccess,
  addSubCategoryDataFail,
  updateCategoryData
} from "./actions"

import { toast } from "react-toastify"

// Include Both Helper File with needed methods
import {
  getCategoryList,
  addCategoryList,
  addSubCategoryList,
  updateCategoryList,
  getSubCategoryList,
  updateSubCategoryList
} from "helpers/backend_helper"
import { data } from "autoprefixer"

//  subcategory
function* fetchSubCategory({ payload: data }) {
  try {
    const response = yield call(getSubCategoryList,data)
    yield put(getSubCategoryDataSuccess(response))
  } catch (error) {
    yield put(getSubCategoryDataFail(error))
  }
}

function* onAddSubCategoryData({ payload: data }) {
  try {
    const response = yield call(addSubCategoryList, data)
    // yield put(addSubCategoryDataSuccess(response))
    yield put({type:"GET_SUBCATEGORY",payload:data})
    toast.success("Sub Category Added Successfully", { autoClose: 5000 });
  } catch (error) {
    const message = error.response.data.name ? error.response.data.name[0] : (error.response.data.detail ? error.response.data.detail:error.response.detail ? "" : "System error"); 
    yield put(addSubCategoryDataFail(message))
    toast.error(message, { autoClose: 5000 });
  }
}

function* onUpdateCategoryData({ payload: data }) {
  try {
    const formData = new FormData()
    formData.append("id", data.id)
    formData.append("name", data.name)
    formData.append("category_image", data.fileImage[0])
    console.log("data")
    console.log(data)
    console.log("data")
    const response = yield call(updateCategoryList, formData)
    yield put({type:"GET_CATEGORY"})
    toast.success(response.data? response.data.detail:"Successfully", { autoClose: 5000 });
  } catch (error) {
    console.log(error.response.data)
    const message = error.response.data.name ? error.response.data.name[0] : (error.response.data.detail ? error.response.data.detail:error.response.detail ? "" : "System error"); 
    yield put(addSubCategoryDataFail(message))
    toast.error(message, { autoClose: 5000 });
  }
}

function* onUpdateSubCategoryData({ payload: data }) {
  try {
    const response = yield call(updateSubCategoryList, data)
    yield put({type:"GET_SUBCATEGORY",payload:data})
    toast.success(response.data? response.data.detail:"Successfully", { autoClose: 5000 });
  } catch (error) {
    console.log(error.response.data)
    const message = error.response.data.name ? error.response.data.name[0] : (error.response.data.detail ? error.response.data.detail:error.response.detail ? "" : "System error"); 
    yield put(addSubCategoryDataFail(message))
    toast.error(message, { autoClose: 5000 });
  }
}


// category
function* fetchCategory() {
  try {
    const response = yield call(getCategoryList)
    yield put(getCategoryDataSuccess(response))
  } catch (error) {
    yield put(getCategoryDataFail(error))
  }
}

function* onAddCategoryData({ payload: data }) {
  try {
     const formData = new FormData()
     formData.append("name", data.name)
     formData.append("category_image", data.fileImage[0])
    const response = yield call(addCategoryList, formData)
    // yield put(addCategoryDataSuccess(response))
    yield put({type:"GET_CATEGORY"})
    toast.success("Category Added Successfully", { autoClose: 5000 });
  } catch (error) {
    const message = error.response.data.name ? error.response.data.name[0] : (error.response.data.detail ? error.response.data.detail:error.response.detail ? "" : "System error"); 
    yield put(addCategoryDataFail(message))
    toast.error(message, { autoClose: 5000 });
  }
}

function* categorySaga() {
  yield takeEvery(GET_CATEGORY, fetchCategory)
  yield takeEvery(ADD_CATEGORY_DATA, onAddCategoryData)
  yield takeEvery(UPDATE_CATEGORY_DATA, onUpdateCategoryData)
  yield takeEvery(UPDATE_SUBCATEGORY_DATA, onUpdateSubCategoryData)
  yield takeEvery(ADD_SUBCATEGORY_DATA, onAddSubCategoryData)
  yield takeEvery(GET_SUBCATEGORY, fetchSubCategory)
}

export default categorySaga
