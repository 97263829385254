import React, { useState,useMemo,useEffect,useRef } from "react";
import { createSelector } from "reselect"
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  Label,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Container,
  FormFeedback,
  UncontrolledTooltip,
  Form,
} from "reactstrap";
import { ToastContainer } from "react-toastify";

import TableContainer from '../../../components/Common/TableContainer';

import { Link } from "react-router-dom";

import {
  addGroupData as onAddGroupData,
  updateGroupData as onUpdateGroupData,
  getGroup as onGetGroup,
} from "store/authetications/group/actions"

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
// redux
import { useSelector, useDispatch } from "react-redux"

import DeleteModal from "../DeleteModal";

const Group = () => {

//meta title
document.title="WekaDau | Group";

//  dispatch
const dispatch = useDispatch();

// fetch data for the group
useEffect(() => {
  dispatch(onGetGroup())
}, [dispatch])

const [group, setGroup] = useState();
const [isEdit, setIsEdit] = useState(false);
// delete modal
const [deleteModal, setDeleteModal] = useState(false);
const [groups, setGroups] = useState([])

  //  modal
const [modal, setModal] = useState(false)


const onClickDelete = group => {
   setGroup(group);
   setDeleteModal(true);
};


  const selectGroupState = state => state.Groups
  
  const GroupProperties = createSelector(selectGroupState, Group => ({
    GroupData:Group.data,
    loading: Group.loading
  }))

  const { GroupData, loading } = useSelector(GroupProperties)

useEffect(() => {
  setGroups(GroupData ? GroupData.data:[])
}, [GroupData])

const handleGroupClick = arg => {
  const group = arg;
  setGroup({
    id: group.id,
    name: group.name
  });
  setIsEdit(true);
  toggle();
};
  
// handle delete group
const handleDeleteGroup = () => {
  if (group && group.id) {
    console.log(group.id)
    // dispatch(onDeleteUser(group.id));
  }
  onPaginationPageChange(1);
  setDeleteModal(false);
};

  const toggle = () => {
    if (modal) {
      // setGroup();
      setIsEdit(false);
      setModal(false)
    } else {
      setModal(true)
    }
  }

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };


  // Form validation 
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (group && group.name) || ''
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Group Name"),
    }),
    onSubmit: (values) => {
      if(isEdit){
            const data={
              'name':values.name,
              'id':group.id
            }
            dispatch(onUpdateGroupData(data));
       }else{
          let payload =  [
              {
                "group":values.name
              }
              ];
            dispatch(onAddGroupData(payload));
       }
      validation.resetForm()
      toggle();
    }
  });

  const columns = useMemo(
    () => [
        {
            Header: 'group name',
            accessor: 'name',
        },
        {
          Header: "Action",
          Cell: cellProps => {
            return (
              <div className="d-flex gap-3">
                <Link
                  to="#"
                  className="text-info"
                  onClick={() => {
                    const groupData = cellProps.row.original;
                    handleGroupClick(groupData);
                  }}
                >
                  <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    Edit
                  </UncontrolledTooltip>
                </Link>
                <Link
                  to={"/all-permission/" + cellProps.row.original.id}
                  className="text-success"
                >
                  <i className="mdi mdi-eye font-size-18" id="viewtooltip" />
                  <UncontrolledTooltip placement="top" target="viewtooltip">
                    View
                  </UncontrolledTooltip>
                </Link>
                {/* <Link
                  to="#"
                  className="text-danger"
                  onClick={() => {
                    const userData = cellProps.row.original;
                    onClickDelete(userData);
                  }}
                >
                  <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </Link> */}
              </div>
            );
          },
        },
    ],
    []
);


  const regExp = /\b\d{5}\b/;
  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteGroup}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Group" breadcrumbItem="Group List" />
          <Row>
            <Col xl="4">
              <Card>
                <CardBody>
                  <div className="text-right d-grid">
                                  <Link
                                    to="#"
                                    className="btn btn-primary waves-effect waves-light addtask-btn"
                                    data-bs-toggle="modal"
                                    data-bs-target=".bs-example-modal-lg"
                                    data-id="#upcoming-task"
                                    onClick={() => toggle(true)}
                                  >
                                    <i className="mdi mdi-plus me-1"></i> Add Group
                                  </Link>
                                </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl="8">
            <Card>
                <CardBody>
                <div className="container-fluid">
                <TableContainer
                    columns={columns}
                    data={groups}
                    isGlobalFilter={true}
                    isAddOptions={false}
                    customPageSize={10}
                    isPagination={true}
                    tableClass="align-middle table-nowrap table-check table"
                    theadClass="table-light"
                    paginationDiv="col-12"
                    pagination="justify-content-center pagination pagination-rounded"
                />
            </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {/* modal */}
      <Modal id="modalForm" isOpen={modal} toggle={toggle} centered={true} size="sm">
        <ModalHeader toggle={toggle}>
          {!!isEdit ? "Edit Group" : "Add Group"}
        </ModalHeader>
        <ModalBody>
        <Form className="needs-validation"
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <Row>
                      <Col md="12" sm="12" lg="12">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom01">group name</Label>
                          <Input
                            name="name"
                            placeholder="Group name"
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.name || ""}
                            invalid={
                              validation.touched.name && validation.errors.name ? true : false
                            }
                          />
                          {validation.touched.name && validation.errors.name ? (
                            <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Button color="primary" type="submit">
                      Submit
                    </Button>
                  </Form>
        </ModalBody>
      </Modal>
      {/* modal */}
      <ToastContainer />
    </React.Fragment>
  );
};

export default Group;
