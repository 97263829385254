import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";

// SETTINGS
import Settings from './setting/reducer';
import News from './setting/News/reducer';
import banner from './setting/banner/reducer';
import PaymentOption from './setting/paymentOption/reducer';
import Groups from './authetications/group/reducer';
import Region from './address/region/reducer';
import District from './address/district/reducer';
import Ward from './address/ward/reducer';
import Street from './address/street/reducer';
import Permission from './authetications/permission/reducer';

// Staff Management
import Staff from './user/Staff/reducer';


// Bidding Management
import Bidding from './Bidding/BiddingList/reducer';

// AUCTION Bidding Management
import Auction from './Bidding/AuctionBiddingList/reducer';

// Bidding Management
// Product Management
import Product from './product/ProductList/reducer';
import RejectedProduct from './product/RejectedProduct/reducer';
import ApprovedProduct from './product/ApprovedProduct/reducer';
import RequestProduct from './product/RequestProduct/reducer';

/**customer product management**/ 
import CustomerProduct from './product/CustomerProduct/reducer';

/**customer bid management**/ 
import CustomerBid from './Bidding/CustomerBid/reducer';

/**customer auction management**/ 
import CustomerAuction from './Bidding/CustomerAuctionBiddingList/reducer';


// Customer Management
import customer from './user/customer/reducer';

//E-commerce
import ecommerce from "./e-commerce/reducer";

//Calendar
import calendar from "./calendar/reducer";

//chat
import chat from "./chat/reducer";

//crypto
import crypto from "./crypto/reducer";1

//invoices
import invoices from "./invoices/reducer";

//jobs
import JobReducer from "./jobs/reducer";

//projects
import projects from "./projects/reducer";

//tasks
import tasks from "./tasks/reducer";

//contacts
import contacts from "./contacts/reducer";

//mails
import mails from "./mails/reducer";

//Dashboard 
import Dashboard from "./dashboard/reducer";

//Dashboard saas
import DashboardSaas from "./dashboard-saas/reducer";

//Dashboard crypto
import DashboardCrypto from "./dashboard-crypto/reducer";

//Dashboard blog
import DashboardBlog from "./dashboard-blog/reducer";

//Dashboard job
import DashboardJob from "./dashboard-jobs/reducer";

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  ecommerce,
  calendar,
  chat,
  mails,
  crypto,
  invoices,
  JobReducer,
  projects,
  tasks,
  contacts,
  Dashboard,
  DashboardSaas,
  DashboardCrypto,
  DashboardBlog,
  DashboardJob,
  // weka dau
  Settings,
  Groups,
  Staff,
  customer,
  Permission,
  Region,
  District,
  Ward,
  Street,
  Product,
  RejectedProduct,
  RequestProduct,
  ApprovedProduct,
  Bidding,
  Auction,
  banner,
  News,
  PaymentOption,
  /**customer**/  
  CustomerProduct,
  /**customer auction list**/ 
  CustomerAuction,
  /**customer bidding**/ 
  CustomerBid
})

export default rootReducer;
