import * as React from "react";
import cx from "classnames";

const stepsList = [
  { 
    name:"Details",
    id:1,
    icon:'fa fa-info fa-10x'
  }, 
  { 
    name:"Price",
    id:2,
    icon:'fa fa-credit-card fa-10x'
  }, 
  { 
    name:"Address",
    id:3,
    icon:'fa fa-map-marker fa-10x'
  },
];

const Steps = ({ currentStepIndex }) => {
  return (
    <div className="steps">
      {stepsList.map((step, idx) => (
        <div key={step.id}
          className={cx(
            "step-item",
            "is-success",
            currentStepIndex > idx && "is-completed",
            idx === currentStepIndex && "is-active"
          )}
        >
          <div className="step-marker">
            <span className="icon">
              <i className={step.icon}></i>
            </span>
          </div>
          <div className="step-details">
            <p className="step-title">{step.name}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Steps;
