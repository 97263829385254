/* PAYMENT_OPTION */
export const GET_PAYMENT_OPTION = "GET_PAYMENT_OPTION"
export const GET_PAYMENT_OPTION_SUCCESS = "GET_PAYMENT_OPTION_SUCCESS"
export const GET_PAYMENT_OPTION_FAIL = "GET_PAYMENT_OPTION_FAIL"
// ADD PAYMENT_OPTION
export const ADD_PAYMENT_OPTION_DATA = "ADD_PAYMENT_OPTION_DATA"
export const ACTIVATE_PAYMENT_OPTION = "ACTIVATE_PRODUCT"
export const UPDATE_PAYMENT_OPTION_DATA = "UPDATE_PAYMENT_OPTION_DATA"
export const ADD_PAYMENT_OPTION_DATA_SUCCESS = "ADD_PAYMENT_OPTION_DATA_SUCCESS"
export const ADD_PAYMENT_OPTION_DATA_FAIL = "ADD_PAYMENT_OPTION_DATA_FAIL"


