import {
  GET_REJECTED_PRODUCT,
  GET_REJECTED_PRODUCT_SUCCESS,
  GET_REJECTED_PRODUCT_DETAILS_SUCCESS,
  GET_REJECTED_PRODUCT_DETAILS_FAIL,
  GET_REJECTED_PRODUCT_FAIL,
  GET_REJECTED_PRODUCT_DETAILS,
  APPROVE_PRODUCT,
  REJECT_PRODUCT,
  FEATURED_PRODUCT,
  UNFEATURED_PRODUCT,
} from "./actionTypes"

// PRODUCT
export const getRejectedProduct = () => ({
  type: GET_REJECTED_PRODUCT,
})

// 
export const getRejectedProductDataSuccess = data => ({
  type: GET_REJECTED_PRODUCT_SUCCESS,
  payload: data,
})

export const getRejectedProductDataFail = error => ({
  type: GET_REJECTED_PRODUCT_FAIL,
  payload: error,
})
// PRODUCT

/*PRODUCT DETAILS*/ 
export const getRejectedProductDetails= data => ({
  type: GET_REJECTED_PRODUCT_DETAILS,
  payload: data,
})

// /*approve product*/ 
// export const approveProduct= data => ({
//   type: APPROVE_PRODUCT,
//   payload: data,
// })
// /*approve product*/ 

// /*rejected product*/ 
// export const rejectedProduct= data => ({
//   type: REJECT_PRODUCT,
//   payload: data,
// })
// /*rejected product*/ 

// /*featured product*/ 
// export const featuredProduct= data => ({
//   type: FEATURED_PRODUCT,
//   payload: data,
// })
// /*featured product*/ 

// /*featured product*/ 
// export const unFeaturedProduct= data => ({
//   type: UNFEATURED_PRODUCT,
//   payload: data,
// })
// /*featured product*/ 

export const getRejectedProductDetailsDataSuccess = data => ({
  type: GET_REJECTED_PRODUCT_DETAILS_SUCCESS,
  payload: data,
})

export const getRejectedProductDetailsDataFail = error => ({
  type: GET_REJECTED_PRODUCT_DETAILS_FAIL,
  payload: error,
})

/*PRODUCT DETAILS*/ 