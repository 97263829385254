import {
  GET_CATEGORY,
  ADD_CATEGORY_DATA_SUCCESS,
  ADD_CATEGORY_DATA_FAIL,
  GET_CATEGORY_SUCCESS,
  GET_CATEGORY_FAIL,
  ADD_SUBCATEGORY_DATA_SUCCESS,
  ADD_SUBCATEGORY_DATA_FAIL,
  GET_SUBCATEGORY_FAIL,
  GET_SUBCATEGORY,
  GET_SUBCATEGORY_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
  data: [],
  error: {},
  loading : true
}

const settings = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CATEGORY:
      return {
        ...state,
        data: action.payload,
        loading : true
      }

      case GET_CATEGORY_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading : true
      }

    case GET_CATEGORY_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_CATEGORY_DATA_SUCCESS:
      return {
        ...state,

        data: state.data.map(data => {
          return data
        }),
      }

    case ADD_CATEGORY_DATA_FAIL:
      return {
        ...state,
        error: action.payload,
      }

      // sub category
      case GET_SUBCATEGORY:
      return {
        ...state,
        subcategory: action.payload,
        loading : true
      }

      case GET_SUBCATEGORY_SUCCESS:
      return {
        ...state,
        subcategory: action.payload,
        loading : true
      }

    case GET_SUBCATEGORY_FAIL:
      return {
        ...state,
        error: action.payload,
      }

      case ADD_SUBCATEGORY_DATA_SUCCESS:
        return {
          ...state,
  
          data: state.data.map(data => {
            return data
          }),
        }
  
      case ADD_SUBCATEGORY_DATA_FAIL:
        return {
          ...state,
          error: action.payload,
        }

    default:
      return state
  }
}

export default settings
