import React, { useState } from 'react';
import {
  Row,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
} from "reactstrap";

import ProductListRequest from './ProductListRequest';
import ApprovedProducts from './ApprovedProducts';
import RejectedProducts from './RejectedProducts';
import AllProduct from './allProduct';

import { ToastContainer } from "react-toastify";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";


const ProductList = () => {

//meta title
  const [activeTab, setActiveTab] = useState('1');

return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Product" breadcrumbItem="Product List" />
                <div>
  <Nav tabs horizontal="center">
    <NavItem>
      <NavLink
        className={activeTab === '1' ? 'active' : ''}
        onClick={() => setActiveTab('1')}
      >
      <i className="bx bx-git-pull-request me-2" /> Request Product
      </NavLink>
    </NavItem>
    <NavItem>
      <NavLink
        className={activeTab === '2' ? 'active' : ''}
        onClick={() => setActiveTab('2')}
      >
      <i className="bx bx-check me-2" />  Approved Product
      </NavLink>
    </NavItem>
    <NavItem>
      <NavLink
        className={activeTab === '3' ? 'active' : ''}
        onClick={() => setActiveTab('3')}
      >
     <i className="bx bx-x me-2" />     Rejected Product 
      </NavLink>
    </NavItem>
    <NavItem>
      <NavLink
        className={activeTab === '4' ? 'active' : ''}
        onClick={() => setActiveTab('4')}
      >
    <i className="bx bx-grid-small me-2" />    All Products
      </NavLink>
    </NavItem>
  </Nav>
  <TabContent activeTab={activeTab}>
    <TabPane tabId="1">
      <Row>
        <Col sm="12">
          <h4>
           <ProductListRequest />
          </h4>
        </Col>
      </Row>
    </TabPane>
    <TabPane tabId="2">
      <Row>
        <Col sm="12">
          <h4>
           <ApprovedProducts />
          </h4>
        </Col>
      </Row>
    </TabPane>
    <TabPane tabId="3">
      <Row>
        <Col sm="12">
          <h4>
           <RejectedProducts />
          </h4>
        </Col>
      </Row>
    </TabPane>
    <TabPane tabId="4">
      <Row>
        <Col sm="12">
          <h4>
           <AllProduct />
          </h4>
        </Col>
      </Row>
    </TabPane>
  </TabContent>
                 </div>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};
export default ProductList;
