import {
  GET_BID,
  GET_BID_DETAILS,
  GET_BID_SUCCESS,
  GET_BID_DETAILS_SUCCESS,
  GET_BID_FAIL,
  GET_BID_DETAILS_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  data: [],
  error: {},
  loading : true
}

const bid = (state = INIT_STATE, action) => {
  switch (action.type) {
    
    case GET_BID:
      return {
        ...state,
        data: action.payload,
        loading : true
      }
      case GET_BID_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading : true
      }

    case GET_BID_FAIL:
      return {
        ...state,
        error: action.payload,
      }
      
      case GET_BID_DETAILS:
      return {
        ...state,
        data: action.payload,
        loading : true
      }

       case GET_BID_DETAILS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading : true
      }

    case GET_BID_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}
export default bid
