import React, { useState,useMemo,useEffect,useRef } from "react";
import { createSelector } from "reselect";
import { useParams } from 'react-router-dom';

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  Label,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Container,
  FormFeedback,
  UncontrolledTooltip,
  Form,
} from "reactstrap";
import { ToastContainer } from "react-toastify";

import TableContainer from '../../../components/Common/TableContainer';

import { Link } from "react-router-dom";

import {
  addSubCategoryData as onAddSubCategoryData,
  getSubCategory as onGetSubCategory,
  updateSubCategoryData as onUpdateSubCategoryData
} from "store/setting/actions"
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

// redux
import { useSelector, useDispatch } from "react-redux"

// created 
import {  CreateadAt,CreatedBy } from "./../categorytCol";

import DeleteModal from "./../DeleteModal";

const SubCategory = () => {
//meta title
document.title="WekaDau | SubCategory";
let { category_id } = useParams();
//  dispatch
const dispatch = useDispatch();

useEffect(() => {
  if (category_id) {
    let data = {
      "category_id":category_id
    };
    dispatch(onGetSubCategory(data))
  } 
}, [dispatch]);

const [category, setCategory] = useState();
const [isEdit, setIsEdit] = useState(false);
// delete modal
const [deleteModal, setDeleteModal] = useState(false);
const [subcategories, setSubCategories] = useState([]);
const [categoryname, setCategoriesName] = useState();
  //  modal
const [modal, setModal] = useState(false)

  const columns = useMemo(
    () => [
        {
            Header: 'Category',
            accessor: 'name',
        },
        {
          Header: 'Created By',
          accessor: 'users.details',
          filterable: true,
          Cell: cellProps => {
          return (
            <>
              {" "}
              <CreatedBy {...cellProps} />{" "}
            </>
          );
        },
       },
        {
            Header: 'created At',
            accessor: 'created_at',
            filterable: true,
        Cell: cellProps => {
          return (
            <>
              {" "}
              <CreateadAt {...cellProps} />{" "}
            </>
          );
        },
        },
        {
          Header: "Action",
          Cell: cellProps => {
            return (
              <div className="d-flex gap-3">
                <Link
                  to="#"
                  className="text-info"
                  onClick={() => {
                    const categoryData = cellProps.row.original;
                    handleCategoryClick(categoryData);
                  }}
                >
                  <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    Edit
                  </UncontrolledTooltip>
                </Link>
                {/* <Link
                  to="#"
                  className="text-success"
                  onClick={() => {
                    const categoryData = cellProps.row.original;
                    console.log(categoryData.id)
                    // handleCategoryClick(categoryData);
                  }}
                >
                  <i className="mdi mdi-eye font-size-18" id="viewtooltip" />
                  <UncontrolledTooltip placement="top" target="viewtooltip">
                    View
                  </UncontrolledTooltip>
                </Link> */}
                {/* <Link
                  to="#"
                  className="text-danger"
                  onClick={() => {
                    const userData = cellProps.row.original;
                    onClickDelete(userData);
                  }}
                >
                  <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </Link> */}
              </div>
            );
          },
        },
    ],
    []
);

const onClickDelete = category => {
   setCategory(category);
   setDeleteModal(true);
};

  const selectSubCategoryState = state => state.Settings.subcategory
  
  const CategoryProperties = createSelector(selectSubCategoryState, subcategory => ({
    SubCategoryData:subcategory,
  }))

  const {SubCategoryData,loading } = useSelector(CategoryProperties)

useEffect(() => {
  setSubCategories(SubCategoryData ? SubCategoryData.data ? SubCategoryData.data.category:[]:[]);
  setCategoriesName(SubCategoryData ? SubCategoryData.data ? SubCategoryData.data :'' :'')
}, [SubCategoryData])

const data = subcategories;


const handleCategoryClick = arg => {
  const category = arg;
  setCategory({
    id: category.id,
    name: category.name
  });
  setIsEdit(true);
  toggle();
};
  
// handle delete category
const handleDeleteCategory = () => {
  if (category && category.id) {
    // dispatch(onDeleteUser(category.id));
  }
  onPaginationPageChange(1);
  setDeleteModal(false);
};

  const toggle = () => {
    if (modal) {
      setCategory();
      setIsEdit(false);
      setModal(false)
    } else {
      setModal(true)
    }
  }

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };


  // Form validation 
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (category && category.name) ||'',
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Sub Category Name"),
    }),
    onSubmit: (values) => {
      if(isEdit){
        const edit_data={
           'name':values.name,
           'id':category.id,
           "category_id":category_id
         }
        dispatch(onUpdateSubCategoryData(edit_data));
       }else{
        let data = {
          "name":values.name,
          "category_id":category_id
        };
      dispatch(onAddSubCategoryData(data));
       }
      validation.resetForm()
      toggle();
    }
  });

  const regExp = /\b\d{5}\b/;
  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteCategory}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Settings" breadcrumbItem="Sub-Category List" />
          <h4 className="font-size-14 mb-3">Category: { categoryname ? categoryname.name:"" } has <b>{ data.length }</b>  sub categories</h4>
          <Row>
            <Col xl="4">
              <Card>
                <CardBody>
                  <div className="text-right d-grid">
                                  <Link
                                    to="#"
                                    className="btn btn-primary waves-effect waves-light addtask-btn"
                                    data-bs-toggle="modal"
                                    data-bs-target=".bs-example-modal-lg"
                                    data-id="#upcoming-task"
                                    onClick={() => toggle(true)}
                                  >
                                    <i className="mdi mdi-plus me-1"></i> Add Sub Category
                                  </Link>
                                </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl="12">
            <Card>
                <CardBody>
                <div className="container-fluid">
                <TableContainer
                    columns={columns}
                    data={data}
                    isGlobalFilter={true}
                    isAddOptions={false}
                    customPageSize={10}
                    isPagination={true}
                    tableClass="align-middle table-nowrap table-check table"
                    theadClass="table-light"
                    paginationDiv="col-12"
                    pagination="justify-content-center pagination pagination-rounded"
                />
            </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <Modal id="modalForm" isOpen={modal} toggle={toggle} centered={true} size="sm">
        <ModalHeader toggle={toggle}>
          {!!isEdit ? "Edit Category" : "Add Sub-Category"}
        </ModalHeader>
        <ModalBody>
        <Form className="needs-validation"
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <Row>
                      <Col md="12" sm="12" lg="12">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom01">sub category name</Label>
                          <Input
                            name="name"
                            placeholder="Sub Category name"
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.name || ""}
                            invalid={
                              validation.touched.name && validation.errors.name ? true : false
                            }
                          />
                          {validation.touched.name && validation.errors.name ? (
                            <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Button color="primary" type="submit">
                      Submit
                    </Button>
                  </Form>
        </ModalBody>
      </Modal>
      <ToastContainer />
    </React.Fragment>
  );
};

export default SubCategory;
