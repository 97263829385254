import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_CUSTOMER_AUCTION,
  GET_CUSTOMER_AUCTION_DETAILS,
} from "./actionTypes"

import {
  getCustomerAuctionOrderDataFail,
  getCustomerAuctionOrderDataSuccess,
  getCustomerAuctionOrderDetailsDataSuccess,
  getCustomerAuctionOrderDetailsDataFail,
} from "./actions"

import { toast } from "react-toastify"

// Include Both Helper File with needed methods
import {
  getCustomerAuctionListApi,
  getCustomerAuctionDetailsApi,
} from "helpers/backend_helper"

/**all customer acution bids **/ 
function* fetchCustomerAuction() {
  try {
    const response = yield call(getCustomerAuctionListApi)
    yield put(getCustomerAuctionOrderDataSuccess(response))
  } catch (error) {
    yield put(getCustomerAuctionOrderDataFail(error))
  }
}

/**fetch customer bid Details**/ 
function* fetchCustomerAuctionDetails({ payload: data }) {
  try {
    const response = yield call(getCustomerAuctionDetailsApi, data)
    yield put(getCustomerAuctionOrderDetailsDataSuccess(response))
  } catch (error) {
    yield put(getCustomerAuctionOrderDetailsDataFail(error))
  }
}

function* AuctionSaga() {
  yield takeEvery(GET_CUSTOMER_AUCTION, fetchCustomerAuction)
  yield takeEvery(GET_CUSTOMER_AUCTION_DETAILS, fetchCustomerAuctionDetails)
}

export default AuctionSaga
