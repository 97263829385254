import React, { useState } from "react";
import { Link } from "react-router-dom";

const CustomerSidebarComponent = props => {
  return (
    <React.Fragment>
       <div id="sidebar-menu">  
       <ul className="metismenu list-unstyled" id="side-menu">      
            {/* customer */}
            <li className="menu-title">Menu </li>
            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-home-circle"></i>
                <span>Dashboards</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/dashboard-saas">Dashboard</Link>
                </li>

              </ul>
            </li>
            {/* customer */}

            {/* product management for Customer*/}
             <li className="menu-title">Product Management</li>
             <li>
               <Link to="/#" className="has-arrow">
                 <i className="bx bx-store-alt"></i>
                 <span>Products</span>
               </Link>
               <ul className="sub-menu">
                 <li>
                   <Link to="/customer/product/list">
                     <i className="bx bx-store"></i>
                     <span>Products </span>
                   </Link>
                 </li>
               </ul>
             </li>
            {/* product management for Customer*/}

            {/* bidding management for customer */}
            <li className="menu-title">Bidding Management</li>
            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-box"></i>
                <span>Bidding List</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/customer/bid/list">
                    <i className="bx bx-list-ol"></i>
                    <span>Bidding List</span>
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bxs-cart-alt"></i>
                <span>Auction Order List</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/auction">
                    <i className="bx bx-cart"></i>
                    <span>Bidding Order</span>
                  </Link>
                </li>
              </ul>
            </li>
            {/* bidding management for customer */}


            {/* form */}
            {/* <li>
              <Link to="/#" >
                <i className="bx bxs-eraser"></i>
                <span className="badge rounded-pill bg-danger float-end">
                  10
                </span>
                <span>Forms</span>
              </Link>
              <ul className="sub-menu">
              <li>
                  <Link to="/ui-alerts">Alerts</Link>
                </li>
                <li>
                <Link to="/ui-notifications">
                    Notifications
                  </Link>
                </li>
                <li>
                  <Link to="/form-elements">Form Elements</Link>
                </li>
                <li>
                  <Link to="/form-layouts">Form Layouts</Link>
                </li>
                <li>
                  <Link to="/form-validation">
                    Form Validation
                  </Link>
                </li>
                <li>
                  <Link to="/form-advanced">Form Advanced</Link>
                </li>
                <li>
                  <Link to="/form-editors">Form Editors</Link>
                </li>
                <li>
                  <Link to="/form-uploads">Form File Upload </Link>
                </li>
                <li>
                  <Link to="/form-repeater">Form Repeater</Link>
                </li>
                <li>
                  <Link to="/form-wizard">Form Wizard</Link>
                </li>
                <li>
                  <Link to="/form-mask">Form Mask</Link>
                </li>
                 <li>
                  <Link to="/dual-listbox">Transfer List</Link>
                </li>
                <li>
                  <Link to="/ui-toasts">Toasts</Link>
                </li>
                <li>
                  <Link to="/ui-alerts">Alerts</Link>
                </li>
                <li>
                <Link to="/ui-notifications">
                    Notifications
                  </Link>
                </li>
               </ul>
            </li> */}
            {/* form */}

          </ul>
        </div>
    </React.Fragment>
  );
};


export default CustomerSidebarComponent;
