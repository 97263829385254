import {
  GET_NEWS,
  ADD_NEWS_DATA,
  ADD_NEWS_DATA_SUCCESS,
  ADD_NEWS_DATA_FAIL,
  GET_NEWS_SUCCESS,
  GET_NEWS_FAIL,
  UPDATE_NEWS_DATA,
  ACTIVATE_NEWS,
} from "./actionTypes"

// NEWS
export const getNews = () => ({
  type: GET_NEWS,
})

export const addNewsData = data => ({
  type: ADD_NEWS_DATA,
  payload: data,
})
export const updateNewsData = data => ({
  type: UPDATE_NEWS_DATA,
  payload: data,
})


export const getNewsDataSuccess = data => ({
  type: GET_NEWS_SUCCESS,
  payload: data,
})


export const addNewsDataSuccess = payload_data => ({
  type: ADD_NEWS_DATA_SUCCESS,
  payload: payload_data.data,
})

export const addNewsDataFail = error => ({
  type: ADD_NEWS_DATA_FAIL,
  payload: error,
})

export const getNewsDataFail = error => ({
  type: GET_NEWS_FAIL,
  payload: error,
})
// NEWS

/*approve news*/ 
export const activateNews = data => ({
  type: ACTIVATE_NEWS,
  payload: data,
})
/*approve news*/ 