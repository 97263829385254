/* BANNER */
export const GET_BANNER = "GET_BANNER"
export const GET_BANNER_SUCCESS = "GET_BANNER_SUCCESS"
export const GET_BANNER_FAIL = "GET_BANNER_FAIL"
// ADD BANNER
export const ADD_BANNER_DATA = "ADD_BANNER_DATA"
export const ACTIVATE_BANNER = "ACTIVATE_PRODUCT"
export const UPDATE_BANNER_DATA = "UPDATE_BANNER_DATA"
export const ADD_BANNER_DATA_SUCCESS = "ADD_BANNER_DATA_SUCCESS"
export const ADD_BANNER_DATA_FAIL = "ADD_BANNER_DATA_FAIL"


