/* STREET */
export const GET_STREET = "GET_STREET"
export const GET_STREET_SUCCESS = "GET_STREET_SUCCESS"
export const GET_STREET_FAIL = "GET_STREET_FAIL"

/*ADD STREET*/
export const UPDATE_STREET_DATA = "UPDATE_STREET_DATA"
export const ADD_STREET_DATA = "ADD_STREET_DATA"
export const ADD_STREET_DATA_SUCCESS = "ADD_STREET_DATA_SUCCESS"
export const ADD_STREET_DATA_FAIL = "ADD_STREET_DATA_FAIL"

