/* PRODUCT */
export const GET_APPROVED_PRODUCT = "GET_APPROVED_PRODUCT"
export const GET_APPROVED_PRODUCT_SUCCESS = "GET_APPROVED_PRODUCT_SUCCESS"
export const GET_APPROVED_PRODUCT_FAIL = "GET_APPROVED_PRODUCT_FAIL"

/*PRODUCT DETAILS*/ 
export const GET_APPROVED_PRODUCT_DETAILS = "GET_APPROVED_PRODUCT_DETAILS"
export const GET_APPROVED_PRODUCT_DETAILS_SUCCESS = "GET_APPROVED_PRODUCT_DETAILS_SUCCESS"
export const GET_APPROVED_PRODUCT_DETAILS_FAIL = "GET_APPROVED_PRODUCT_DETAILS_FAIL"

/* PRODUCT APPROVAL*/ 
export const APPROVE_PRODUCT = "APPROVE_PRODUCT"
export const REJECT_PRODUCT = "REJECT_PRODUCT"
export const FEATURED_PRODUCT = "FEATURED_PRODUCT"
export const UNFEATURED_PRODUCT = "UNFEATURED_PRODUCT"

