import {
  GET_CUSTOMER_BID,
  GET_CUSTOMER_BID_SUCCESS,
  GET_CUSTOMER_BID_DETAILS_SUCCESS,
  GET_CUSTOMER_BID_DETAILS_FAIL,
  GET_CUSTOMER_BID_FAIL,
  GET_CUSTOMER_BID_DETAILS,
  ADD_BID_DATA,
  ADD_BID_DATA_SUCCESS,
  ADD_BID_DATA_FAIL  
} from "./actionTypes"

/**ADD BID**/  
export const addBid = data => ({
  type: ADD_BID_DATA,
  payload: data,
})

// CUSTOMER BID
export const getCustomerBid = () => ({
  type: GET_CUSTOMER_BID,
})

// CUSTOMER BID SUCCESSFUL
export const getCustomerBidDataSuccess = data => ({
  type: GET_CUSTOMER_BID_SUCCESS,
  payload: data,
})

export const getCustomerBidDataFail = error => ({
  type: GET_CUSTOMER_BID_FAIL,
  payload: error,
})
// CUSTOMER  BID

/*CUSTOMER  BID DETAILS*/ 
export const getCustomerBidDetails= data => ({
  type: GET_CUSTOMER_BID_DETAILS,
  payload: data,
})

export const getCustomerBidDetailsDataSuccess = data => ({
  type: GET_CUSTOMER_BID_DETAILS_SUCCESS,
  payload: data,
})

export const getCustomerBidDetailsDataFail = error => ({
  type: GET_CUSTOMER_BID_DETAILS_FAIL,
  payload: error,
})

export const addBidDataSuccess = error => ({
  type: ADD_BID_DATA_SUCCESS,
  payload: error,
})

export const addBidDataFail = error => ({
  type: ADD_BID_DATA_FAIL,
  payload: error,
})

/*BID DETAILS*/ 