import React, { useState, useMemo, useEffect, useRef } from "react"
import { createSelector } from "reselect"
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  UncontrolledTooltip,
  Button,
} from "reactstrap"
import TableContainer from "../../../components/Common/TableContainer"
import { ToastContainer } from "react-toastify"
import Spinners from "components/Common/Spinner"
import { Link } from "react-router-dom"
import { getCustomerAuctionOrder as onGetCustomerAuctionOrder } from "store/Bidding/CustomerAuctionBiddingList/actions"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
// redux
import { useSelector, useDispatch } from "react-redux"
import {
  CreateadAt,
  Customer,
  OrderNumber,
  TotalWarningNumber,
  PriceFormat
} from "../../../components/Common/Col"
const CustomerAuctionOrder = () => {
  //meta title
  document.title = "WekaDau | Auction Order"
  //  dispatch
  const dispatch = useDispatch({})
  const [customer, setAuction] = useState()
  const [isEdit, setIsEdit] = useState(false)

  // Auctions
  const [customerAuction, setCustomerAuctions] = useState([])

  useEffect(() => {
    dispatch(onGetCustomerAuctionOrder())
  }, [dispatch])

  const selectCustomerAuctionState = state => state.CustomerAuction

  const CustomerAuctionProperties = createSelector(selectCustomerAuctionState, Auction => ({
    CustomerAuctionData: Auction,
    loading: Auction,
  }))

  const { CustomerAuctionData, loading } = useSelector(
    CustomerAuctionProperties ? CustomerAuctionProperties : []
  )
  const [isLoading, setLoading] = useState(loading)

  useEffect(() => {
    setCustomerAuctions(CustomerAuctionData ? CustomerAuctionData.data : [])
  }, [CustomerAuctionData])

  // handle delete category
  const handleDeleteAuction = () => {
    if (category && category.id) {
      // dispatch(onDeleteUser(category.id));
    }
    onPaginationPageChange(1)
    setDeleteModal(false)
  }

  const toggle = () => {
    if (modal) {
      setAuction()
      setIsEdit(false)
      setModal(false)
    } else {
      setModal(true)
    }
  }

  var node = useRef()
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page)
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: "Auctioneer",
        accessor: "customer",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              {" "}
              <Customer {...cellProps} />{" "}
            </>
          )
        },
      },
      {
        Header: "Auction Number",
        accessor: "bidding_number",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              {" "}
              <OrderNumber {...cellProps} />{" "}
            </>
          )
        },
      },
      {
        Header: "Product Name",
        accessor: "product.name",
      },
      {
        Header: "Total Auctions",
        accessor: "total_auction",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              {" "}
              <TotalWarningNumber {...cellProps} />{" "}
            </>
          )
        },
      },
      {
        Header: "Latest max Price",
        accessor: "latest_max_auction_price",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              {" "}
              <PriceFormat {...cellProps} />{" "}
            </>
          )
        },
      },
      {
        Header: "Starting Date",
        accessor: "starting_date",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              {" "}
              <CreateadAt {...cellProps} />{" "}
            </>
          )
        },
      },
      {
        Header: "Ending Date",
        accessor: "ending_date",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              {" "}
              <CreateadAt {...cellProps} />{" "}
            </>
          )
        },
      },

      {
        Header: "Starting Price",
        accessor: "bidding_price.starting_price",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              {" "}
              <PriceFormat {...cellProps} />{" "}
            </>
          )
        },
      },
      {
        Header: "Ending Price",
        accessor: "bidding_price.ending_price",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              {" "}
              <PriceFormat {...cellProps} />{" "}
            </>
          )
        },
      },
      {
        Header: "created At",
        accessor: "created_at",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              {" "}
              <CreateadAt {...cellProps} />{" "}
            </>
          )
        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to={"/auction/details/" + cellProps.row.original.id}
                className="text-success "
              >
                <Button color="success" outline>
                  View
                  <i
                    className="mdi mdi-eye font-size-18 m-1"
                    id="viewtooltip"
                  />
                  <UncontrolledTooltip placement="top" target="viewtooltip">
                    Preview Auction
                  </UncontrolledTooltip>
                </Button>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Auction Order" breadcrumbItem="Auction Order " />
          <div>
            {isLoading ? (
              <Spinners setLoading={setLoading} />
            ) : (
              <Row>
                <Col xl="12">
                  <Card>
                    <CardBody>
                      <div className="container-fluid">
                        <TableContainer
                          columns={columns}
                          data={customerAuction ? customerAuction.data : []}
                          isGlobalFilter={true}
                          isAddOptions={false}
                          customPageSize={10}
                          isPagination={true}
                          tableClass="align-middle table-nowrap table-check table"
                          theadClass="table-light"
                          paginationDiv="col-12"
                          pagination="justify-content-center pagination pagination-rounded"
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}
            <ToastContainer />
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default CustomerAuctionOrder
