import React from 'react';
import * as moment from "moment";


const formateDate = (date, format) => {
    const dateFormat = format ? format : "DD MMM Y";
    const date1 = moment(new Date(date)).format(dateFormat);
    return date1;
};

const CreateadAt = (cell) => {
    return cell.value ? formateDate(cell.value) : '';
};

const priceSplitter = (number) =>
  number && number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

// const CreatedBy = (cell) => {
//     return cell.value ? cell.value.details.first_name.toUpperCase() + " " + cell.value.details.middle_name.toUpperCase() + " " + cell.value.details.last_name.toUpperCase() : '';
// };

const Customer = (cell) => {
    return cell.value ? cell.value.details.first_name.toUpperCase() + " " + cell.value.details.middle_name.toUpperCase() + " " + cell.value.details.last_name.toUpperCase() : '';
};

const Price = (cell) => {
    return cell.value ? priceSplitter(cell.value.starting_price) + " "+ priceSplitter(cell.value.ending_price) + " " + cell.value.currency   : '';
};

const Status = (cell) => {
    return cell.value ? (<div><span className="badge rounded-pill bg-success float-center">Approved</span></div>):(<div>
        <span className="badge rounded-pill bg-danger float-center">Pending</span>
    </div>);
};


const Image = cell => {
    return cell.value[0] ? (
      <div>
        <img height="25" src={cell.value[0].Product_image} alt="image" />
      </div>
    ) : (
      <>
        <span className="badge rounded-pill bg-info">N/a</span>
      </>
    )
  }



export {
    CreateadAt,
    Price,
    Customer,
    Status,
    Image
};

