import {
  GET_CATEGORY,ADD_CATEGORY_DATA,
  ADD_CATEGORY_DATA_SUCCESS,
  ADD_CATEGORY_DATA_FAIL,
  GET_CATEGORY_SUCCESS,
  GET_CATEGORY_FAIL,
  GET_SUBCATEGORY,
  GET_SUBCATEGORY_SUCCESS,
  ADD_SUBCATEGORY_DATA,
  ADD_SUBCATEGORY_DATA_FAIL,
  ADD_SUBCATEGORY_DATA_SUCCESS,
  GET_SUBCATEGORY_FAIL,
  UPDATE_CATEGORY_DATA,
  UPDATE_SUBCATEGORY_DATA
} from "./actionTypes"


// SUB CATEGORY

export const getSubCategory = data => ({
  type: GET_SUBCATEGORY,
  payload:data
})

export const getSubCategoryDataSuccess = data => ({
  type: GET_SUBCATEGORY_SUCCESS,
  payload: data,
})

export const addSubCategoryData = data => ({
  type: ADD_SUBCATEGORY_DATA,
  payload: data,
})

export const updateCategoryData = data => ({
  type: UPDATE_CATEGORY_DATA,
  payload: data,
})

export const updateSubCategoryData = data => ({
  type: UPDATE_SUBCATEGORY_DATA,
  payload: data,
})

export const addSubCategoryDataFail = error => ({
  type: ADD_SUBCATEGORY_DATA_FAIL,
  payload: error,
})

export const addSubCategoryDataSuccess = data => ({
  type: ADD_SUBCATEGORY_DATA_SUCCESS,
  payload: data,
})

export const getSubCategoryDataFail = error => ({
  type: GET_SUBCATEGORY_FAIL,
  payload: error,
})

// SUB CATEGORY
// CATEGORY
export const getCategory = () => ({
  type: GET_CATEGORY,
})

export const addCategoryData = data => ({
  type: ADD_CATEGORY_DATA,
  payload: data,
})


export const getCategoryDataSuccess = data => ({
  type: GET_CATEGORY_SUCCESS,
  payload: data,
})


export const addCategoryDataSuccess = data => ({
  type: ADD_CATEGORY_DATA_SUCCESS,
  payload: data,
})

export const addCategoryDataFail = error => ({
  type: ADD_CATEGORY_DATA_FAIL,
  payload: error,
})

export const getCategoryDataFail = error => ({
  type: GET_CATEGORY_FAIL,
  payload: error,
})

// CATEGORY