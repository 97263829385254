import {
  GET_GROUP,
  ADD_GROUP_DATA_SUCCESS,
  ADD_GROUP_DATA_FAIL,
  ADD_GROUP_PERMISSION_DATA_SUCCESS,
  ADD_GROUP_PERMISSION_DATA_FAIL,
  GET_GROUP_SUCCESS,
  GET_GROUP_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  data: [],
  error: {},
  loading : true
}

const groups = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_GROUP:
      return {
        ...state,
        data: action.payload,
        loading : true
      }

      case GET_GROUP_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading : true
      }

    case GET_GROUP_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_GROUP_DATA_SUCCESS:
      return {
        ...state,
        data: state
      }

    case ADD_GROUP_DATA_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_GROUP_PERMISSION_DATA_SUCCESS:
      return {
        ...state,
        data: state
      }

    case ADD_GROUP_PERMISSION_DATA_FAIL:
      return {
        ...state,
        error: action.payload,
      }
      
    default:
      return state
  }
}

export default groups
