import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_REJECTED_PRODUCT,
  GET_REJECTED_PRODUCT_DETAILS,
  APPROVE_PRODUCT,
  REJECT_PRODUCT,
  FEATURED_PRODUCT,
  UNFEATURED_PRODUCT
} from "./actionTypes"

import {
  getRejectedProductDataFail,
  getRejectedProductDataSuccess,
  getRejectedProductDetailsDataSuccess,
  getRejectedProductDetailsDataFail,
} from "./actions"

import { toast } from "react-toastify"

// Include Both Helper File with needed methods
import {
  getRejectedProductListApi,
  getRejectedProductDetailsApi,
  approveProduct,
  featuredProduct,
  rejectedProduct,
  unFeaturedProduct
} from "helpers/backend_helper"

// all pooducts 
function* fetchProduct() {
  try {
    const response = yield call(getRejectedProductListApi)
    yield put(getRejectedProductDataSuccess(response))
  } catch (error) {
    yield put(getRejectedProductDataFail(error))
  }
}



// fetch product Details
function* fetchProductDetails({ payload: data }) {
  try {
    const response = yield call(getRejectedProductDetailsApi, data)
    yield put(getRejectedProductDetailsDataSuccess(response))
  } catch (error) {
    yield put(getRejectedProductDetailsDataFail(error))
  }
}

// approve Product api
function* onApproveProductData({ payload: data }) {
  try {
    const response = yield call(approveProduct, data)
    yield put({type:"GET_REJECTED_PRODUCT_DETAILS",payload:data})
    toast.success(response.data ? response.data.detail :"", { autoClose: 5000 });
  } catch (error) {
    const message = error.response.data.name ? error.response.data.name[0] : (error.detail ? error.detail : "System error"); 
    yield put(addWardDataFail(message))
    toast.error(message, { autoClose: 5000 });
  }
}


// reject Product api
function* onRejectProductData({ payload: data }) {
  try {
    const response = yield call(rejectedProduct, data)
    console.log("response",response)
    console.log("response data",response.data)
    console.log("response details",response.data ? response.data.detail :"")
    toast.success(response.data ? response.data.detail :"", { autoClose: 5000 });
        yield put({type:"GET_REJECTED_PRODUCT_DETAILS",payload:data})

  } catch (error) {
    const message = error.response.data.name ? error.response.data.name[0] : (error.detail ? error.detail : "System error"); 
    toast.error(message, { autoClose: 5000 });
  }
}

// featured product data
function* onFeaturedProductData({ payload: data }) {
  try {
    const response = yield call(featuredProduct, data)
     yield put({type:"GET_REJECTED_PRODUCT_DETAILS",payload:data})
    toast.success(response.data ? response.data.detail :"", { autoClose: 5000 });
  } catch (error) {
    const message = error.response.data.name ? error.response.data.name[0] : (error.detail ? error.detail : "System error"); 
    toast.error(message, { autoClose: 5000 });
  }
}

/**un featured**/ 
function* onUnFeaturedProductData({ payload: data }) {
  try {
    const response = yield call(unFeaturedProduct, data)
     yield put({type:"GET_REJECTED_PRODUCT_DETAILS",payload:data})
    toast.success(response.data ? response.data.detail :"", { autoClose: 5000 });
  } catch (error) {
    const message = error.response.data.name ? error.response.data.name[0] : (error.detail ? error.detail : "System error"); 
    toast.error(message, { autoClose: 5000 });
  }
}

function* ProductSaga() {
  yield takeEvery(GET_REJECTED_PRODUCT, fetchProduct)
  yield takeEvery(GET_REJECTED_PRODUCT_DETAILS, fetchProductDetails)
  yield takeEvery(APPROVE_PRODUCT, onApproveProductData)
  yield takeEvery(REJECT_PRODUCT, onRejectProductData)
  yield takeEvery(FEATURED_PRODUCT, onFeaturedProductData)
  yield takeEvery(UNFEATURED_PRODUCT, onUnFeaturedProductData)
}

export default ProductSaga
