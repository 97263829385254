import React, { useState,useMemo,useEffect,useRef } from "react";
import { createSelector } from "reselect"
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  Label,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Container,
  FormFeedback,
  UncontrolledTooltip,
  Form,
} from "reactstrap";
import Dropzone from "react-dropzone"
import { ToastContainer } from "react-toastify";
import Spinners from "components/Common/Spinner";
import TableContainer from '../../components/Common/TableContainer';

import { Link } from "react-router-dom";

import {
  addNewsData as onAddNewsData,
  activateNews as onActivateNews,
  updateNewsData as onUpdateNewsData,
  getNews as onGetNews,
} from "store/setting/News/actions"

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
// redux
import { useSelector, useDispatch } from "react-redux"

import { CreateadAt, CreatedBy, Image, LongText } from "./categorytCol"
// Form Mask
import InputMask from "react-input-mask"

// import DeleteModal from "../DeleteModal";

const NewsListPage = args => {
  //meta title
  document.title = "WekaDau | News"

  //  dispatch
  const dispatch = useDispatch({})

  const [staff, setNews] = useState()
  const [isEdit, setIsEdit] = useState(false)
  const [selectedFiles, setselectedFiles] = useState([])
  const [errorFiles, seterrorFiles] = useState("")
  // delete modal
  const [deleteModal, setDeleteModal] = useState(false)
  const [news, setNewss] = useState([])
  const [modalActivate, setModalActivate] = useState(false)

  //  modal
  const [modal, setModal] = useState(false)

  useEffect(() => {
    dispatch(onGetNews())
  }, [dispatch])

  const onClickDelete = category => {
    setNews(category)
    setDeleteModal(true)
  }

  const [news_data, setNewsData] = useState({})

  const selectNewsState = state => state.News

  const NewsProperties = createSelector(selectNewsState, News => ({
    NewsData: News.data,
    loading: News.loading,
  }))
  const { NewsData, loading } = useSelector(
    NewsProperties ? NewsProperties : []
  )
  const [isLoading, setLoading] = useState(loading)

  useEffect(() => {
    setNewss(NewsData ? NewsData.data : [])
  }, [NewsData])

  const handleNewsClick = arg => {
    const category = arg
    setNews(category)
    setIsEdit(true)
    toggle()
  }

  // handle delete category
  const handleDeleteNews = () => {
    if (category && category.id) {
      console.log(category.id)
      // dispatch(onDeleteUser(category.id));
    }
    onPaginationPageChange(1)
    setDeleteModal(false)
  }

  const toggle = () => {
    if (modal) {
      setNews()
      setIsEdit(false)
      setModal(false)
    } else {
      setModal(true)
    }
  }

  var node = useRef()
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page)
    }
  }

  function handleAcceptedFiles(files) {
    files.map(file => {
      if (file.type.startsWith("image/")) {
        setselectedFiles(files)
        return Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: formatBytes(file.size),
        })
      } else {
        seterrorFiles("Please image select files")
        // handle non-image files here or simply ignore them
        return null // returning null will filter out non-image files from the resulting array
      }
    })
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      title: (news && news.title) || "",
      body: (news && news.body) || "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter Title"),
      body: Yup.string().required("Please Enter Body"),
    }),

    onSubmit: values => {
      let payload = {
        body: values.banner_body,
        title: values.banner_title,
        fileImage: selectedFiles,
      }
      if (isEdit) {
        // dispatch(onUpdateNewsData(values))
      } else {
        let payload = {
          body: values.body,
          title: values.title,
          fileImage: selectedFiles,
        }
        dispatch(onAddNewsData(payload))
      }
      validation.resetForm()
      toggle()
    },
  })

  const toggleActivate = () => {
    if (modalActivate) {
      setModalActivate(false)
    } else {
      setModalActivate(true)
    }
  }
  const handleActivate = arg => {
    const data = arg
    setNewsData(data)
    toggleActivate()
  }

  const columns = useMemo(
    () => [
      {
        Header: "Title",
        accessor: "title",
        filterable: true,
      },
      {
        Header: "Body",
        accessor: "body",
        Cell: cellProps => {
          return (
            <>
              {" "}
              <LongText {...cellProps} />{" "}
            </>
          )
        },
      },
      {
        Header: "Image",
        accessor: "news_image",
        Cell: cellProps => {
          return (
            <>
              {" "}
              <Image {...cellProps} />{" "}
            </>
          )
        },
      },
      {
        Header: "Created By",
        accessor: "author.details",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              {" "}
              <CreatedBy {...cellProps} />{" "}
            </>
          )
        },
      },
      {
        Header: "created At",
        accessor: "created_at",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              {" "}
              <CreateadAt {...cellProps} />{" "}
            </>
          )
        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-info"
                onClick={() => {
                  const categoryData = cellProps.row.original
                  // handleNewsClick(categoryData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const groupData = cellProps.row.original
                  handleActivate(groupData)
                }}
              >
                <i
                  className="mdi mdi-sticker-check-outline font-size-18"
                  id="viewtooltip"
                />
                <UncontrolledTooltip placement="top" target="viewtooltip">
                  {cellProps.row.original.is_active ? "Deactivate" : "Activate"}
                </UncontrolledTooltip>
                {cellProps.row.original.is_active ? "Deactivate" : "Activate"}
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  const handleSubmitApprove = () => {
    if (news_data.is_active === true) {
      const payload = {
        news_id: news_data.id,
        news_status: true,
      }
      dispatch(onActivateNews(payload))
      toggleActivate()
    } else {
      const data = {
        news_id: news_data.id,
        news_status: false,
      }
      dispatch(onActivateNews(data))
      toggleActivate()
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Settings" breadcrumbItem="News List" />
          {isLoading ? (
            <Spinners setLoading={setLoading} />
          ) : (
            <Row>
              <Col xl="4" sm="4" md="4">
                <Card>
                  <CardBody>
                    <div className="text-right d-grid">
                      <Link
                        to="#"
                        className="btn btn-primary waves-effect waves-light addtask-btn"
                        data-bs-toggle="modal"
                        data-bs-target=".bs-example-modal-lg"
                        data-id="#upcoming-task"
                        onClick={() => toggle(true)}
                      >
                        <i className="mdi mdi-plus me-1"></i> Add News
                      </Link>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col xl="12">
                <Card>
                  <CardBody>
                    <div className="container-fluid">
                      <TableContainer
                        columns={columns}
                        data={news}
                        isGlobalFilter={true}
                        isAddOptions={false}
                        customPageSize={10}
                        isPagination={true}
                        tableClass="align-middle table-nowrap table-check table"
                        theadClass="table-light"
                        paginationDiv="col-12"
                        pagination="justify-content-center pagination pagination-rounded"
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>

      {/* adding News modal */}
      <Modal
        id="modalForm"
        isOpen={modal}
        toggle={toggle}
        centered={true}
        size="lg"
      >
        <ModalHeader toggle={toggle}>
          {!!isEdit ? "Edit News" : "Add News"}
        </ModalHeader>
        <ModalBody>
          <Form
            className="needs-validation"
            onSubmit={e => {
              e.preventDefault()
              validation.handleSubmit()
              return false
            }}
          >
            <Row>
              <Col md="12" sm="12" lg="12">
                <FormGroup className="mb-3">
                  <Label htmlFor="validationCustom01">Title</Label>
                  <Input
                    name="title"
                    placeholder="Title"
                    type="text"
                    className="form-control"
                    id="validationCustom01"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.title || " "}
                    invalid={
                      validation.touched.title && validation.errors.title
                        ? true
                        : false
                    }
                  />
                  {validation.touched.title && validation.errors.title ? (
                    <FormFeedback type="invalid">
                      {validation.errors.title}
                    </FormFeedback>
                  ) : null}
                </FormGroup>
              </Col>

              <Col md="12" sm="12" lg="12">
                <FormGroup className="mb-3">
                  <Label htmlFor="formmessage">News Body</Label>
                  <Input
                    type="textarea"
                    name="body"
                    id="formmessage"
                    className="form-control"
                    rows="3"
                    placeholder="Enter news Body"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.body || ""}
                    invalid={
                      validation.touched.body && validation.errors.body
                        ? true
                        : false
                    }
                  />
                  {validation.touched.body && validation.errors.body ? (
                    <FormFeedback type="invalid">
                      {validation.errors.body}
                    </FormFeedback>
                  ) : null}
                </FormGroup>
              </Col>
              {!!isEdit ? (
                <div>
                  <img height="70" src={news.image} alt="image" />
                </div>
              ) : (
                ""
              )}
              <Col md="12" sm="12" lg="12">
                <FormGroup className="mb-3">
                  <Dropzone
                    onDrop={acceptedFiles => {
                      handleAcceptedFiles(acceptedFiles)
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div className="dropzone">
                        <div
                          className="dz-message needsclick"
                          {...getRootProps()}
                        >
                          <input {...getInputProps()} />
                          <div className="dz-message needsclick">
                            <div className="mb-3">
                              <i className="display-4 text-muted bx bxs-cloud-upload" />
                            </div>
                            <h4>Drop News image here or click to upload.</h4>
                          </div>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                  <div className="dropzone-previews mt-3" id="file-previews">
                    {selectedFiles.map((f, i) => {
                      return (
                        <Card
                          className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                          key={i + "-file"}
                        >
                          <div className="p-2">
                            <Row className="align-items-center">
                              <Col className="col-auto">
                                <img
                                  data-dz-thumbnail=""
                                  height="80"
                                  className="avatar-sm rounded bg-light"
                                  alt={f.name}
                                  src={f.preview}
                                />
                              </Col>
                              <Col>
                                <Link
                                  to="#"
                                  className="text-muted font-weight-bold"
                                >
                                  {f.name}
                                </Link>
                                <p className="mb-0">
                                  <strong>{f.formattedSize}</strong>
                                </p>
                              </Col>
                            </Row>
                          </div>
                        </Card>
                      )
                    })}
                    <span className="text-danger">
                      {errorFiles ? errorFiles : ""}*
                    </span>
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <div className="text-center d-grid">
              <Button color="primary" type="submit">
                {!!isEdit ? "Submit Edit" : "Submit"}
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
      {/* adding staff modal */}

      {/* model for activate the news */}
      <div>
        <Modal isOpen={modalActivate} toggle={toggleActivate} {...args}>
          <ModalHeader toggle={toggleActivate}>
            Approve News Notifications
          </ModalHeader>
          <ModalBody>
            Are you sure you want to approve this news:{" "}
            <b>{news_data ? news_data.title : ""}</b> ?
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={handleSubmitApprove}>
              Submit
            </Button>{" "}
            <Button color="secondary" onClick={toggleActivate}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
      {/* model for activate  news */}

      <ToastContainer />
    </React.Fragment>
  )
}
export default NewsListPage
