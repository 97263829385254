import {
  GET_CUSTOMER_AUCTION,
  GET_CUSTOMER_AUCTION_SUCCESS,
  GET_CUSTOMER_AUCTION_DETAILS_SUCCESS,
  GET_CUSTOMER_AUCTION_DETAILS_FAIL,
  GET_CUSTOMER_AUCTION_FAIL,
  GET_CUSTOMER_AUCTION_DETAILS
} from "./actionTypes"

/**Customer Auctions**/
export const getCustomerAuctionOrder = () => ({
  type: GET_CUSTOMER_AUCTION,
})

// 
export const getCustomerAuctionOrderDataSuccess = data => ({
  type: GET_CUSTOMER_AUCTION_SUCCESS,
  payload: data,
})

export const getCustomerAuctionOrderDataFail = error => ({
  type: GET_CUSTOMER_AUCTION_FAIL,
  payload: error,
})
// AUCTION

/*AUCTION DETAILS*/ 
export const getCustomerAuctionOrderDetails= data => ({
  type: GET_CUSTOMER_AUCTION_DETAILS,
  payload: data,
})

export const getCustomerAuctionOrderDetailsDataSuccess = data => ({
  type: GET_CUSTOMER_AUCTION_DETAILS_SUCCESS,
  payload: data,
})

export const getCustomerAuctionOrderDetailsDataFail = error => ({
  type: GET_CUSTOMER_AUCTION_DETAILS_FAIL,
  payload: error,
})

/*AUCTION DETAILS*/ 