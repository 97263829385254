import React, { useState } from "react";
import Dropzone from "react-dropzone"
import {
  Row,
  Col,
  Card
} from "reactstrap"
import { Link } from "react-router-dom"

const ContactDetails = ({ errors, values, handleChange }) =>{
  const [selectedFiles, setSelectedFiles] = useState([])
  const [errorFiles, setErrorFiles] = useState("")

  function handleAcceptedFiles(files) {
    if(files.length < 5){
       files.map(file => {
      if (file.type.startsWith("image/")) {
        setSelectedFiles(files)
        values.ProductImages = files;
        handleChange();
        return Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: formatBytes(file.size),
        })
      } else {
        setErrorFiles("Please Image select files")
        return null
      }
    })
    values.ProductImages = selectedFiles;
    }else{
      setErrorFiles("Please select up to 4 Images")
    }
   
  }
  // format bytes
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
  
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }
  

  return(
  <>
    <div className="columns">
      <div className="column is-12">
      <Dropzone
          onDrop={acceptedFiles => {
            handleAcceptedFiles(acceptedFiles)
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <div className="dropzone">
              <div
                className="dz-message needsclick"
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                <div className="dz-message needsclick">
                  <div className="mb-3">
                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                  </div>
                  <h4>Drop/Select Product Image here or click to upload.</h4>
                </div>
              </div>
            </div>
          )}
        </Dropzone>
        {/* preview  an image page */}
        <div className="dropzone-previews mt-3" id="file-previews">
                    {selectedFiles.map((f, i) => {
                      return (
                        <Card
                          className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                          key={i + "-file"}
                        >
                          <div className="p-2">
                            <Row className="align-items-center">
                              <Col className="col-auto">
                                <img
                                  data-dz-thumbnail=""
                                  height="80"
                                  className="avatar-sm rounded bg-light"
                                  alt={f.name}
                                  src={f.preview}
                                />
                              </Col>
                              <Col>
                                <Link
                                  to="#"
                                  className="text-muted font-weight-bold"
                                >
                                  {f.name}
                                </Link>
                                <p className="mb-0">
                                  <strong>{f.formattedSize}</strong>
                                </p>
                              </Col>
                            </Row>
                          </div>
                        </Card>
                      )
                    })}
                    <span className="text-danger">
                      {errorFiles ? errorFiles : ""}*
                    </span>
                  </div>  
      </div>
        {/* preview  an image page */}
      </div>  
{/* youtube link */}
      <div className="column is-12">
        <div className="field">
          <label className="label">Youtube Link</label>
          <div className="control">
            <input
              name="youtube_link"
              className="input"
              type="text"
              placeholder="Youtube Link"
              value={values.youtube_link}
              onChange={handleChange}
            />
          </div>
      </div>
        </div>
        {/* youtube link */}

  </>
)};

export default ContactDetails;
