import {
  GET_STREET,
  ADD_STREET_DATA,
  ADD_STREET_DATA_SUCCESS,
  ADD_STREET_DATA_FAIL,
  GET_STREET_SUCCESS,
  GET_STREET_FAIL,
  UPDATE_STREET_DATA
} from "./actionTypes"

// STREET
export const getStreet = data => ({
  type: GET_STREET,
  payload: data,
})

export const addStreetData = data => ({
  type: ADD_STREET_DATA,
  payload: data,
})


export const updateStreetData = data => ({
  type: UPDATE_STREET_DATA,
  payload: data,
})

export const getStreetDataSuccess = data => ({
  type: GET_STREET_SUCCESS,
  payload: data,
})


export const addStreetDataSuccess = data => ({
  type: ADD_STREET_DATA_SUCCESS,
  payload: data,
})

export const addStreetDataFail = error => ({
  type: ADD_STREET_DATA_FAIL,
  payload: error,
})

export const getStreetDataFail = error => ({
  type: GET_STREET_FAIL,
  payload: error,
})
// STREET