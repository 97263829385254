import React from 'react';
import * as moment from "moment";
import { Badge } from "reactstrap"

const formateDate = (date, format) => {
    const dateFormat = format ? format : "DD MMM Y";
    const date1 = moment(new Date(date)).format(dateFormat);
    return date1;
};

const CreateadAt = (cell) => {
    return cell.value ? formateDate(cell.value) : '';
};

const priceSplitter = (number) =>
  number && number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

const Customer = (cell) => {
    return cell.value ? cell.value.details.first_name.toUpperCase() + " " + cell.value.details.middle_name.toUpperCase() + " " + cell.value.details.last_name.toUpperCase() : '';
};

const Price = (cell) => {
    return cell.value ? priceSplitter(cell.value.starting_price) + " "+ priceSplitter(cell.value.ending_price) + " " + cell.value.currency   : '';
};

const PriceFormat = (cell) => {
    return cell.value ? priceSplitter(cell.value) : '';
};

const SpanNumber = (cell) => {
   return (
     <Badge
       className="font-size-16 badge-soft-success"
     >
       {cell.value}
     </Badge>
   )
};

const TotalWarningNumber = (cell) => {
   return (
     <Badge className="font-size-16 badge-soft-success">{cell.value}</Badge>
   )
};
const OrderNumber = (cell) => {
   return (
     <Badge
       className="font-size-16 badge-soft-success"
     >
       #{cell.value}
     </Badge>
   )
};

export {
    CreateadAt,
    Price,
    SpanNumber,
    TotalWarningNumber,
    OrderNumber,
    PriceFormat,
    Customer
};

