import {
  GET_PRODUCT,
  GET_PRODUCT_SUCCESS,
  GET_PRODUCT_DETAILS_SUCCESS,
  GET_PRODUCT_DETAILS_FAIL,
  GET_PRODUCT_FAIL,
  GET_PRODUCT_DETAILS,
  APPROVE_PRODUCT,
  REJECT_PRODUCT,
  FEATURED_PRODUCT,
  UNFEATURED_PRODUCT,
} from "./actionTypes"

// PRODUCT
export const getProduct = () => ({
  type: GET_PRODUCT,
})

// 
export const getProductDataSuccess = data => ({
  type: GET_PRODUCT_SUCCESS,
  payload: data,
})

export const getProductDataFail = error => ({
  type: GET_PRODUCT_FAIL,
  payload: error,
})
// PRODUCT

/*PRODUCT DETAILS*/ 
export const getProductDetails= data => ({
  type: GET_PRODUCT_DETAILS,
  payload: data,
})

/*approve product*/ 
export const approveProduct= data => ({
  type: APPROVE_PRODUCT,
  payload: data,
})
/*approve product*/ 

/*rejected product*/ 
export const rejectedProduct= data => ({
  type: REJECT_PRODUCT,
  payload: data,
})
/*rejected product*/ 

/*featured product*/ 
export const featuredProduct= data => ({
  type: FEATURED_PRODUCT,
  payload: data,
})
/*featured product*/ 

/*featured product*/ 
export const unFeaturedProduct= data => ({
  type: UNFEATURED_PRODUCT,
  payload: data,
})
/*featured product*/ 

export const getProductDetailsDataSuccess = data => ({
  type: GET_PRODUCT_DETAILS_SUCCESS,
  payload: data,
})

export const getProductDetailsDataFail = error => ({
  type: GET_PRODUCT_DETAILS_FAIL,
  payload: error,
})

/*PRODUCT DETAILS*/ 